import React from 'react';
import classes from "./terms.module.css"

const PrivacyPolicy = () => {

  return (
    <div className={classes.container}>
      <p className={classes.extraLarge}>Privacy Policy</p>
      <p className={classes.small}><p className={classes.large}>Introduction</p>
        We at USURP HRTECH Solutions Private Limited (Company that owns and
        runs Usurp.Tech) (“Usurp”, “we,”, “us” or “our''), take our responsibility to
        protect your (the “user”) data privacy seriously. We strive to ensure the safe
        and secure usage of the information you provide while you interact with this
        platform. This privacy “policy” applies to the securing and processing of data
        by the website “Usurp.Tech” (“the platform”), its services and content.
        By accessing our Site or our Service, you accept our Privacy Policy and
        Terms of Service, and you consent to our collection, storage, use and
        disclosure of your personal information as described in this privacy policy.
        This policy explains how we collect, use and share personal information in the
        course of our business activities, including:
        1. Who we are and important information
        2. Purpose of this policy
        3. Definitions
        4. What personal information we collect and when and how we use it
        5. How we share personal information within the company and other third
        parties
        6. How we store and protect personal information: Usurp Information &
        Data Security policy
        7. Legal rights available to help manage your privacy
        8. How to contact us
        <p className={classes.medium}>
          1. Who we are and important information
        </p>
        1.1. “USURP HRTECH Solutions Private Limited” is a private limited
        company registered in Hyderabad with the Company
        Incorporation Number (CIN) U74999TG2022PTC163271 that
        provides companies, organisations and individuals (“clients'')
        with HR services and offers potential employment candidates
        and test takers (“users”,“ you”) jobs and upskilling
        opportunities, career consulting, aptitude tests and advice
        services. Our gamified, automated and personalised experience
        provides the users a chance to improve their skills through tests,
        competitions and bootcamps. The boot camps and competitions
        help collect valuable data that eases hiring for our clients. We
        use emerging tech like machine learning, web development and
        blockchain to provide staffing and related solutions to all other
        allied activities.
        1.2. Are we always acting as a data controller?
        1.2.1. Usurp is often a data controller, but for some of our
        activities we may also act as a data processor or
        subprocessor. A data controller decides what personal
        data is collected, the means of the collection and its
        purpose. A data processor is the one who carries out the
        actual processing of the data under the specific
        instructions of the data controller to process personal
        data on its behalf. A data sub-processor is engaged by a
        processor and will have access to or will process
        personal data for a specific purpose.
        1.2.2. Where Usurp is a data processor or sub-processor, we
        have policies in place to ensure data security, and that we
        act in accordance with our customers’ wishes and the
        relevant data protection legislation (please refer to
        section 9).
        1.2.3. The instances of when Usurp acts as data controller
        includes, but is not limited to, processing of client and
        potential client personal data, processing of employees
        and former employees personal data, processing of
        personal data in the course of recruiting new Usurp
        employees, processing of candidates personal data for
        research, processing of candidates personal data to
        anonymise the said personal data, processing the
        personal data of users of the website in the context of
        that use, providing the “Your Personal Profile”
        assessment service.
        <p className={classes.medium}>2. Purpose of this policy</p>
        This Privacy policy describes how we collect, use and share the data that
        identifies or is associated with you (Personal Information) when you use and
        interact with the platform. Our privacy policy applies to all the users and
        clients that use this platform and avail the services provided on the platform.
        The policy only applies to the users and clients on this platform and will apply
        to the information shared directly to the company via the platform and in other
        ways related to the platform as specified under.
        Apart from this policy, some services may require further consent from you to
        collect and share your personal information. Such privacy notices will pop-up
        when you access those services.

        Before you use or access our platform and its services please ensure that you
        have read and understood our privacy policy. This policy supplements the
        other notices and is not intended to override them.
        <p className={classes.medium}>3. Definitions</p>
        The following are definitions of terms used in this privacy policy.
        Opportunities: competitions, quizzes, Hackathons, Jobs, Internships,
        Conferences and other events posted by us or our clients.
        Clients: Companies, organisations, institutes, businesses and individuals who
        are registered with the platform as organisers or hosts and clients who avail
        our HR services like employee aptitude tests, etc.
        Personal Information: As per the Information Technology (Reasonable
        security practices and procedures and sensitive personal data or information)
        Rules, 2011 personal information is defined as any information that relates to
        a natural person, which, either directly or indirectly, in combination with other
        information available or likely to be available with a body corporate, is capable
        of identifying such person. This includes information you provide to us while
        using our services and other ways that you consent to providing us with
        information under this privacy policy, i.e. cookies.
        Users: Any individual, employee/s of a company, any person who registers
        with this platform and uses the platform for the services provided and takes
        part in the opportunities posted on the platform.
        <p className={classes.medium}>4. What personal information do we collect,
          when and how do we use it?</p>
        4.1. When do we collect information?
        We collect information about you if you register with or use our
        website or online services; purchase one of our services; or
        work with us as a business partner.
        4.2. What are the grounds for data collection?
        It includes performance of a contract, legal or regulatory
        obligation, legitimate interests subject to your fundamental
        rights, consent. We do not use your personal data for activities
        where our interests are overridden by the impact on you (unless
        we have your consent or are otherwise required or permitted to
        by law). For some services, providing your personal information
        will be mandatory to avail the service. Your consent will be
        respected and you are free to decline consent. In addition, when
        you use Usurp site services, you may make some of your
        personal information public. If you post personal information
        online that is accessible to the public, you may receive
        unsolicited messages from other parties and Usurp will not be
        responsible for the use or misuse of such information.
        4.3. What personal data do we collect directly and
        indirectly from you when you visit our website?
        In order to provide you with the full range of our services we
        may require you to provide some personal information. We will
        collect the following types of information:
        4.3.1. Personal Information: Personal data includes any
        information about any user from which that person can be
        identified. It does not include personal data where the
        identity has been removed (anonymous data). For
        example: full name, e-mail address, Location (country
        and state), mobile/telephone number, login details
        (including username and password), date of birth, job and
        employment details (career related information), if
        applicable;
        4.3.2. Log File and session Information: your computer’s IP
        address and device ID, Operating System, browser type,
        time zone and location, internet service provider, the type
        of camera, microphone and speaker installed in your
        device and any other such information as may be
        necessary for us to provide our services. We use this
        information to identify you and log your use. We also use
        session cookies to provide a smoother experience on our
        platform. Session cookies remember your computer and
        track your movement in that session so you don’t have to
        re-enter your details as you navigate through the platform
        on our more secure services.
        4.3.3. To improve our platform we will collect information using
        Analytics Services such as cookies that are files placed
        on your device that will anonymously collect your log file
        information and behavioural information like the web
        pages you visit and add-ons.
        4.3.4. We will also collect information associated with your
        interaction with this Platform like the details of your
        Platform usage, clicks on the platform, your browsing
        behaviour and preferences data as part of aggregate
        data.
        4.3.5. Your Responses to the tests taken on this platform like
        tournaments, quizzes, gamified and automated
        bootcamps, Aptitude tests and other such gamified
        assessments available on our platform.
        4.3.6. We use third party applications such as RazorPay and
        PayU among others for payments on the platform. These
        Applications have their own privacy policy and your
        information will be processed according to that, this
        privacy policy does not apply to those applications and
        websites.
        4.3.7. Any personal information about you that we receive from
        third parties will be processed according to this privacy
        policy. For example, you may have given a third party
        website some information categorised as personal
        information and if you use them to login to Usurp, we may
        receive information from your profile on that website as
        per their privacy policy. The affiliated sites may have a
        different privacy policy based on their country and
        services and we suggest that you read their privacy
        policies.
        4.3.8. Test Results.
        4.3.9. Information you provide in various forms available on the
        platform.
        4.3.10. Marketing preferences if consented to. Usurp will also
        use direct marketing tools to send you customised
        content and advertisements which serve our interest and
        purpose. However you have the right to opt-out of direct
        marketing by following the unsubscribe link or by
        contacting us at support@usurp.tech. Usurp may provide
        links that enable us to track the user's interaction with
        these links. We use this information to improve our
        search technology, customise content and advertising.
        4.4. How is my personal data used if I take a Usurp
        assessment ?
        We may also use your Personal Information for several reasons
        including but not limited to:
        4.4.1. To contact you: your email address, phone number and
        location is shared with interested clients, recruitment
        agencies, organisations, companies and individuals. We
        will not share your information with third parties unless
        they are looking to offer you employment opportunities
        and/or have explicitly registered for our services.
        4.4.2. Analytics and marketing: cookies placed on your device
        through this platform will be used to collect and create
        statistics such as traffic, demographic patterns, etc to
        help us improve our marketing and analytics strategies
        4.4.3. Service providing and optimisation: Information that is
        essential to provide our services to the user and create
        the ideal personalised experience.
        4.4.4. Provide our clients with data: To collect and use
        anonymised and aggregated test data to help map
        demographics to provide a benchmark to the clients
        which is crucial to provide and improve our services
        4.4.5. Information given voluntarily will be utilised by Usurp only.
        Your password and other sensitive information such as
        payment information will be encrypted using JWT and the
        application’s encryption system. As we will be using third
        party apps for payments, their privacy policy and security
        measures will apply.
        4.4.6. Internally we use your personal Information:
        4.4.6.1. To improvement our services and platform
        4.4.6.2. To contact you about new opportunities and tests
        4.4.6.3. To notify you of the test and application results
        4.4.6.4. To send you advertisements and promotional
        materials from our contest sponsors and
        advertisers
        4.4.6.5. For password reminder, e-mail confirmation and
        registration confirmation
        4.4.6.6. Newsletters and special offers on our platform
        available to you
        4.4.6.7. To notify you of changes in the service's privacy
        policy or terms of use and user agreement. We will
        not reduce your rights without your consent.
        4.4.6.8. Event communications like information on a new
        event, Invites to those events, reminders, etc
        4.4.6.9. To address your questions, complaints, issues and
        inquiries
        4.5. Information shared directly to a client by a user will be governed
        by the client’s privacy policy. Usurp is not liable for the
        information that a user provides directly to the client and this
        policy does not apply to such information.
        <p className={classes.medium}>5. With whom is my personal data shared?</p>
        Usurp will send your information to others when:
        5.1.1. You give us consent to share some information
        5.1.2. Your information is required to be shared to provide the
        services you requested
        5.1.3. Your information needs to be shared with the client for the
        opportunity in which you registered to participate;
        5.1.4. We need to share the information to our service
        providers, for example our IT and Communications
        service providers. The service providers will only receive
        data in line with their function on our platform.
        5.1.5. When we receive legal requests such as subpoenas,
        court orders and search warrants and other requests from
        any Indian or foreign government, security, defence,
        revenue, regulatory or other authority, agency or officer;
        5.1.6. We find that your actions on our platform violate the
        terms of service or any of our user guidelines for specific
        services and tournaments.
        5.1.7. Cookies that are set on your computer through
        advertisements on our platform will be governed by the
        advertising company’s privacy policy and not this privacy
        policy.
        <p className={classes.medium}>6. Legal rights</p>
        6.1.1. Right to access and portability: you are entitled to ask us
        if we are processing your personal data and request a
        copy of the same.
        6.1.2. The user can choose to share their contact information
        only while they look for opportunities (jobs and tests). The
        user will be able to turn on/off the option of sharing their
        contact information with our clients. This is only
        applicable where your data does not need to be
        processed to meet a contractual or other legal
        requirement.
        6.1.3. Objection to automated decisions, direct marketing and
        automated decisions: you have the right to object where
        we are processing your personal information for direct
        marketing purposes. None of the Usurps assessments
        should ever be used in isolation in the recruitment or
        candidates development process. They are provided for a
        broader decision-making process alongside all of the
        other information that the customer has collected.
        6.1.4. Profiling and automated decisions: none of the
        assessments Usurp provides should ever be used in
        isolation in the recruitment or personnel development
        process. It is provided to its customers as part of their
        wider decision-making process alongside all of the other
        information that the customer has collected.
        6.1.5. Rectification: you can request us to modify and/or correct
        your personal information by emailing us on
        support@usurp.tech
        6.1.6. You can decline cookies by modifying your browser
        settings, however this may prevent you from accessing
        the platform without hindrance.
        6.1.7. Your rights are subject to our terms of service. We may
        not be able to address some of your requests if it may
        violate a third party’s privacy or when we have legal
        liberty to address your request in a different way.
        6.1.8. Erasure: if you wish to delete your account with us, you
        can send an email to support@usurp.tech from your
        registered email Id
        6.1.9. Withdrawing Consent: Your consent is important. When
        you give us consent to use your personal information, you
        also have the right to revoke that consent. You can do
        this by contacting us on support@usurp.tech.
        <p className={classes.medium}>7. How we store and protect personal
          information:</p>
        7.1. Measures we take to ensure data safety:
        7.1.1. all servers used for assessments are in thoroughly secure
        environments;
        7.1.2. regularly checking our network security and cross
        checking with external experts;
        7.1.3. allowing no access to personal data; and
        7.1.4. having access control systems and regular training on
        data protection and IT security.
        7.2. Usurp Information & Data Security
        7.2.1. The confidentiality of our users and client’s information is
        extremely crucial. Our systems and policies are designed
        to protect personal information as well as our own
        intellectual property. Our extensive Information Security
        program prevents unauthorised access to information and
        protects against potential threats. We utilise a “secure by
        design” framework to secure data at the design phase of
        our services. We deploy a defence-in-depth strategy that
        has layers securing our data assets in the cloud. The
        technology layers include firewalls, intrusion detection
        systems, real time alerts, vulnerability scanner log
        monitoring and anti-virus protection. For instance, your
        Usurp account information is password-protected and
        information transfers are encrypted using JSON Web
        Token (JWT) for your privacy and security.
        7.2.2. Our commitment to cloud services allows us to rely on
        their security investments as the foundation, allowing
        Usurp to focus specifically on data protection. The
        security policy follows industry-standard policies and best
        practices like need-to-know and
        principle-of-least-privileges. We actively comply with
        Indian jurisdiction laws as well as our company policies.
        Our security policy meets the requirements of the IT Act
        and the Information Technology (Procedure and
        Safeguards for Interception, Monitoring and Decryption of
        Information) Rules, 2009. We recognise user, client and
        business information security as pivotal and every
        member of Usurp is trained to understand their capacity
        and obligation to ensure this.
        7.2.3. As a legal caveat, Usurp can not guarantee the accuracy
        in the aforementioned analysis. Usurp specifically
        disclaims liability for any damages,injury, claims, or
        losses that may emerge from any mistakes or omissions
        in the aforementioned paragraph, whether caused by
        Usurp or its sources, or reliance upon any
        recommendation made by Usurp.
        7.3. How long do we retain data?
        We will retain your personal data in our databases for as long
        as necessary to fulfil the purposes we collected it for and in
        accordance with our document management, retention and
        destruction policy and applicable laws. This period may extend
        beyond the end of your relationship with us, but it will be only as
        long as it is necessary for us to have sufficient information to
        respond to any issues that may arise later. Once you deactivate
        your account with this platform, your personal data is stored for
        a period of 6 years. When we process personal information
        while performing a contract or for a tournament, we keep the
        information for 6 years from your last interaction with our
        platform. We may also need to retain certain information to
        prevent fraudulent activity; to protect ourselves against liability,
        permit us to pursue available remedies or limit any damages
        that we may sustain; or if we believe in good faith that a law,
        regulation, rule or guideline requires it.
        In some circumstances we may anonymise aggregate personal
        data for research or statistical purposes in which case we may
        use this information indefinitely without further notice to you.
        <p className={classes.medium}>8. Contact us</p>
        8.1. To exercise any of your rights where Usurp is acting as a data
        controller or should you believe that there has been a loss or
        unlawful use or disclosure of this data, please contact our data
        protection officer at support@usurp.tech
        8.2. We may require specific information to identify you and verify
        access to your personal data (or to exercise any of your other
        rights) as a security measure. We attempt all legitimate requests
        within one calendar month except if your request is particularly
        complex or there are many requests. In this case, we will notify
        you and keep you updated.</p>
    </div>
  )
}

export default PrivacyPolicy;