import React from 'react';
import classes from "./terms.module.css"

const TermsOfService = () => {

  return (
    <div className={classes.container}>
      <p className={classes.extraLarge}>Service terms</p>
      <p className={classes.small}>
        By Usurp HRtech Solutions
        Effective date: 22 December 2022
        Thank you for using Usurp! We're happy you're here. Please familiarise yourself
        with the following terms and conditions carefully as your use of Usurp HRtech
        Solutions’ products, software, services and websites is conditioned on your
        acceptance and compliance without modification of the terms, conditions, and
        notices contained herein.
        For your convenience, we have presented these terms in a short non-binding
        summary followed by the full legal terms.
        1. General provisions
        1.1. Contract
        1.2. Services
        1.3. Modification
        1.4. Advertising Material and Links to Third Party Websites
        1.5. Privacy Policy
        1.6. Choice of Law and Forum
        1.7. Severability, No Waiver, and Survival
        1.8. Interpretation
        1.9. Correction of Errors and Inaccuracies
        2. Obligations
        2.1. Account Controls
        2.2. Required Information
        2.3. Account Security
        2.4. Respect
        2.5. Fee Payment
        3. Representations and warranties
        3.1. Disclaimer of Certain Warranties
        3.2. Limitation of liability
        3.3. Release and indemnification
        4. Rights in content and submissions
        4.1. Content
        4.2. Licence grant to us
        4.3. Duration
        4.4. Intellectual property notice
        5. Cancellation and Termination
        5.1. Account terminations
        5.2. Cancellations and refund policy
        6. Communications with Usurp
        6.1. Report abuse
        6.2. Electronic communications
        6.3. Legal notice
        6.4. Contact us
        <p className={classes.large}>1. General provisions</p>
        These Terms of Use apply to your access and use of the website Usurp.tech (the
        “Site”). In these Terms, references to "you", "User", “Visitor” shall mean the end
        user accessing the Website, its contents and using the Services offered through the
        Website, and "we", "us" and "our" shall mean Usurp HRtech Solutions.
        <p className={classes.medium}>Contract</p>
        You can accept the Terms by clicking to accept or agree to the Terms, where this
        option is made available to you by Usurp HRtech Solutions in the user interface
        for any Service, using the Services or browsing the website. You agree that by
        clicking “Join Now”, “Join”, “Sign Up” or similar, registering, accessing or using
        our services, you are agreeing to enter into a legally binding contract with us (even
        if you are using our Services on behalf of a company). If you do not agree to this
        contract (“Contract” or “User Agreement”), do not click “Join Now” (or similar)
        and do not access or otherwise use any of our Services. If you wish to terminate
        this contract, at any time you can do so by closing your account and no longer
        accessing or using our Services.
        <p className={classes.medium}>Services</p>
        This Contract applies to usurp.tech and other Usurp-related sites, apps,
        communications and other services that state that they are offered under this
        Contract (“Services”), including the offsite collection of data for those Services,
        such as our ads and plugins. Registered users of our Services are “Members” and
        unregistered users are “Visitors”; the contract applies to both.
        <p className={classes.medium}>Modification</p>
        You are responsible for checking these Terms of Use periodically to remain in
        compliance with these terms. Your use of the Site after any amendment to the
        Terms of Use shall constitute your acceptance of these terms and you also agree to
        be bound by any such changes/revisions.
        If we make material changes that negatively impact your use of our services or if
        we stop offering a service, we’ll provide you with reasonable advance notice,
        except in urgent situations such as preventing abuse, responding to legal
        requirements, or addressing security and operability issues. We’ll also provide you
        with an opportunity to export your content from your Usurp HRtech Solutions
        Account using Usurp HRtech Solutions Takeout, subject to applicable law and
        policies.
        <p className={classes.medium}>Advertising Material and Links to Third Party Websites</p>
        The Platform may include links to third party websites and advertising materials
        that are controlled and maintained by others. Any link to other websites is not an
        endorsement of such websites and you acknowledge and agree that we are not
        responsible for the content or availability of any such sites.
        Part of the Site contains advertising information or promotion material or other
        material submitted to Usurp HRtech Solutions by third parties. Usurp HRtech
        Solutions reserves the right to omit, suspend or change the position of any
        advertising material submitted for insertion. Acceptance of advertisements on the
        Site will be subject to these terms and conditions.
        <p className={classes.medium}>Privacy Policy</p>
        The User hereby consents, expresses and agrees that they have read and fully
        understands the Privacy Policy of Usurp HRtech Solutions in respect of the
        Website. The user further consents that the terms and contents of such Privacy
        Policy are acceptable to them.
        <p className={classes.medium}>Choice of Law and Forum</p>
        These Terms of Use shall be governed by and construed in accordance with the
        laws of India, without regard to its conflicts of law rules. You expressly agree that
        the exclusive jurisdiction and venue for any claim or action arising out of or
        relating to these Terms of Use or your use of this Site shall be filed only in the
        state or federal courts located in India, and you further agree and submit to the
        exercise of personal jurisdiction of such courts for the purpose of litigating any
        such claim or action.
        <p className={classes.medium}>Severability, No Waiver, and Survival</p>
        This Agreement, and any product specific Usurp terms of sale applicable to
        products purchased from Usurp, constitute the entire agreement between you and
        Usurp concerning this Site and the purchase of products from Usurp, and replaces
        all foregoing and contemporaneous communications and proposals, whether oral,
        written, or electronic, between you and Usurp with respect to this Site. If any part
        or portion of these Terms of Use or an product specific Usurp terms of sale is held
        invalid or unenforceable, that portion shall be construed in a manner consistent
        with applicable law to reflect, as nearly as possible, the original intentions of the
        parties, and the remaining portions shall remain in full force and effect.
        Any failure on our part to enforce any provision of this Agreement will not be
        considered a waiver of our right to enforce such provision. Our rights under this
        Agreement will survive any termination of this Agreement.
        <p className={classes.medium}>Interpretation</p>
        In these terms, “include” or “including” means “including but not limited to,” and
        any examples we give are for illustrative purposes.
        <p className={classes.medium}>Correction of Errors and Inaccuracies</p>
        The Content may contain typographical errors or other errors or inaccuracies.
        Usurp therefore reserves the right to correct any errors, inaccuracies or omissions
        and to change or update the Content at any time without prior notice. Usurp does
        not, however, guarantee that any errors, inaccuracies or omissions will be
        corrected.
        <p className={classes.large}>2. Obligations</p>
        Subject to your agreement to and compliance with the terms of this Agreement, we
        grant to you a non-exclusive, non-transferable, non-sharable, revocable, limited
        licence to use the Services (including the Software in connection with the
        Services) solely for your personal, non-commercial use to the Services in
        accordance with this Agreement.
        <p className={classes.medium}>Account Controls</p>
        Users: Subject to these Terms, you retain ultimate administrative control over your
        Personal Account and the Content within it.
        Organisations: The "owner" of an Organization that was created under these Terms
        has ultimate administrative control over that Organization and the Content within
        it. Within the Service, an owner can manage User access to the Organization’s data
        and projects. An Organization may have multiple owners, but there must be at
        least one Personal Account designated as an owner of an Organization. If you are
        the owner of an Organization under these Terms, we consider you responsible for
        the actions that are performed on or through that Organization.
        <p className={classes.medium}>Required Information</p>
        You must provide a valid email address in order to complete the signup process.
        Additional information will have to be provided by the user when you are
        accepting these terms on behalf of a legal entity (in which case we need more
        information about the legal entity) or if you opt for a paid Account, in which case
        additional information will be necessary for billing purposes. User shall be solely
        responsible for the adequacy and accuracy, of the User Materials and all
        information, data, and other materials therein furnished to Usurp by or on behalf
        of the user by the user or its agents or affiliates, and assumes all liability therefor.
        <p className={classes.medium}>Account security</p>
        You agree and understand that you are responsible for maintaining the
        confidentiality of passwords associated with any account you use to access the
        Services. Accordingly, you agree that you will be solely responsible to Usurp
        HRtech Solutions for all activities that occur under your account. If you become
        aware of any unauthorised use of your password or of your account, you agree to
        notify Usurp HRtech Solutions immediately at support@usurp.tech.
        As between you and others (including your employer), your account belongs to
        you. However, if the Services were purchased by another party for you to use (e.g.
        bought by your employer), the party paying for such Service has the right to
        control access to and get reports on your use of such paid Service; however, they
        do not have rights to your personal account.
        <p className={classes.medium}>Service eligibility</p>
        You must be a human to create an Account. Accounts registered by "bots" or other
        automated methods are not permitted.
        One person or legal entity may maintain no more than one free Account.
        You must be of the age 13 or older. If we learn of any User under the age of 13, we
        will terminate that User’s Account immediately.
        Your login may only be used by one person — i.e., a single login may not be
        shared by multiple people. A paid organisation may only provide access to as
        many Personal Accounts as your subscription allows.
        <p className={classes.medium}>Respect</p>
        You agree and undertake to use the Website and the Service only to post and
        upload messages and material that are proper. By way of example, and not as a
        limitation, you agree and undertake that when using a Service, you will respects
        the legal rights of others, comply with applicable laws and not abuse or harm
        others, yourself or the services, follow code of conduct or other guidelines, which
        may be applicable for or to any particular Service.
        <p className={classes.medium}>Fee Payments</p>
        Usurp HRtech Solutions reserves the right to charge listing/advertising/product
        usage fees as well as transaction fees based on certain completed transactions
        using the Usurp HRtech Solutions Services. Usurp HRtech Solutions further
        reserves the right to alter any and all fees from time to time, without notice. The
        User shall be liable to pay all applicable charges, fees, duties, taxes, levies and
        assessments for availing the Usurp HRtech Solutions Services.
        We may store and continue billing your payment method (e.g. credit card) even
        after it has expired, to avoid interruptions in your Services and to use to pay other
        Services you may buy.
        All of your purchases of Services are subject to our refund policy. Failure to pay
        these fees will result in the termination of your paid Services.
        <p className={classes.large}>3. Usurp HRtech Solutions representations and warranties</p>
        <p className={classes.medium}>Disclaimer of Certain Warranties</p>
        We provide the software, the account, the services, promotion program, resources
        and all other services on an "As is" basis, and hereby expressly disclaim all
        warranties or conditions of any kind, written or oral, express, implied or statutory,
        including without limitation any implied warranty of title, noninfringement,
        merchantability or fitness for a particular purpose. Without limiting the foregoing,
        we do not warrant or represent that the software, the account, the services,
        resources or other services will meet your requirements, or that the operation of
        the software, the account, the services, resources or other services will be
        uninterrupted, error-free, continuous, secure, virus-free, or that the defects in the
        software, the account, the services, resources or other services will be corrected.
        We do not warrant or make any representation regarding the use or the results of
        the use of the software, the account, the services, resources or other services or
        related documentation in terms of their correctness, accuracy, quality, reliability,
        appropriateness for a particular task or application, or otherwise. No oral or
        written information or advice given by us or our authorised representatives shall
        create a warranty or in any way increase the scope of this warranty. You are
        entirely responsible for and assume all risk for use of the software, the account, the
        services, resources or other services. You should not use Services in high-risk
        activities where substantial damage could result if an error occurred. We do not
        warrant or represent that our security procedures will prevent the loss or improper
        access to your data. We are not responsible for transmission errors or corruption or
        security of information carried over telecommunication lines or for any foul/unfair
        play or any breach of this agreement by any user or loss of resources or records or
        other data (in particular but without limitation for loss of resources pursuant to any
        prohibited trading activities).
        In the event that we choose to grant any awards or prizes (whether pursuant to any
        marketing activities or events or otherwise), any award or prize may be substituted
        or withheld by us for any reason whatsoever as we may deem fit in our sole
        discretion. You further agree that in the event of any controversy or conflict or
        dispute with regard to the award or prize or other results of any activity or event
        (including without limitation due to an error in the system display), the decision by
        us as to the award or prize or whether the same is to be granted or otherwise as to
        the results of any activity or event shall be final.
        <p className={classes.medium}>Limitation of Liability</p>
        Usurp HRtech Solutions has endeavoured to ensure that all the information on the
        Website is correct, but Usurp HRtech Solutions neither warrants nor makes any
        representations regarding the quality, accuracy or completeness of any data,
        information, product or Service. In no event shall Usurp HRtech Solutions be
        liable for any direct, indirect, punitive, incidental, special, exemplary,
        consequential damages or any other damages resulting from: the use or the
        inability to use the Services; unauthorised access to or alteration of the user's
        transmissions or data; your violation of any applicable laws; any other matter
        related to the services; including, without limitation, damages for loss of use, data
        or profits, arising out of or in any way connected with the use or performance of
        the website or service. Neither shall Usurp HRtech Solutions be responsible for
        the delay or inability to use the website or related services, the provision of or
        failure to provide services, or for any information, software, products, services and
        related graphics obtained through the website, or otherwise arising out of the use
        of the website, whether based on contract, tort, negligence, strict liability or
        otherwise. Further, Usurp HRtech Solutions shall not be held responsible for
        non-availability of the Website during periodic maintenance operations or any
        unplanned suspension of access to the website that may occur due to technical
        reasons or for any reason beyond Usurp HRtech Solutions’s control.
        The user understands and agrees that any material and/or data downloaded or
        otherwise obtained through the website is done entirely at their own discretion and
        risk and they will be solely responsible for any damage to their computer systems
        or loss of data that results from the download of such material and/or data.
        Notwithstanding any other provision in this Agreement, we shall not be obligated
        to refund any portion of any Fee or otherwise provide any compensation or have
        any liability by reason of any interruption of the Services by reason of any of the
        circumstances described in the above clauses.
        The foregoing applies even if we have been advised of the possibility of or could
        have foreseen the damages. In those conditions that do allow the exclusion or
        limitation of liability for the damages; you agree that the disclaimers, limitation of
        liability, releases, waivers contained in this Agreement shall apply to the fullest
        extent permitted by any applicable law.
        Usurp HRtech Solutions’s total liability arising out of or relating to these terms is
        limited to the greater of (1) INR10000 or (2) 125% of the fees that you paid to use
        the relevant services in the 12 months before the breach.
        <p className={classes.medium}>Release and Indemnification</p>
        You understand and agree that you are personally responsible for your behaviour
        on the Site. You agree to indemnify, defend and hold harmless Usurp HRtech
        Solutions, its parent companies, subsidiaries, affiliated companies, joint venturers,
        business partners, licensors, employees, agents, and any third-party information
        providers from and against all claims, losses, expenses, damages and costs
        (including, but not limited to, direct, incidental, consequential, exemplary and
        indirect damages), and reasonable attorneys' fees, resulting from or arising out of
        your use, misuse, or inability to use the Site or the Content, or any violation by
        you of these terms.
        This indemnity covers any liability or expense arising from claims, losses,
        damages, judgments, fines, litigation costs, and legal fees.
        Nothing in these Terms shall affect your statutory rights as a consumer.
        <p className={classes.large}>4. Rights in content and submissions</p>
        During the term of this Agreement, the user authorises and grants any rights,
        permissions and licences to Usurp in or relating to all materials that are necessary
        or useful to perform the services under this Agreement or to enforce Usurp’s rights
        under this Agreement and to make their materials available to subscribers and
        subcontractors or other third parties in providing the services.
        <p className={classes.medium}>Usurp HRtech Solutions content</p>
        Some of our services include content that belongs to us — for example, many of
        the visual illustrations you see in our enterprise tools. You may use our content as
        allowed by these terms and any service-specific additional terms, but we retain
        any intellectual property rights that we have in our content. Don’t remove,
        obscure, or alter any of our branding, logos, or legal notices.
        <p className={classes.medium}>User generated content</p>
        As a User, you may submit content to us. Any and all content Users submit to us
        via the Game or the Service (“user Content”), and any intellectual property rights
        in the user Content belong to the us from the moment it is created.
        If you're posting anything you did not create yourself or do not own the rights to,
        you agree that you are responsible for any Content you post; that you will only
        submit Content that you have the right to post; and that you will fully comply with
        any third party licences relating to your content. Accordingly, we assume no
        liability for any action or inaction regarding transmissions, communications, or
        content provided by any user or third party.
        You have no obligation to provide any content to our services and you’re free to
        choose the content that you want to provide.
        <p className={classes.medium}>Licence Grant to Us</p>
        We need your permission if your intellectual property rights restrict our use of
        your content. You provide us with that permission through this licence. This
        licence covers your content if that content is not protected by intellectual property
        rights. This licence doesn’t affect your privacy rights – it’s only about your
        intellectual property rights.
        This licence is for the purpose of operating and improving the services, which
        means allowing the services to work as designed and creating new features and
        functionalities. This includes using automated systems and algorithms to analyse
        your content for spam and malware, to recognize patterns in data and to customise
        our services for you, developing new technologies and services for us consistent
        with these terms. This licence also includes the right to do things like copy it to
        our database and make backups; show it to you and other users; parse it into a
        search index or otherwise analyse it on our servers; share it with other users; and
        perform it, in case Your Content is something like audio or video.
        You represent, permit and covenant that your content to Usurp.com are not
        confidential or exclusive, and you grant Usurp the worldwide, royalty free,
        continuous, irrevocable, non-exclusive, transferrable and fully sublicensable right
        and licence to reproduce, modify, adapt, publish, translate, create derivative works
        from, distribute, perform and display such submissions (in whole or in part), in
        any form, media, or technology now known or later developed. You also
        represent, permit and covenant that you will only submit bonafide orders for
        products and services through the Site.
        If you provide a trademark (e.g., company logo) owned by you, your company,
        your customer or any other third party, to be used by Usurp in connection with the
        sale of products or services to you or your customers (including, without
        limitation, the creation of purchase orders), you also grant to Usurp a royalty-free,
        non-exclusive right and licence to use such trademark in connection with its
        activities associated with sales to you, subject to such reasonable restrictions as
        you may require. We have no liability or responsibility to anyone for performance
        or nonperformance of the activities described in this section. Where applicable and
        to the extent permitted under applicable law, you also agree to waive, and not to
        enforce, any "moral rights" or equivalent rights, such as your right to be identified
        as the author of any User Content, including Feedback, and your right to object to
        derogatory treatment of such User Content.
        Usurp reserves the right to remove from the Site any of your content submissions
        at any time, with or without cause. Without limiting the foregoing, Usurp has the
        right to fully cooperate with any law enforcement authorities or court order
        requesting or directing us to disclose the identity or other information of anyone
        posting any materials on or through the Site. You waive and hold harmless usurp
        and its affiliates, licensors and service providers from and against any claims
        resulting from any action taken by any of the foregoing parties during, or taken as
        a consequence of, investigations by such parties or law enforcement authorities.
        <p className={classes.medium}>Duration</p>
        This licence lasts for as long as your content is protected by intellectual property
        rights. If you remove from our services any content that’s covered by this licence,
        then our systems will stop making that content publicly available in a reasonable
        amount of time. The exception is if you already shared your content with others
        before removing it.
        <p className={classes.medium}>Intellectual property notice</p>
        Usurp respects the intellectual property rights of others and expects the same from
        site visitors. Although we give you permission to use our services, we retain any
        intellectual property rights we have in the services. We will respond to notices of
        alleged copyright infringement as according to applicable law and are properly
        given to us. Please provide (i) a signature of the copyright owner; (ii) the work
        whose copyright is infringed; (iii) the material that is claimed to be infringing or
        the concern of infringing activity which has to be removed; (iv) your contact
        information including address, telephone number, and an email address; (v) a
        statement by you that you have a good faith belief that use of the material in the
        manner complained of is not authorised by the copyright owner, its agent, or the
        law; and (vi) a statement that the information in the notification is accurate, and,
        under penalty of perjury, that you are authorised to act on behalf of the copyright
        owner.
        Usurp HRtech Solutions reserves the right to remove content alleged to be
        infringing without prior notice and at its sole discretion. We may also terminate a
        user’s account if the user is determined to be a repeat infringer. Please reach us at:
        Usurp HRtech Solutions
        Name
        Address
        Number
        E-mail
        <p className={classes.large}>5. Cancellation and Termination</p>
        Account terminations by You
        You may stop using the Service at any time. Follow these instructions to delete the
        Service from your Usurp HRtech Solutions Account, which involves closing your
        YouTube channel and removing your data.
        You may stop using the Service at any time. If you want to terminate your legal
        agreement with Usurp HRtech Solutions, you may do so by (a) notifying Usurp
        HRtech Solutions at any time and (b) closing your accounts for all of the Services
        which you use, where Usurp HRtech Solutions has made this option available to
        you. Your notice should be sent to Usurp HRtech Solutions’s email address. You
        also have the option to download a copy of your data first.
        <p className={classes.medium}>Terminations and Suspensions by Usurp</p>
        We reserve the right to suspend or terminate your account or your access to all or
        part of the Service if: (a) you materially or repeatedly breach this Agreement; (b)
        we are required to do so to comply with a legal requirement or a court order; or (c)
        we believe there has been conduct that creates (or could create) liability or harm to
        any user, other third party, or our Affiliates; (d) the partner with whom Usurp
        HRtech Solutions offered the Services to you has terminated its relationship with
        Usurp HRtech Solutions or ceased to offer the Services to you; or (e) the provision
        of the Services to you by Usurp HRtech Solutions is, in Usurp HRtech Solutions’s
        opinion, no longer commercially viable.
        When these Terms come to an end, all of the legal rights, obligations and liabilities
        that you and Usurp HRtech Solutions have benefited from, been subject to (or
        which have accrued over time whilst the Terms have been in force) or which are
        expressed to continue indefinitely, shall be unaffected by this cessation, and the
        provisions of Indemnification and Governing Law shall continue to apply to such
        rights, obligations and liabilities indefinitely.
        <p className={classes.medium}>Notice for Termination or Suspension</p>
        We will notify you with the reason for termination or suspension unless we
        reasonably believe that to do so: (a) would violate the law or the direction of a
        legal enforcement authority; (b) would compromise an investigation; (c) would
        compromise the integrity, operation or security of the Service; or (d) would cause
        harm to any user, other third party, or our Affiliates. Where we are terminating
        your use for Service changes, where reasonably possible, you will be provided
        with sufficient time to export your Content from the Service.
        <p className={classes.medium}>Effect of Account Suspension or Termination</p>
        If your account is terminated or your access to the Service is restricted, you may
        continue using certain aspects of the Service (such as viewing only) without an
        account, and this Agreement will continue to apply to such use. If you believe that
        the termination or suspension of your account has been made in error, you can
        appeal.
        <p className={classes.medium}>Cancellation/Refund Policy</p>
        All sales/purchase of services are final with no refund or exchange permitted.
        However, if in a transaction performed by you on the site, money has been charged
        to your card or bank account without the delivery of the services, then you may
        inform us by sending an email to support@usurp.tech or an email address
        mentioned on the Contact Us page. Usurp HRtech Solutions shall investigate the
        incident and if it is found that money was indeed charged to your card or bank
        account without delivery of the service, then you will be refunded the money
        within 21 working days from the date of receipt of your email. All refunds will be
        credited back to the source of the payment after deducting the service charges and
        taxes(if applicable). It will take 3-21 days for the money to show in your bank
        account depending on your bank’s policy.
        <p className={classes.large}>6.Communications with Usurp</p>
        <p className={classes.medium}>Report Abuse</p>
        As per these Terms, users are solely responsible for every material or content
        uploaded on to the website. Usurp HRtech Solutions does not verify, endorse or
        otherwise vouch for the contents of any user or any content generally posted or
        uploaded on to the website. Users can be held legally liable for their contents and
        may be held legally accountable if their contents or material include, for example,
        defamatory comments or material protected by copyright, trademark, etc. Users
        are bound to follow the rules of the challenges and quizzes offered on the
        platform, the failure of which will lead to the removal of that user’s account and
        prohibition from using the services on this platform. Users are prohibited from
        tampering with the services in any manner. If you come across any abuse or
        violation of these Terms, please report to support@usurp.tech
        <p className={classes.medium}>Electronic Communication Required</p>
        For contractual purposes, you consent to receive communications from us in an
        electronic form via the email address you have submitted or via the Service; and
        agree that all Terms of Service, agreements, notices, disclosures, and other
        communications that we provide to you electronically satisfy any legal
        requirement that those communications would satisfy if they were on paper. This
        section does not affect your non-waivable rights.
        <p className={classes.medium}>Legal Notice</p>
        We provide our services using reasonable skill and care. If we don’t meet the
        quality level described, you agree to tell us and we’ll work with you to try to
        resolve the issue.
        Communications made through email or our Support's messaging system will not
        constitute legal notice to us or any of its officers, employees, agents or
        representatives in any situation where notice to us is required by contract or any
        law or regulation. Legal notice to us must be in writing.
        All notices, requests, demands, and other communications under this Agreement
        must be a clear statement (e.g. a letter sent by post or e-mail) addressed to the
        following:
        <p>Usurp HRtech Solutions</p>
        <p>Name: Komarraju Kiran Chandra S/o Uday Shanker Komarraju</p>
        <p>Address : 8-2-269/S/14, Sagar Society, Road No. 2, Banjara Hills, Hyderabad,
          Telangana - 500034</p>
        <p>Number: +91-9632247617</p>
        <p>E-mail: support@usurp.tech</p>
        Before taking action, we’ll provide you with advance notice when reasonably
        possible, describe the reason for our action, and give you an opportunity to fix the
        problem, unless we reasonably believe that doing so would cause harm or liability
        to a user, third party, or us; violate the law or a legal enforcement authority’s
        order; compromise an investigation or compromise the operation, integrity, or
        security of our services.
        <p className={classes.medium}>Questions</p>
        Questions about the Terms of Service? Contact us.
        Usurp HRtech Solutions
        <p>Name:</p>
        <p>Address:</p>
        <p>Number:</p>
        <p>E-mail:</p>
        <p className={classes.rights}>Usurp HRtech Solutions All rights reserved.
          Version 1.0</p></p>
    </div>
  )
}

export default TermsOfService




