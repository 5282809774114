import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ChipInput from 'material-ui-chip-input';
import { useDispatch } from "react-redux";
import { getPosts, getPostsBySearch } from '../../actions/posts';
import { Marginer } from "../Auth/Marginer";
import Posts from '../Posts/Posts';
import Form from '../Form/Form';
import Helper from '../Helper/Helper';
import axios from 'axios';
import classes from "./contentFeed.module.css"
import { fetchTopUsers } from "../../api/analysis";
import Top5Posts from './Top5Posts';
import Leaderboard from './Leaderboard';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ContentFeed = () => {
  const [currentId, setCurrentId] = useState(null);
  const [search, setSearch] = useState('');
  const [tags, setTags] = useState([]);

  const query = useQuery();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const searchQuery = query.get('searchQuery');
  useEffect(() => {
    dispatch(getPosts());
  }, [currentId, dispatch]);

  useEffect(() => {
    dispatch(getPosts());
  }, [currentId, dispatch]);


  const searchPost = () => {
    if (search.trim() || tags) {
      dispatch(getPostsBySearch({ search, tags: tags.join(',') }));
      navigate(`/feed/search?searchQuery=${search || 'none'}&tags=${tags.join(',')}`);
    } else {
      navigate('/feed');
    }
  };
  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      searchPost();
    }
  };
  const handleAdd = (tag) => setTags([...tags, tag]);
  const handleDelete = (tagToDelete) => setTags(tags.filter((tag) => tag !== tagToDelete));


  return (
    <>


      <div className={classes.container} >

        <div className={classes.card}>
          <input className={classes.input} name='search' onKeyPress={handleKeyPress} placeholder='Search Posts' value={search} onChange={(e) => setSearch(e.target.value)} />
          <ChipInput className={classes.input} value={tags} onAdd={handleAdd} onDelete={handleDelete} placeholder="Search Using Skills(press enter)" />
          <button onClick={searchPost} className={classes.button}> Search</button>

        </div>

        <Leaderboard />
        <Helper />
        <Posts setCurrentId={setCurrentId} />

      </div>



    </>
  );
};

export default ContentFeed;