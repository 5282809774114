import React, { useEffect, useState } from 'react'
import { getTemplateQuizes, getQuizsLazyLoading } from "../../APIServices/Template";
import { Button, Grid, CircularProgress, Container } from "@material-ui/core";
import { getQuizBySearch } from '../../APIServices/Template';
import useStyle from "./Style";
import CssBaseline from '@material-ui/core/CssBaseline';
import Quiz from '../Quizs/Quiz/Quiz';
import NavBar from '../NavBar/NavBar';
import Form from 'react-bootstrap/Form';


import InfiniteScroller from "react-infinite-scroll-component";
import QuizT from '../Quizs/Quiz/QuizT';

const Template = () => {
    const classes = useStyle();
    const [page, setPage] = useState(1);

    const [quizs, setQuizs] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [searchValue, setSearchValue] = useState("");

    const [hasMore, setHasMore] = useState(true);
    const [currLength, setCurrentLength] = useState();


    const handlePageLoad = () => {
        setTimeout(() => {
            setCurrentLength(quizs?.length)
            setPage(page + 1)
        }, 300)
    }

    useEffect(() => {

        getQuizsLazyLoading({ page: page })
            .then((result) => {
                setQuizs([...quizs, ...result.data])
                if (currLength === [...quizs, ...result.data].length) {
                    setHasMore(false)
                } else {
                    setCurrentLength([...quizs, ...result.data].length)
                }
                setLoading(false)
            }, error => {
                console.log(error)
            })
    }, [page])
    console.log(quizs);
    const search = (value) => {
        console.log(value);
        getQuizBySearch(value)
            .then((data) => {
                setQuizs(data.data)
                setLoading(false);
            },

                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log(resMessage);
                }
            );

    }

    // const handlePageClick = (event) => {
    //     const newOffset = (event.selected * itemsPerPage) % quizs.length
    //     setItemOffset(newOffset)
    // }

    return (
        <div className={classes.body}>
            <NavBar current="quizs" search={search} />
            <div >
                <div>
                    <CssBaseline />
                    {isLoading ? (<CircularProgress />) : ""}

                    <Container className={classes.cardGrid} maxWidth="lg">
                        <Form className="d-flex">
                            <Form.Control
                                type="search"
                                placeholder="Search"
                                className="me-2"
                                aria-label="Search"
                                value={searchValue}
                                width="5rem"

                                onChange={(e) => { setSearchValue(e.target.value) }}
                            />
                            <>


                                <Button variant="contained" color="primary" onClick={(e) => { search(searchValue) }}>
                                    Search
                                </Button>
                                &nbsp;
                                <Button variant="contained" color="secondary" href="/challenge/home">
                                    All
                                </Button>
                            </>
                        </Form>
                    </Container>
                    <Container className={classes.cardGrid} maxWidth="lg">

                        <div className={classes.grid}>
                            {quizs.map((quiz, i) => (

                                quiz.visibility ? (<QuizT formData={quiz} key={i} />) : ""
                            ))}
                        </div>
                        <div className="App" style={{ textAlign: "center", margin: "auto", alignContent: "center" }}>
                            <InfiniteScroller dataLength={quizs?.length} next={handlePageLoad} hasMore={hasMore} endMessage={<h5 style={{ color: "white", marginTop: '3.5vh' }}>This is the end!</h5>} loader={<h5 style={{ color: "white", marginTop: '2vh' }}>Loading....</h5>} />
                        </div>
                    </Container>


                </div>
                <div>

                </div>
            </div>
        </div>
    )
}

export default Template;
