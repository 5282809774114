import React from 'react';
import classes from "./gettingStarted.module.css"

function GettingStarted() {

  return (
    <div className={classes.container}>
      <div className={classes.card}>
        <h2 className={classes.h2}>Getting started with Usurp</h2>
        <p>1) Create your profile in the profile page.</p>
        <p>2) Start posting articles, projects and informative videos.</p>
        <p>3) Also start posting questions if you need help.</p>
        <p>4) Check for constests and challenges. Participate to get recruited by the top companies in your field.</p>
        <p>5) Level up your profile to join exclusive events, win prizes and opportunities.</p>
      </div>
    </div>
  )
}

export default GettingStarted
