import React, { useState, useEffect } from "react";
import { Container, AppBar, Typography, Grow, Grid, Paper, TextField, Button } from '@material-ui/core';
import { useNavigate, useLocation } from "react-router-dom";
import ChipInput from 'material-ui-chip-input';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Formbar from '../FormBar/FloatingButtonForm';
import { getUserPosts } from '../../actions/posts';
import { Marginer } from "../Auth/Marginer";
import Posts1 from '../Posts/Posts1';

import "../../App.css";
import useStyles from './styles';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PostsOfUser = ({ currentId, setCurrentId, show = true }) => {
  const { post, posts, isLoading } = useSelector((state) => state.posts);
  // console.log(post);
  // console.log(posts);
  const query = useQuery();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchQuery = query.get('searchQuery');
  useEffect(() => {
    dispatch(getUserPosts(currentId));
  }, [currentId, dispatch]);
  console.log(posts);
  if (posts.length === 0 && !isLoading && show) {
    return (
      <div style={{ display: "flex", flexDirection: "column", marginTop: "10vh", alignItems: "center", gap: "10px" }}>
        <Formbar />
        <div className={classes.paper} >
          <h5>
            Please use the Button above to create your first Post!!
          </h5>
        </div>
      </div>
    );


  }


  return (
    <>


      <div>
        <div>

          <div className={classes.gridContainer1} >
            <div>
              <Posts1 setCurrentId={setCurrentId} show={show} />
            </div>
          </div>
        </div>

      </div>


    </>
  );
};

export default PostsOfUser;