import React, {useState} from 'react';


import * as BsIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";
import * as RiIcons from "react-icons/ri";
import * as GiIcons from "react-icons/gi";
import * as TfiIcons from "react-icons/tfi";

export const SidebarData = [

    {
        title: 'Dashboard',
        path: '/recruiterdashboard',
        icon: <MdIcons.MdEmojiEvents/>,
        className:'nav-text'
    },
    {
        title: 'Challenges',
        path: '/myChallenges',
        icon: <GiIcons.GiArcheryTarget/>,
        className:'nav-text'
    }, 
    {
        title: 'Templates',
        path: '/templates',
        icon: <TfiIcons.TfiFiles/>,
        className: 'nav-text'
    },
    {
        title: 'User Feed',
        path: '/feed',
        icon: <RiIcons.RiQuestionnaireFill/>,
        className:'nav-text'
    },
    
    {
        title: 'Profile',
        path: '/profile',
        icon: <BsIcons.BsPersonCircle/>,
        className:'nav-text'
    }

    
     
     
]