import React from 'react'
import { Grid, IconButton, Tooltip } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import useStyles from "./styles";
const ImageComp = (props) => {
    const classes = useStyles();

    return (
        <div style={{ display: "flex", width: "100%", flexWrap: "wrap", gap: "25px" }}>
            {
                props.images ? (
                    props.images?.map((img, i) => (
                        <div style={{ width: "30%", display: "flex", justifyContent: "center", position: "relative" }} key={1}>
                            <div key={i}>
                                <img src={img} width="100%" height="auto" alt="questionImage" />
                                <Tooltip title="Remove Image">

                                    <IconButton style={{
                                        left: '10%',
                                        position: "absolute", top: '0px', zIndex: 999, color: 'var(--opp)'
                                    }}
                                        size="small"
                                        onClick={() => {
                                            props.removeImage(i)
                                        }}>
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>

                            </div>
                        </div>
                    ))
                ) : ""
            }
        </div>
    )
}

export default ImageComp;