import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  media: {
    borderRadius: '15px',
    objectFit: 'cover',
    width: '100%',
    height: '95%',
    "@media (max-width: 800px)": {
      width: "90%",
    },
  },

  media2: {
    borderRadius: '5px',
    objectFit: 'scale-down',
    width: '20vh',
    height: '20vh',
    marginLeft: '2vw',
    marginRight: '2vw',
    "@media (max-width: 600px)": {
      width: '15vh',
      height: '15vh',
      marginBottom: '1vh'
    },

  },
  title: {
    marginTop: '1.5vh',
    fontSize: '4vh',
    color: '#25b8ef',
    "@media (max-width: 780px)" : {
      fontSize: '3vh',
    },
    "@media (max-width: 600px)" : {
      fontSize: '2.5vh'
    },
    "@media (max-width: 480px)" : {
      fontSize: '2vh'
    },
  },
  time: {
    color: '#25b8ef',
    fontSize: "14px",
    "@media (max-width: 780px)" : {
      fontSize: '1.5vh'
    },
    "@media (max-width: 480px)" : {
      fontSize: '1.15vh'
       
    },

  },
  tags: {
    fontSize: '16px',
    marginBottom: '2vh',
    color: '#c935ff',
    wordBreak: 'break-all',
    whiteSpace: 'pre-wrap',
    
    "@media (max-width: 780px)" : {
      fontSize: '1.5vh',
    },
    "@media (max-width: 480px)" : {
      fontSize: '1.3vh'
    },
  },
  paper: {
    display: 'flex',
    padding: '20px',
    borderRadius: '15px',
    width: '90%',
    background: 'var(--card-bg)',
    flexDirection: 'column',
    boxShadow: '5px 5px 15px 5px rgba(5, 5, 10, 0.7), -5px -5px 15px 5px rgba(255, 255, 255, 0.3)',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      flexDirection: 'column',

    },
    "@media (max-width: 1250px)" : {
      width: '90vw',
      marginLeft: '4.5vw',
      marginRight: '4.5vw',
      marginTop: '5vh',
      padding: '15px', 
    }
  },
  section: {
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    "@media (max-width: 800px)": {
      width: "90%",
    },
  },
  name: {
    fontSize: '3vh',
    marginLeft: '1vw',
    color: '#25b8ef',
    "@media (max-width: 780px)": {
      fontSize: '3vh',
      marginRight: '1vw'
    },
    "@media (max-width: 580px)": {
      fontSize: '2.5vh'

    },

  },
  imageSection: {
    margin: '3vw',
    height: '50vh',
    width: '75vw',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  recommendedPosts: {
    display: 'inline-block',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
    },
  },
  loadingPaper: {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    padding: '20px', 
    borderRadius: '30px', 
    height: '39vh',
    marginTop: '25vh',
    background: 'var(--card-bg)',
    boxShadow: "var(--shadow)"
  },
  commentsOuterContainer: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 800px)": {
      flexDirection: " column",
    },
  },
  commentsInnerContainer: {
    height: "max-content",
    maxheight: "40vh",
    overflowY: "auto",
    marginRight: "4vw",
    width: "40vw",
    "@media (max-width: 580px)": {
      fontSize: "1.75vh",
      marginRight: "0",
      width: "100%",
    },
  },
  commentField: {
    borderRadius: '4px',
    padding: "5px",
    width: "100%",
    maxWidth: "100%",
    background: "none",

    border: '1px solid var(--opp)',
    outline: '0px',
    boxShadow: '-1px -1px 2.5px 1px rgba(255, 255, 255, 0.3), 1px 1px 2.5px 1px rgba(5, 5, 10, 0.9)',

  },
  nameDet: {
    display: 'flex',
    direction: "row",
    justifyContent: "flex-start",
    margin: '0px',
    marginRight: '5px',
  },
  pre: {
    whiteSpace: 'pre-wrap',
    fontSize: '16px',
    marginBottom: '2vh',
    color: 'var(--opp)',
    "@media (max-width: 780px)" : {
      fontSize: '1.5vh'
    },
    "@media (max-width: 480px)" : {
      fontSize: '1.3vh',
      marginRight: '5vw',
    },

  },
  commentButton: {
    background: 'var(--opp)',
    width: '10vw',
    padding: "2vh 10vw",
    height: '5vh',
    borderRadius: '5px',
    color: 'var(--card-bg)',
    fontSize: '14px',
    fontFamily: 'Montserrat, sans-serif',
    boxShadow: '-2px -2px 5px 2px rgba(255, 255, 255, 0.3), 2px 2px 5px 2px rgba(5, 5, 10, 0.7)',
    "@media (max-width: 780px)" : {
      fontSize: '1.5vh',
      width: '15vw',
      padding: '1vw 0vw'
       
    },
    "@media (max-width: 480px)" : {
      fontSize: '1.1vh',
      width: '18vw',
      padding: '0vw'
      
       
    },
  },
  comments: {
    fontFamily: 'Montserrat, sans-serif',
    color: 'var(--opp)',
    fontSize: '16px',
    "@media (max-width: 780px)" : {
      fontSize: '1.5vh'
    },
    "@media (max-width: 480px)" : {
      fontSize: '1.3vh' 
    },
  },
  nameComments: {
    fontWeight: '700'
  },
  button: {
    border: '0',
    fontFamily: 'Montserrat, sans-serif',
    "@media (max-width: 480px)": {
      fontSize: '2vh',
      
    },
  },
  recommendedTitle: {
    fontSize: '2.3vh',
    margin: '2vh',
    color: '#25b8ef',
    "@media (max-width: 480px)": {
      fontSize: '1.5vh',
      margin: '1.25vh',
    },
  },
  recommendedName: {
    fontSize: '20px',
    marginLeft: '3vh',
    color: 'var(--opp)',
    "@media (max-width: 480px)": {
      fontSize: '1.25vh',
      marginLeft: '1.25vh',
      

    },
  },
  upvotes: {
    color: '#34b522',
    "@media (max-width: 480px)": {
      fontSize: '1vh',

    },
    "@media (max-width: 620px)": {
      fontSize: '1.25vh',

    },
  },
  downvotes: {
    color: '#f55047',
    "@media (max-width: 480px)": {
      fontSize: '1vh',
    },
    "@media (max-width: 620px)": {
      fontSize: '1.25vh',

    },
  },
  commentOut: {
    width: '50vw',
    "@media (max-width: 780px)": {
      width: '65vw',
      marginRight: '13vw'



    },
    "@media (max-width: 480px)": {
      width: '65vw',
      marginRight: '10vw'



    },
  },
  recommendedContainer: {
    margin: '2vh 0 4vh 0',
    cursor: 'pointer',
    background: 'var(--card-bg)',
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '-3px -3px 8px 3px rgba(255,255,255,0.3), -3px -3px 8px 3px rgba(5,5,10,0.9)',
    width: '20vw',
    "@media (max-width: 1400px)": {
      width: '35vw',

    },
    "@media (max-width: 780px)" : {
      width: '60vw',
      
      
      
       
    },
    "@media (max-width: 480px)" : {
      width: '65vw',
      
      
      
       
  },
}
}));
