import React, { useState } from 'react'
import classes from './helper.module.css';
import { BsQuestionCircle } from "react-icons/bs";
import { Button, Modal } from '@material-ui/core';
import { Link } from 'react-router-dom';
const Helper = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <button className={classes.button} onClick={handleOpen}><BsQuestionCircle className={classes.Icon} /></button>
      <Modal open={open} onClose={handleClose}>
        <div className={classes.paper}>
          <div className={classes.placeButtons}>
            <Link to='/usingapp'>
              <button className={classes.buttons1}>How do I use this app?</button>
            </Link>
            <Link to='/gettingstarted'>
              <button className={classes.buttons1}>Getting Started</button>
            </Link>
            <Link to='/upcomingevents'>
              <button className={classes.buttons1}>Upcoming events</button>
            </Link>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Helper
