import React from 'react'
import { Grid, Card, CardActionArea, CardMedia, CardContent, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import useStyles from "./Style"
export default function QuizT(props) {
    const classes = useStyles();

    const [quiz, setQuiz] = React.useState({})


    React.useEffect(() => {
        //console.log(props.formData)
        setQuiz(props.formData)
    }, [props])


    return (

        <Card className={classes.card}>
            {/* <CardActionArea href={"/form/" + form._id}> */}
            <CardMedia
                className={classes.media}
                image={quiz.quizImage ? quiz.quizImage : "https://img.freepik.com/premium-vector/online-exam-test-paper-time-icon-element-design-questionanswer-pencil-stopwatch_259594-210.jpg?w=740"}
                title="Contemplative Reptile"
            />
            <CardContent style={{ color: "#eeddee" }}>
                <Typography gutterBottom variant="h4" component="h2">
                    {quiz.quizName}
                </Typography>
                <Typography variant="body1" component="p">
                    {quiz.description}
                </Typography>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '7.5vh'}}>
                    <Typography variant="body2">No.of Questions: {quiz['questions']?.length}</Typography>
                    <Typography variant="body2">Duration: {quiz?.duration?.minutes} minutes</Typography>
                </div>

            </CardContent>
            {/* </CardActionArea> */}
            <div style={{ textAlign: "center", margin: "auto" }}>

                <Link to={"/templates/" + quiz._id} style={{ textDecoration: "none" }}><Button className={classes.fabButton} >Challenge details</Button></Link>
            </div>

        </Card>

    )
}
