import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import { TextField, Button, Typography, Paper, Container, Tooltip, IconButton, Grid, Box } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import * as BsIcons from "react-icons/bs";
import { createPost, updatePost } from "../../actions/posts";
import { uploadImage } from "../../APIServices/Image";
import { uploadVideo } from "../../APIServices/Image";
import LoadingButton from '@mui/lab/LoadingButton';
import VideoComp from "./VideoComp";
import ImageComp from "./ImageComp";

import * as api from '../../api';
const Form = ({ currentId, setCurrentId }) => {
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [tags, setTags] = useState([]);
    const [images, setImage] = useState([]);
    const [video, setVideo] = useState([]);
    const [name, setName] = useState("");
    const [avatarUrl, setAvatarUrl] = useState("");
    const [loading, setLoading] = useState(false)
    const [postData, setPostData] = useState()
    const post = useSelector((state) => currentId ? state.posts.posts.find((p) => p._id === currentId) : null);
    const user = JSON.parse(localStorage.getItem('profile'));
    const [thumbnail, setThumbnail] = useState("")
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        if (post) setPostData(post);
    }, [post])

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("title", title);
        formData.append("message", message);
        tags.forEach(tag => { formData.append("tags", tag) });
        formData.append("name", name);
        formData.append("thumbnail", thumbnail)
        images.forEach(img => { formData.append("imageUrl", img) });
        video.forEach(vid => { formData.append("videoUrl", vid) })
        formData.append("avatarUrl", avatarUrl);
        formData.append("creator", user.result._id || user.result.googleId)

        dispatch(createPost(formData, navigate));
    }

    console.log(thumbnail)
    const handleFilechange = async (e) => {
        setLoading(true)
        const formData = new FormData()
        formData.append('image', e.target.files[0])
        await uploadImage(formData)
            .then((data) => {
                var imageLink = data.data;
                var temp = [...images]
                temp.push(imageLink)
                setImage(temp)
                setLoading(false)
            },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );



        setName(user?.result?.name);
        setAvatarUrl(user?.result?.imageUrl);


    }
    const handleFilechange1 = async (e) => {
        setLoading(true)
        const formData = new FormData()
        formData.append("file", e.target.files[0])
        await uploadVideo(formData)
            .then((data) => {
                var videoLink = data.data;
                var temp = [...video]
                temp.push(videoLink)
                setVideo(temp)
                setLoading(false)

            })
        setName(user?.result?.name);
        setAvatarUrl(user?.result?.imageUrl);

    }
    const handleThumbnail = async (e) => {
        setLoading(true)
        const formData = new FormData()
        formData.append('image', e.target.files[0])
        await uploadImage(formData)
            .then((data) => {
                setThumbnail(data.data)
                setLoading(false)
            },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
    }


    const clear = () => {

        setTitle("");
        setMessage("");
        setTags("");
        setImage([]);
        setVideo([]);
    }
    const removeImage = (i) => {
        var temp = [...images]
        temp.splice(i, 1)
        setImage(temp)
    }
    const removeVideo = (i) => {
        var temp = [...video]
        temp.splice(i, 1)
        setVideo(temp)
    }
    if (!user?.result?.name) {
        return (
            <Paper className={classes.paper}>
                <Typography variant='h5' align='center'>
                    Please Login to post, upvote/downvote, comment on other user's posts!
                </Typography>
            </Paper>
        );
    }


    return (
        <>

            <div className={classes.paper}>
                <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`} onSubmit={handleSubmit}>
                    <p className={classes.formTitle} >{currentId ? 'Edit' : 'Create'} Post</p>

                    <input className={classes.Field} placeholder="Title" label="Title" onChange={(e) => setTitle(e.target.value)} />
                    <input className={classes.Field} placeholder="Post Body" label="Post Body" onChange={(e) => setMessage(e.target.value)} />
                    <input className={classes.Field} placeholder="Skills (Use commas without spaces)" onChange={(e) => setTags(e.target.value.split(','))} />
                    <p className={classes.choice}>Upload an Image for thumbnail</p>
                    <div className={classes.fileInput}>
                        <label htmlFor="thumbnail">
                            <BsIcons.BsFillCameraFill style={{ color: '#25b8ef', cursor: 'pointer' }} size={30} />
                            <input type='file' onChange={handleThumbnail} accept="image/*" id="thumbnail" multiple hidden />
                        </label>
                    </div>
                    {thumbnail !== "" && (
                        <>
                            <div style={{ width: "100%", display: "flex", justifyContent: "center", position: "relative" }}>
                                <img src={thumbnail} width="70%" height="auto" alt="questionImage" />
                                <Tooltip title="Remove Image">

                                    <IconButton style={{
                                        left: '10%',
                                        position: "absolute", top: '0px', zIndex: 999, color: 'var(--opp)'
                                    }}
                                        size="small"
                                        onClick={() => {
                                            setThumbnail("")
                                        }}>
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>

                            </div>
                        </>)}
                    <br></br>
                    &nbsp;
                    <div className={classes.fileInput}>
                        <label htmlFor="image">
                            <BsIcons.BsFillCameraFill style={{ color: '#25b8ef', cursor: 'pointer' }} size={30} />
                            <input type='file' onChange={handleFilechange} name="file" accept="image/*" id="image" multiple hidden />
                        </label>
                        <p className={classes.Or}>Or</p>
                        <label htmlFor="video">
                            <BsIcons.BsFillCameraVideoFill style={{ color: '#25b8ef', cursor: 'pointer' }} size={30} />
                            <input type='file' onChange={handleFilechange1} name="file" accept="video/*" id="video" hidden />
                            <p style={{ fontSize: '10px', color: '#c935ff' }}>{video?.name}</p>
                        </label>
                    </div>


                </form>
                {images.length !== 0 || video.length !== 0 ? <div style={{ minHeight: "300px", width: "100%", overflow: "scroll" }}>
                    <ImageComp style={{}} images={images} removeImage={removeImage} />
                    <VideoComp video={video} removeVideo={removeVideo} />
                </div> : <></>}

                <div className={classes.buttonsBelow}>
                    <Box>
                        <LoadingButton
                            className={classes.ButtonS}
                            onClick={handleSubmit}
                            loading={loading}
                            variant="contained"
                            loadingPosition="start"
                        >
                            Submit
                        </LoadingButton>
                    </Box>
                    <button className={classes.ButtonC} onClick={clear} >Clear</button>
                </div>

            </div>
        </>
    );
}

export default Form;