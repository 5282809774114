import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import { TextField, Button, Typography, Paper, Container } from "@material-ui/core";
import FileBase from 'react-file-base64';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { BsFillCameraVideoFill, BsFillCameraFill } from "react-icons/bs";
import { createQuestion, updateQuestion } from "../../actions/questions";

const FormQ = ({ currentId, setCurrentId }) => {
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [tags, setTags] = useState([]);
    const [image, setImage] = useState(null);
    const [video, setVideo] = useState(null);
    const [name, setName] = useState("");
    const [avatarUrl, setAvatarUrl] = useState("");
    const [questionData, setQuestionData] = useState()
    const question = useSelector((state) => currentId ? state.questions.questions.find((p) => p._id === currentId) : null);
    const user = JSON.parse(localStorage.getItem('profile'));
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        if (question) setQuestionData(question);
    }, [question])
    const handleSubmit = (e) => {

        e.preventDefault();
        const formData = new FormData();
        formData.append("title", title);
        formData.append("message", message);
        tags.forEach(tag => { formData.append("tags", tag) });
        formData.append("name", name);
        formData.append("image", image);
        formData.append("video", video);
        formData.append("avatarUrl", avatarUrl);

        dispatch(createQuestion(formData, navigate));
    }
    const handleFilechange = async (e) => {
        setImage(e.target.files[0]);
        setName(user?.result?.name);
        setAvatarUrl(user?.result?.imageUrl);

    }
    const handleFilechange1 = async (e) => {

        setVideo(e.target.files[0]);
        setName(user?.result?.name);
        setAvatarUrl(user?.result?.imageUrl);

    }
    const clear = () => {

        setTitle("");
        setMessage("");
        setTags("");
        setImage(null);
        setVideo(null);

    }
    if (!user?.result?.name) {
        return (
            <Paper className={classes.paper}>
                <Typography variant='h5' align='center'>
                    Please Login to post a question, upvote/downvote, comment on other user's questions!
                </Typography>
            </Paper>
        );
    }
    return (
        <div className={classes.paper}>
            <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`} onSubmit={handleSubmit}>
                <p className={classes.formTitle} >{currentId ? 'Edit' : 'Create'} Question</p>

                <input className={classes.Field} placeholder="Title" label="Title" onChange={(e) => setTitle(e.target.value)} />
                <input className={classes.Field} placeholder="Post Body" label="Post Body" onChange={(e) => setMessage(e.target.value)} />
                <input className={classes.Field} placeholder="Skills(Use commas without spaces)" onChange={(e) => setTags(e.target.value.split(','))} />

                <p className={classes.choice}>Upload an Image or a Video (max size: image(5MB) video(100MB))</p>
                <div className={classes.fileInput}>

                    <label htmlFor="image">
                        <BsFillCameraFill style={{ color: '#25b8ef', cursor: 'pointer' }} size={30} />
                        <input type='file' onChange={handleFilechange} name="file" accept="image/*" id="image" multiple hidden />
                        <p style={{ fontSize: '10px', color: '#c935ff' }}>{image?.name}</p>
                    </label>
                    <p className={classes.Or}>Or</p>
                    <label htmlFor="video">
                        <BsFillCameraVideoFill style={{ color: '#25b8ef', cursor: 'pointer' }} size={30} />
                        <input type='file' onChange={handleFilechange1} name="file" accept="video/*" id="video" hidden />
                        <p style={{ fontSize: '10px', color: '#c935ff' }}>{video?.name}</p>
                    </label>


                </div>


            </form>
            <div className={classes.buttonsBelow}>
                <button className={classes.ButtonS} onClick={handleSubmit} >Submit</button>
                <button className={classes.ButtonC} onClick={clear} >Clear</button>
            </div>

        </div>
    );
}

export default FormQ;