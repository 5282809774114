import React, { useState } from 'react';
import Cookies from "universal-cookie";
import axios from 'axios';
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { Typography, Paper } from '@material-ui/core';
import Spline from '@splinetool/react-spline';
import styled from 'styled-components';
import classes from "./auth.module.css"
import { BsFillCameraFill } from "react-icons/bs";
import { FcGoogle } from 'react-icons/fc';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signInRecruiter, signUpRecruiter } from '../../actions/recruiter';
import { createRecruiter } from '../../APIServices/RecruiterAPI';

const Container = styled.div`
  
  display: flex;
  
 justify-content:center;
  ${'' /* margin-left: 36vw; */}
  
  
  @media only screen and (max-width: 1200px) {
    margin: 15px;
  padding: 60px;
  }
  @media only screen and (max-width: 900px) {
    margin: 12px;
  padding: 50px;
  }
  @media only screen and (max-width: 700px) {
    margin: 8px;
  padding: 25px;
  }
  @media only screen and (max-width: 450px) {
    margin: 10px;
  
  }
  @media only screen and (max-width: 350px) {
    margin: 10px;
  
  }
`;

const Fields = styled.div`
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  padding: 0rem;
  background: #1a1a1a;
  border-radius: 30px;

  @media only screen and (max-width: 550px) {
   width: 90vw;

    
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
    
  padding: 10%;
  
  border-radius: 30px;
  transition: 0.8s ease;
  box-shadow: -4px -4px 15px 4px rgba(255, 255, 255, 0.20), 4px 4px 15px 4px rgba(0, 0, 0, 0.65);
  background: #1a1a1a;

`;

const Para = styled.p` 
  font-size: 35px;
  text-align: center;
  color: #25b8ef;
  margin: 0;
  font-weight: 600;
  padding: 1rem 0rem;

`;

const Input = styled.div`
    display: flex;
    flex-direction: column;
    
  
    padding-block: 1rem;

`;
const Label = styled.label`
    margin-bottom: 0.45rem;
    
    font-size: 13px;
    
    letter-spacing: 0.9px;
    line-height: 1.3;
    color: rgb(37, 184, 239);
`;

const Inputi = styled.input`
    padding: 1rem;
    border: 2px solid rgb(37, 184, 239);
    border-radius: 5px;
    font-size: 16px;
    color: rgb(37, 184, 239);
    box-shadow: -1.5px -1.5px 6px 1.5px rgba(255, 255, 255, 0.20),  2px 2px 8px 2px rgba(0, 0, 0, 0.75);
    outline: none;
    transition: all 150ms ease-in-out 0.3s;
    width: 100%;
    background: #1a1a1a;
    @media only screen and (max-width: 650px) {
      padding: .7rem;
    }
`;
const Account = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  
    margin-top: 0.2rem;
`;
const Span = styled.span` {
    color: #25b8ef;
    cursor: pointer;
    font-weight: 550;
    }
`;
const Image = styled.div` {
    
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin:auto;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 30vh;
    height: 30vh;
    margin-top: 0px;
    @media only screen and (max-width: 650px) {
      display: column-reverse;
    }
    @media only screen and (max-width: 450px) {
      padding: 0px;
      margin-top: -35px;
      margin-left: 30px;
    }
}    
`;
const SignIn = styled.button`
    border-radius: 5px;
    background: #22262b;
    width: 100%;
    padding: .7rem;
    border: 0px solid #25b8ef;
    color: #25b8ef;
    box-shadow: -2px -2px 6px 2px rgba(255, 255, 255, 0.25), 2px 2px 6px 2px rgba(0, 0, 0, 0.75);
    font-weight: 500;
    font-size: 20px;
    outline: none;
    cursor: pointer;
    transition: 0.3s ease;
    :hover{
    background: #22262b;
    border: 0px solid #25b8ef;
    color: #25b8ef;
    box-shadow: 2px 2px 6px 2px rgba(255, 255, 255, 0.25), -2px -2px 6px 2px rgba(0, 0, 0, 0.75);
    font-weight: 500;
    outline: none;
    cursor: pointer;
    transition: 0.3s ease;
    }

    @media only screen and (max-width: 500px) {
      padding: 0.5rem;
      font-size: 16px;
      border: 0px solid #1167bf;
      border-radius: 5px;
      box-shadow: -1.5px -1.5px 4.5px 1.5px rgba(255, 255, 255, 0.25), 1.5px 1.5px 4.5px 1.5px rgba(0, 0, 0, 0.75);
      :hover{
        border: 0px solid #1167bf;
        box-shadow: 1.5px 1.5px 4.5px 1.5px rgba(255, 255, 255, 0.25), -1.5px -1.5px 4.5px 1.5px rgba(0, 0, 0, 0.75);
      }
    }  
   
`;
const SignIng = styled.button`
    border-radius: 5px;
    width: 100%;
    background: #22262b;
    border: 0px solid #c935ff;
    box-shadow: -2px -2px 6px 2px rgba(255, 255, 255, 0.20), 2px 2px 6px 2px rgba(0, 0, 0, 0.55);
    
    color: #c935ff;
    
    font-weight: 500;
    font-size: 20px;
    font-color: #000000;
    padding: 0.7rem;
    outline: none;
    cursor: pointer;
    transition: 0.3s ease;
    :hover {
    background: #22262b;
    border: 0px solid #c935ff;
    box-shadow: 2px 2px 6px 2px rgba(255, 255, 255, 0.20), -2px -2px 6px 2px rgba(0, 0, 0, 0.55);
    
    color: #c935ff;
    
    font-weight: 500;
    font-size: 20px;
    font-color: #000000;
    outline: none;
    cursor: pointer;
    transition: 0.3s ease;
    }
    @media only screen and (max-width: 500px) {
      padding: 0.5rem;
      font-size: 16px;
      border: 0px solid #1167bf;
      border-radius: 5px;
      box-shadow: -1.5px -1.5px 4.5px 1.5px rgba(255, 255, 255, 0.25), 1.5px 1.5px 4.5px 1.5px rgba(0, 0, 0, 0.75);
      :hover{
        border: 0px solid #1167bf;
        
        box-shadow: 1.5px 1.5px 4.5px 1.5px rgba(255, 255, 255, 0.25), -1.5px -1.5px 4.5px 1.5px rgba(0, 0, 0, 0.75);
      }
    }  

`;

const initialState = {
  fullName: '',
  username: '',
  phoneNumber: '',
  emailId: '',
  password: '',
  confirmPassword: '',
  image: null,
}


const AuthRecruiter = () => {
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [image, setImage] = useState(null);
  const [isSignup, setIsSignup] = useState(false);
  const [quiz, setQuiz] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();



  const handleSubmit = (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append("name", name);
    form.append("username", username);
    form.append("phoneNumber", phoneNumber);
    form.append("emailId", emailId);
    form.append("password", password);
    form.append("confirmPassword", confirmPassword);
    form.append("image", image);

    if (isSignup) {
      dispatch(signUpRecruiter(form, navigate))
    } else {
      dispatch(signInRecruiter(form, navigate))
    }
  }
  const switchMode = () => {
    setIsSignup((prevIsSignup) => !prevIsSignup)
  }
  const googleSuccess = async (res) => {

    // console.log(result)
    const token = res?.credential;
    const decoded = jwt_decode(token);
    const result = {
      email: decoded?.email,
      givenName: decoded.given_name,
      googleId: decoded.sub,
      imageUrl: decoded?.picture,
      name: decoded?.name
    }

    let obj;
    await createRecruiter(result)
      .then((res) => {
        console.log("res")
        obj = res;
        console.log(obj);
        //setQuiz(obj);
        //console.log(quiz);
      }, (err) => {
        console.log(err);
      })
    try {
      dispatch({ type: 'AUTH', data: { result, token, obj } });
      navigate('/recruiterdashboard');

    } catch (error) {
      console.log(error);
    }

  };

  const googleFailure = (error) => {
    console.log(error);
    console.log("Google Sign In was unsuccessful");
  }
  return (



    <div className={classes.container}>
      <div className={classes.logo}>
        <Spline scene="https://prod.spline.design/S9EsPGrVWiDU9gYJ/scene.splinecode" />

      </div>
      <div className={classes.content}>
        <h2 className={classes.signIn}>{isSignup ? 'Sign Up' : 'Sign In'}</h2>

        <form onSubmit={handleSubmit}>
          {isSignup && (
            <div>
              <label htmlFor="name" >Full Name </label>
              <input
                name="name"
                type="text"
                placeholder='Full Name'
                inputColor='white'
                onChange={(e) => setName(e.target.value)}
                required
              />

            </div>
          )}


          <div>
            <label htmlFor="username">Username </label>
            <input
              name="username"
              type="text"
              placeholder='Username'
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>

          {isSignup && (
            <div>
              <label htmlFor="phoneNumber">Phone Number (10 digits) </label>

              <input
                name="phoneNumber"
                type="text"
                placeholder='Phone Number'
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            </div>

          )}

          {isSignup && (
            <div>
              <label htmlFor="emailId">Email Id </label>
              <input
                name="emailId"
                type="text"
                placeholder='Email Id'
                onChange={(e) => setEmailId(e.target.value)}
                required
              />
            </div>
          )}

          <div>
            <label htmlFor="password">Password </label>
            <input
              name="password"
              type="password"
              placeholder='Password'
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>

          {isSignup && (
            <div>
              <label htmlFor="confirmPassword">Confirm Password </label>
              <input
                name="confirmPassword"
                type="password"
                placeholder='Confirm Password'
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
          )}


          {isSignup && (

            <div>
              <label>Upload Profile Picture</label>

              <div>
                <label htmlFor="image">
                  <BsFillCameraFill style={{ color: '#25b8ef', cursor: 'pointer', marginLeft: '0px', marginTop: '10px' }} size={30} />
                  <input type='file' onChange={(e) => setImage(e.target.files[0])} name="file" accept="image/*" id="image" hidden />
                  <p>{image?.name}</p>
                </label>
              </div>
            </div>
          )}

          <div style={{ display: 'flex', flexDirection: 'column', gap: "1.5rem" }} className={classes.buttonContainer}>
            <button className={classes.signInButton}>{isSignup ? "Sign Up" : "Sign In"}</button>

            <GoogleLogin
              clientId="22206794554-165ahregurdak2b5p4oqgirg4no38ip7.apps.googleusercontent.com"
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className={classes.googleSignIn}
                  variant="contained"
                >
                  <FcGoogle fontSize="large" />
                  &nbsp;
                  {isSignup ? "Google Sign Up" : "Google Sign In"}
                </button>
              )}
              onSuccess={googleSuccess}
              onFailure={googleFailure}
              cookiePolicy="single_host_origin"


            />
          </div>
        </form>
        <div>
          <p style={{ color: '#eeedee' }}>
            {isSignup
              ? "Already have an account?"
              : "Don't have an account?"
            }
            <Span style={{ marginLeft: "5px" }} onClick={switchMode}>
              {isSignup ? 'Sign In' : 'Sign Up'}
            </Span>
          </p>

        </div>


      </div>


    </div>


  )
}

export default AuthRecruiter;