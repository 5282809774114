import React, { useState, useEffect } from "react";
import { Container, AppBar, Typography, Grow, Grid, Paper, TextField, Button } from '@material-ui/core';
import { useNavigate, useLocation } from "react-router-dom";
import ChipInput from 'material-ui-chip-input';
import { useDispatch } from "react-redux";
import { getQuestions, getQuestionsBySearch } from '../../actions/questions';
import { Marginer } from "../Auth/Marginer";
import Questions from '../Questions/Questions';
import Header from "../../Header";
import FormQ from '../Form/FormQ';
import "../../App.css";
import classes from "./question.module.css"

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const QuestionFeed = () => {
  const [currentId, setCurrentId] = useState(null);
  const query = useQuery();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const searchQuery = query.get('searchQuery');
  useEffect(() => {
    dispatch(getQuestions());
  }, [currentId, dispatch]);

  const [search, setSearch] = useState('');
  const [tags, setTags] = useState([]);
  const searchQuestion = () => {
    if (search.trim() || tags) {
      dispatch(getQuestionsBySearch({ search, tags: tags.join(',') }));
      navigate(`/questions/search?searchQuery=${search || 'none'}&tags=${tags.join(',')}`);
    } else {
      navigate('/questions');
    }
  };
  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      searchQuestion();
    }
  };
  const handleAdd = (tag) => setTags([...tags, tag]);
  const handleDelete = (tagToDelete) => setTags(tags.filter((tag) => tag !== tagToDelete));


  return (
    <>


      <div className={classes.container} >

        <div className={classes.card}>
          <input className={classes.input} name='search' onKeyPress={handleKeyPress} placeholder='Search Posts' value={search} onChange={(e) => setSearch(e.target.value)} />
          <ChipInput className={classes.input} value={tags} onAdd={handleAdd} onDelete={handleDelete} placeholder="Search Using Skills(press enter)" />
          <button onClick={searchQuestion} className={classes.button}> Search</button>

        </div>

        <Questions setCurrentId={setCurrentId} />


      </div>



    </>
  );
};

export default QuestionFeed;