import React, { useEffect, useState  } from 'react'
import { create } from '../../APIServices/QuestionAPI';
import { useNavigate } from 'react-router-dom';
import QuizDetail from "./quizCountl.js"
import { useReactToPrint } from "react-to-print";
//import "./RecruiterDashboard.js"
import { getResponseByQuizId  } from "../../APIServices/ResponseAPI"
import {createRecruiter} from "../../APIServices/RecruiterAPI"
import { getSpecificUserProfile, getUserProfile } from '../../actions/profile';
import SideBar from "../sidebarRecruiter/Sidebar.js"
import Dashboard from "./Dashboard.js"
function RecruiterDashboard() {
  const [quizNo , setQuizNo] = useState([])
  
  const [user, setUser] = React.useState(JSON.parse(localStorage.getItem("profile")).obj ? JSON.parse(localStorage.getItem("profile")).obj : JSON.parse(localStorage.getItem("profile")).result)
  const navigate = useNavigate();
  useEffect(() => {
    console.log("res");
    setUser(JSON.parse(localStorage.getItem("profile")).obj ? JSON.parse(localStorage.getItem("profile")).obj : JSON.parse(localStorage.getItem("profile")).result)
    console.log(user.data);
    //console.log("ss")
    setQuizNo(user.data.quizs);
   
}, [user._id])

  return (
    <div>
    <SideBar/>
    <Dashboard/>
      {
        quizNo.map(quizNumber =>{
          return <QuizDetail key ={quizNumber} quizNumber = {quizNumber}/>
        })
      }

    </div>
  )
}

export default RecruiterDashboard