import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import Header from '../Header/Header';
import { FaWhatsapp } from "react-icons/fa";
import community from "../../Assets/Community.png"
import challenges from "../../Assets/Challenges.png"
import knowYourself from "../../Assets/Get to know yourself.png"
import useStyles from './styles';

function EntryPage() {
  const textRef = useRef();
  const testRef = useRef();
  const classes = useStyles();
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        entry.target.classList.add("slideinright");
      }
    });
    observer.observe(textRef.current);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        entry.target.classList.add("slideinleft");
      }
    });
    observer.observe(testRef.current);
  }, []);


  return (
    <div className="flex w-full max-w-[1300px] justify-center items-center flex-col">
      <Header />
      <div className="flex py-10 px-10 md:px-20 md:flex-row flex-col justify-center md:justify-around items-center overflow-x-hidden w-full  min-h-screen">
        <div
          className=" w-full md:w-[50%] flex min-h-52 justify-evenly slideinleft"
          style={{ "--time": 1 }}
        >
          <img src={community} alt="community" className="w-full" />
        </div>

        <div className="w-full md:w-[45%] my-10 slideinright">
          <h1 className="text-gray-300 sm:text-5xl text-3xl font-bold">
            Create A <span className="text-gradient">Stunning</span> Portfolio
          </h1>
          <p className="text-gray-500 text-xl mt-2">
            Showcase your skills to the world. Post your projects and articles
            that make you stand out. Create a lively professional history! Join
            the community of like minded individuals to give you feedback and
            answer any questions!
          </p>
          <Link to="/auth">
            <button className="h-16 w-48 bt-gradient my-5 rounded-md text-2xl text-gray-300 font-semibold flex justify-center items-center">
              Start Now!
            </button>
          </Link>
        </div>
      </div>
      <div className="flex py-10 px-10 md:px-20 md:flex-row flex-col justify-center md:justify-around items-center overflow-x-hidden w-full  min-h-screen">
        <div
          className=" w-full md:w-[50%] flex min-h-52 justify-evenly slideinleft"
          style={{ "--time": 1 }}
          ref={testRef}
        >
          <img src={knowYourself} alt="community" className="w-full" />
        </div>
        <div className="w-full md:w-[45%] my-10 slideinright" ref={textRef}>
          <h1 className="text-gray-300 sm:text-5xl text-3xl font-bold">
            Know Yourself Better!
          </h1>
          <p className="text-gray-500 text-xl mt-2">
            Take world class reputed psychometric tests. Choose your career path
            accordingly!
          </p>
          <Link to="/auth">
            <button className="h-16 w-48 bt-gradient my-5 rounded-md text-2xl text-gray-300 font-semibold flex justify-center items-center">
              Take Tests
            </button>
          </Link>
        </div>
      </div>
      <div className="flex py-10 px-10 md:px-20 md:flex-row flex-col justify-center md:justify-around items-center overflow-x-hidden w-full  min-h-screen">
        <div
          className=" w-full md:w-[50%] flex min-h-52 justify-evenly slideinleft"
          style={{ "--time": 1 }}
          ref={testRef}
        >
          <img src={challenges} alt="community" className="w-full" />
        </div>

        <div className="w-full md:w-[45%] my-10 slideinright" ref={textRef}>
          <h1 className="text-gray-300 sm:text-5xl text-3xl font-bold">
            Conquer Kingdoms To Get Noticed!
          </h1>
          <p className="text-gray-500 text-xl mt-2">
            Game like challenges to make your journey scintilating! Earn KP's,
            badges and rewards to get noticed easily and secure a job!
          </p>
          <Link to="/auth">
            <button className="h-16 w-48 bt-gradient my-5 rounded-md text-2xl text-gray-300 font-semibold flex justify-center items-center">
              Challenges
            </button>
          </Link>
        </div>
      </div>
      <a href='https://wa.me/919632247617'>
      <button className={classes.whatsapp}>
        <FaWhatsapp  size={40}/>
      </button>
      </a>
    </div>
  );
}

export default EntryPage
