import React, { useState, useEffect } from "react";
import classes from "./question.module.css";
import { Grid, CircularProgress, Paper, Typography } from "@material-ui/core";
import Question from './Question/Question';
import { useSelector } from "react-redux";
import { Marginer } from "../Auth/Marginer";
import Formbar from "../FormBar/FormButton";
import InfiniteScroller from "react-infinite-scroll-component";
import { getQuestionByPage } from "../../APIServices/Loading";

const Questions = ({ setCurrentId, show = true }) => {
    const [questions, setQuestions] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [currLength, setCurrentLength] = useState();

    useEffect(() => {
        getQuestionByPage({ page: page })
            .then((result) => {
                setQuestions([...questions, ...result.data])
                if (currLength === [...questions, ...result.data].length) {
                    setHasMore(false)
                } else {
                    setCurrentLength([...questions, ...result.data].length)
                }
                setLoading(false)
            }, error => {
                console.log(error)
            })
    }, [page])

    if (!questions.length && !isLoading && show) return (
        <div style={{ marginLeft: '130px' }}>
            <Formbar />
            <Paper className={classes.paper} style={{ marginLeft: '50px' }}>
                <Typography variant='h5' align='center'>
                    Please use the Button above to create a Question!!
                </Typography>
            </Paper>
        </div>
    );

    const handlePageLoad = () => {
        setTimeout(() => {
            setPage(page + 1)
        }, 300)
    }

    return (
        isLoading ? <CircularProgress /> : (

            <div className={classes.container}>


                {show ? (<Formbar />) : ""}
                <div className={classes.grid}>
                    {questions.map((question) => (
                        <Question question={question} setCurrentId={setCurrentId} />
                    ))}
                </div>
                <div className="App" style={{ textAlign: "center", margin: "auto", alignContent: "center" }}>
                    <InfiniteScroller dataLength={questions?.length} next={handlePageLoad} hasMore={hasMore} endMessage={<h5 style={{ color: "white" }}>End of the line!</h5>} loader={<h5 style={{ color: "white" }}>Loading....</h5>} />
                </div>
            </div>

        )
    );

}

export default Questions;