export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const COMMENT = 'COMMENT';
export const DELETE = 'DELETE';
export const FETCH_ALL = 'FETCH_ALL';
export const FETCH_BY_SEARCH = 'FETCH_BY_SEARCH';
export const FETCH_USER_POSTS = 'FETCH_USER_POSTS';
export const FETCH_SPECIFIC_USER_POSTS = 'FETCH_SPECIFIC_USER_POSTS';
export const FETCH_POST = 'FETCH_POST';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
export const FETCH_SPECIFIC_USER_PROFILE = 'FETCH_SPECIFIC_USER_PROFILE';
export const FETCH_QUESTION = 'FETCH_QUESTION';
export const FETCH_USER_QUESTIONS = 'FETCH_USER_QUESTIONS';
export const FETCH_SPECIFIC_USER_QUESTIONS = 'FETCH_SPECIFIC_USER_QUESTIONS';
export const FETCH_CONTEST = 'FETCH_CONTEST';
export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';
export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';
export const FETCH_TASK = 'FETCH_TASK';