import React from 'react';
import classes from "./usingApp.module.css"
import started1 from '../../Assets/Getting started/Posts/Getting started 1.png';
import started2 from '../../Assets/Getting started/Posts/getting started 2.png';
import started3 from '../../Assets/Getting started/Posts/Getting started 3.png';
import started4 from '../../Assets/Getting started/Posts/Getting started 4.png';
import started5 from '../../Assets/Getting started/Posts/Getting started 5.png';
import started6 from '../../Assets/Getting started/Posts/Getting started 6.png';
import startedQ1 from '../../Assets/Getting started/Posts/q1.png';
import startedQ2 from '../../Assets/Getting started/Posts/q2.png';
import startedQ3 from '../../Assets/Getting started/Posts/q3.png';
import startedQ4 from '../../Assets/Getting started/Posts/q4.png';
import video1 from '../../Assets/Getting started/Posts/Post Upload.mp4';
import video2 from '../../Assets/Getting started/Posts/QuestionUpload.mp4';


function UsingTheApp() {

  return (
    <div className={classes.container}>
      <div className={classes.card}>
        <h2 className={classes.h2}>How do I use the app?</h2>
        <h4 className={classes.h4}>How do I post my projects or an article?</h4>
        <p className={classes.p}>1) Click on the button Post Something in the center of the screen when you log in.</p>
        <img src={started1} className={classes.image} />
        <p className={classes.p}>2) Type in the Title of your post.</p>
        <img src={started2} className={classes.image} />
        <p className={classes.p}>3) Type in the details of your post.</p>
        <img src={started3} className={classes.image} />
        <p className={classes.p}>4) Type in the skills pertaining to the post. Remember to use commas and no spaces. The hastags will automatically populate once you submit the post.</p>
        <img src={started4} className={classes.image2} />
        <p className={classes.p}>5) You will have to select an Image or a Video to upload with your post.(blank posts wont deploy)</p>
        <img src={started5} className={classes.image3} />
        <p className={classes.p}>6) Click on submit for the post to be uploaded.</p>
        <img src={started6} className={classes.image3} />
        <p className={classes.p}>Here is a video to make things easier</p>
        <video src={video1} controls className={classes.video} />
        <h4 className={classes.h4}>How do I post my questions?</h4>
        <p className={classes.p}>1) Click on the button Post a question in the Question Feed</p>
        <img src={startedQ1} className={classes.image} />
        <p className={classes.p}>2) Type in the Title of your question.</p>
        <img src={startedQ2} className={classes.image} />
        <p className={classes.p}>3) Type in the details of your question.</p>
        <img src={startedQ3} className={classes.image} />
        <p className={classes.p}>4) Type in the skills pertaining to the question. Remember to use commas and no spaces. The hastags will automatically populate once you submit the question.</p>
        <img src={startedQ4} className={classes.image2} />
        <p className={classes.p}>5) You will have to select an Image or a Video to upload with your question.(blank questions wont deploy)</p>
        <img src={started5} className={classes.image3} />
        <p className={classes.p}>6) Click on submit for the question to be uploaded.</p>
        <img src={started6} className={classes.image3} />
        <p className={classes.p}>Here is a video to make things easier</p>
        <video src={video2} controls className={classes.video} />
      </div>
    </div>
  )
}

export default UsingTheApp