import React, {useState, useEffect} from 'react'

import { makeStyles } from '@material-ui/core/styles';

import { getResponseByQuizId } from '../../APIServices/ResponseAPI';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Paper, Container, Button } from '@material-ui/core';
import Form from 'react-bootstrap/Form';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Grid } from '@material-ui/core';
import { getResponseByUserName } from '../../APIServices/ResponseAPI';
import { getResponseByPage } from '../../APIServices/Loading';
import InfiniteScroller from "react-infinite-scroll-component";




const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});



const Responses = (props) => {
    const classes = useStyles();
    const [quizDetails, setQuizDetails] = React.useState({});
    const [responseData, setResponseData] = React.useState([]);
    const [questions, setQuestions] = React.useState([]);
    const [expanded, setExpanded] = React.useState('');
    const [isLoading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [currLength, setCurrentLength] = useState();
    const [searchValue, setSearchValue] = React.useState("");

    useEffect(() => {
        getResponseByPage({ page: page, quizId: props.formData._id })
            .then((result) => {
                setResponseData([...responseData, ...result.data])
                if (currLength === [...responseData, ...result.data].length) {
                    setHasMore(false)
                } else {
                    setCurrentLength([...responseData, ...result.data].length)
                }
                setLoading(false)
            }, error => {
                console.log(error)
            })
    }, [page])

    const search = (value) => {

        getResponseByUserName(value)
            .then((data) => {
                console.log(data);
                setResponseData(data.data)
            },

                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log(resMessage);
                }
            );

    }



    React.useEffect(() => {
        if (props.formData) {
            // console.log(props.formData.questions.length);
            setQuestions(props.formData.questions)

            setQuizDetails(props.formData)
        }
        // var quizId = props.quizId


        // if (quizId !== undefined && quizId !== "") {
        //    getResponseByQuizId(quizId)
        //        .then((data) => {
        //            setResponseData(data.data)
        //            setLoading(false)
        //            console.log(data)
        //        },
        //            error => {
        //                const resMessage =
        //                    (error.response &&
        //                        error.response.data &&
        //                        error.response.data.message) ||
        //                    error.message ||
        //                    error.toString();
        //                console.log(resMessage);
        //            }
        //        );
        //}
    }, [props.quizId, props.formData]);

    const handlePageLoad = () => {
        setTimeout(() => {
            setCurrentLength(responseData.length)
            setPage(page + 1)
        }, 300)
    }

    function getSelectedOption(qId, i, j) {
        var oneResData = responseData[j];
        //console.log(oneResData);

        var selectedOp = oneResData.response.filter(qss => qss.questionId === qId);

        if (selectedOp.length > 0) {

            var finalOption = selectedOp[0].option;
            return finalOption;
        } else {
            return "not attempted";
        }


        // return selectedOp[0].optionId;
        //this.students.filter(stud => stud.Class==className);
    }

    // function getOptionTextById(optionId, questionId, i){
    // var finalOption = questions[i].options.find(oo => oo._id === optionId);
    // return finalOption.optionText
    // }



    return (
        <>
            {isLoading ? (<CircularProgress />) : (
                <>
                    <Container className={classes.cardGrid} maxWidth="lg" style={{ marginTop: "30px" }}>
                        <Form className="d-flex">
                            <Form.Control
                                type="search"
                                placeholder="User Name"
                                className="me-2"
                                aria-label="Search"
                                value={searchValue}
                                width="10rem"
                                onChange={(e) => { setSearchValue(e.target.value) }}
                            />
                            <>


                                <Button variant="contained" color="primary" onClick={(e) => { search(searchValue) }}>
                                    Search
                                </Button>
                                &nbsp;
                                <Button variant="contained" color="secondary" href={"/myChallenges/" + props.quizId}>
                                    All
                                </Button>
                            </>
                        </Form>
                    </Container>
                    <div style={{ margin: "30px" }}>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item xs={10} sm={8} md={8} style={{ width: '100%' }}>

                                <Grid style={{ borderRadius: 10 }}>
                                    {
                                        responseData.map((res, i) => (
                                            <>
                                                <Paper elevation={2} style={{ width: '100%' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: '15px', paddingTop: '20px', paddingBottom: '20px' }}>

                                                        <Typography variant='h5' >
                                                            <b>{"Name : " + res.userName}    {" | Total Score : " + res.correctAnswers}</b>
                                                        </Typography>
                                                        <br></br>
                                                        <Typography sx={{ color: 'text.secondary' }}></Typography>
                                                        {questions?.map((ques, j) => (
                                                            <>
                                                                <Typography key={j} variant="h6">{"Question : " + ques.question}</Typography>
                                                                <Typography key={j} variant="h6">{"Answer : "}{getSelectedOption(ques._id, j, i)}</Typography>
                                                                <br></br>
                                                            </>

                                                        ))}
                                                    </div>
                                                </Paper>
                                                <br></br>
                                            </>

                                        ))
                                    }
                                </Grid>
                            </Grid>
                            <div className="App" style={{ textAlign: "center", margin: "auto", alignContent: "center" }}>
                                <InfiniteScroller dataLength={responseData?.length} next={handlePageLoad} hasMore={hasMore} endMessage={<h5 style={{ color: "black" }}>End of the line, buddy!</h5>} loader={<h5 style={{ color: "black" }}>Loading....</h5>} />
                            </div>
                        </Grid>
                    </div>

                </>
            )
            }


        </>


    )
};

export default Responses;