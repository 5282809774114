import React from 'react'
import { Grid, IconButton, Tooltip } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import useStyles from "./styles";

const VideoComp = (props) => {
    const classes = useStyles();

    return (
        <Grid className={classes.container1} container alignItems="stretch" spacing={8}>

            {
                props.video ? (
                    props.video?.map((img, i) => (
                        <Grid key={i} item xs={12} md={4} sm={4} >
                            <div key={i}>
                                <video src={img} autoPlay={false} controls={true} width="50px" />
                                <Tooltip title="Remove Video">

                                    <IconButton style={{ marginLeft: '-15px', marginTop: '-15px', zIndex: 999, color: 'grey' }}
                                        size="small"
                                        onClick={() => {
                                            props.removeVideo(i)
                                        }}>
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>

                            </div>
                        </Grid>
                    ))
                ) : ""

            }
        </Grid>
    )

}

export default VideoComp;