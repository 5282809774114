import React from "react";
import { FaTwitter, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";
import classes from "./footer.module.css"

const Footer = () => {
  return (
    <div className={`w-full p-10 mt-10 ${classes.footer}`}>
      <div className="flex lg:justify-around justify-start flex-wrap">
        <div className="flex flex-col space-between lg:w-[initial] overflow-x-hidden w-[50%]">
          <p className="text-xl text-[#25b8ef]">Policies</p>
          <Link
            to="/termsofservice"
            className="text-gray-300 mt-5"
          >
            Terms of Service
          </Link>
          <Link
            to="/privacypolicy"
            className="text-gray-300 mt-5"
          >
            Privacy Policy
          </Link>
          <Link to="/contactus" className="text-gray-300 mt-5">
            Contact Us
          </Link>
        </div>
        <div className="flex flex-col space-between lg:w-[initial] w-[50%]">
          <p className="text-xl text-[#25b8ef]">Useful Links</p>
          <Link
            to="/gettingstarted"
            className="text-gray-300 mt-5"
          >
            Getting Started with Usurp
          </Link>
          <Link to="/usingapp" className="text-gray-300 mt-5">
            How to use the platform
          </Link>
        </div>

        <div>
          <p className="text-xl lg:mt-0 mt-5 mb-4 text-[#25b8ef]">
            Social Media Accounts of Usurp...
          </p>
          <div className="flex lg:flex-col gap-6 flex-row">
            <label htmlFor="Twitter">
              <a href="https://twitter.com/usurptech">
                <FaTwitter
                  style={{ color: "#25b8ef", cursor: "pointer" }}
                  size={30}
                />
              </a>
            </label>
            <a href="https://www.instagram.com/_usurp/">
              <FaInstagram
                style={{ color: "#25b8ef", cursor: "pointer" }}
                size={30}
              />
            </a>
            <a href="https://www.linkedin.com/company/usurp-tech/">
              <FaLinkedinIn
                style={{ color: "#25b8ef", cursor: "pointer" }}
                size={30}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="text-gray-400 mt-10 text-center">
        <p>Made in India with &#128153; </p>
      </div>
      <div className="text-gray-400 mt-10 text-center">
        <p>&#169; Usurp. All rights reserved. </p>
      </div>
    </div>
  );
};

export default Footer;
