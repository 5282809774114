import React, { useState, useEffect, useRef } from 'react';
import useStyles from './styles';
import { useAnimation, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Logo from '../../Assets/Asset 2.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Header from '../Header/Header';
import FirstImg from '../../Assets/EntryPage/First image.png';
import SecondImg from '../../Assets/EntryPage/Second image.png';
import ThirdImg from '../../Assets/EntryPage/Third image.png';
import video from '../../Assets/Reduce hiring time.mp4';
import { FaWhatsapp } from "react-icons/fa";

import { Container, AppBar, Typography, Grow, Grid, Paper, TextField, Button } from '@material-ui/core';
const EntryPageForRecruiters = () => {
  const textRef = useRef();
  const testRef = useRef();
  const classes = useStyles();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        entry.target.classList.add("slideinright");
      }
    });
    observer.observe(textRef.current);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        entry.target.classList.add("slideinleft");
      }
    });
    observer.observe(testRef.current);
  }, []);


  return (
    <div className="flex w-full max-w-[1300px] justify-center items-center flex-col">
      <Header />
      <div className="flex py-10 px-10 md:px-20 md:flex-row flex-col justify-center md:justify-between items-center overflow-x-hidden w-full  min-h-screen">
        <div
          className=" w-full md:w-[50%] flex min-h-52 justify-evenly slideinleft"
          style={{ "--time": 1 }}
        >
          <img src={FirstImg} alt="" className="w-full" />
        </div>

        <div className="w-full md:w-[45%] my-10 slideinright">
          <h1 className="text-gray-300 sm:text-5xl text-3xl font-bold">
            Create challenges/tests to find better hires
          </h1>
          <p className="text-gray-500 text-xl mt-2">
            Make your hiring process 3X faster by making candidates take
            tests/challenges! Know who you're hiring!
          </p>
        </div>
      </div>

      <div className="flex w-full min-h-screen justify-center md:justify-around items-center">
        <div className="w-full flex flex-col lg:flex-row md:px-20 px-10 lg:justify-between">
          <div
            className="w-full md:w-[50%] flex lg:flex-row flex-col gap-5 md:gap-0  justify-between"
            ref={testRef}
          >
            <img src={SecondImg} alt="" className="w-full" />
          </div>
          <div className="w-full md:w-[45%]" ref={textRef}>
            <h3 className="sm:text-5xl text-3xl font-bold text-gray-300">
              Make use of the test templates to make your life easier
            </h3>
            <p className="text-xl text-gray-500 w-full mt-2">
              We have multiple existing tests to make your life easier. Just
              select them and share the link with the candidates.
            </p>
          </div>
        </div>
      </div>
      <div className="flex w-full min-h-screen justify-center md:justify-around items-center">
        <div className="w-full flex flex-col lg:flex-row-reverse md:px-20 px-10 lg:justify-between">
          <div
            className="w-full md:w-[50%] flex lg:flex-row relative flex-col gap-5 md:gap-0  justify-between"
            ref={testRef}
          >
            <video
              src={video}
              className="w-full"
              muted
              autoPlay
              loop
              controls={false}
            />
          </div>
          <div className="w-full md:w-[45%]" ref={textRef}>
            <h3 className="sm:text-5xl text-3xl font-bold text-gray-300">
              Reduce the resources and time spent on hiring
            </h3>
            <p className="text-xl text-gray-500 w-full mt-2">
              The Average cost of hiring a candidate is ₹40,000 and time taken
              to hire a candidate is 45 days. Not anymore! Use our challenges
              and real time dashboard to reduce time and resources spent on each
              hire!
            </p>
          </div>
        </div>
      </div>
      <div className="flex w-full min-h-screen justify-center md:justify-around items-center">
        <div className="w-full flex flex-col lg:flex-row-reverse md:px-20 px-10 lg:justify-between">
          <div
            className="w-full md:w-[50%] flex lg:flex-row relative flex-col gap-5 md:gap-0  justify-between"
            ref={testRef}
          >
            <img src={ThirdImg} alt="" className="w-full" />
          </div>
          <div className="w-full md:w-[45%]" ref={textRef}>
            <h3 className="sm:text-5xl text-3xl font-bold text-gray-300">
              Manage your employees, effectively
            </h3>
            <p className="text-xl text-gray-500 w-full mt-2">
              Track their progress, skills, interests and mental health. Long
              drawn out performance meetings and tedious training sessions are
              now a thing of the past!
            </p>
          </div>
        </div>
      </div>
      <a href='https://wa.me/919632247617'>
      <button className={classes.whatsapp}>
        <FaWhatsapp  size={40}/>
      </button>
      </a>
    </div>
  );
};

export default EntryPageForRecruiters;
