import React,{useEffect,useState} from 'react'
import { getUser  } from "../../APIServices/UserAPI" ;
import Score from "./Score.js"
import Score1 from "./Score1.js"
import "./RecruiterDashboard.css"
function QuizDetail({id,quizzes,quizDetails}) {
    

    const [response , setResponse] = useState([])
    useEffect(()=>{
        getUser(id)
        .then((data) => {
          console.log(data.data)
          //setUser(data.data)
          setResponse(data.data.responses)
          //obj = data.data.quizName;
          //console.log(obj);

        }, error => {
            const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            console.log(resMessage);
        });
    },[])

  return (

        
            quizzes.map(quiz =>{
 
                const foundQuiz =  response.find(res => res === quiz) ;     
                

    
                return (
        
                    <td className="td">
                        <div className="in">{foundQuiz ? <Score userId={id} quizId = {foundQuiz} quizDetails={quizDetails}/>: "NA"}</div>
                        <div className="in">{foundQuiz ? <Score1 userId={id} quizId = {foundQuiz} quizDetails={quizDetails}/>: " "}</div>
                    </td>
            
                    
                    
                
                   
                
                )
            

                
                  })

        


    
        
    

    
  )
}

export default QuizDetail