import React, { useState, useEffect } from "react";
import classes from "./post.module.css";
import { Grid, CircularProgress, Paper, Typography } from "@material-ui/core";
import Post from './Post/Post';
import { useSelector } from "react-redux";
import { getPostByPage } from "../../APIServices/Loading";
import { Marginer } from "../Auth/Marginer";
import Formbar from "../FormBar/FloatingButtonForm";
import InfiniteScroller from "react-infinite-scroll-component";

const Posts = ({ setCurrentId }) => {
    const [posts, setPosts] = useState([]);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const [creator, setCreator] = useState(null);
    const userId = user?.result?.googleId || user?.result?._id;
    const [isLoading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [currLength, setCurrentLength] = useState();
    useEffect(() => {
        getPostByPage({ page: page })
            .then((result) => {
                setPosts([...posts, ...result.data])
                if (currLength === [...posts, ...result.data].length) {
                    setHasMore(false)
                } else {
                    setCurrentLength([...posts, ...result.data].length)
                }
                setLoading(false)
            }, error => {
                console.log(error)
            })
    }, [page])

    if (!posts.length && !isLoading) return (
        <div>
            <Formbar />
            <div className={classes.paper}>
                <p variant='h5' align='center'>
                    Please use the Button above to create a Post!!
                </p>
            </div>
        </div>
    );

    const handlePageLoad = () => {
        setTimeout(() => {
            setPage(page + 1)
        }, 300)
    }

    return (
        isLoading ? <CircularProgress /> : (

            <div className={classes.container} >

                <Formbar />
                <div className={classes.grid}>
                    {posts.map((post) => (
                        <Post key={post._id} post={post} setCurrentId={setCurrentId} />

                    ))}
                </div>
                <div className="App" style={{ textAlign: "center", margin: "auto", alignContent: "center" }}>
                    <InfiniteScroller dataLength={posts?.length} next={handlePageLoad} hasMore={hasMore} endMessage={<h5 style={{ color: "white" }}>End of the line!</h5>} loader={<h5 style={{ color: "white" }}>Loading....</h5>} />
                </div>
            </div>

        )
    );

}

export default Posts;