import React from 'react';
import { Toolbar, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

const BlogHeader = () => {
  return (
    <div>
      <Toolbar>
        <Link to='/blog'><Button style={{ color: 'var(--opp)' }}>Home(Blog)</Button></Link>
        <Link to='/'><Button style={{ color: 'var(--opp)' }}>Candidate Landing page</Button></Link>
        <Link to='/auth'><Button style={{ color: 'var(--opp)' }}>Candidate Log in</Button></Link>
        <Link to='/recruiters'><Button style={{ color: 'var(--opp)' }}>Employer Landing page</Button></Link>
      </Toolbar>

    </div>
  )
}

export default BlogHeader
