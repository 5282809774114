import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Container, AppBar, Typography, Grow, Grid, Card, Button, ListItemAvatar, ListItem, List, Paper } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { Marginer } from "../Auth/Marginer";
import { getProfiles } from '../../actions/profile';
import PostsOfUser from '../Posts/UserPostsB';
import axios from 'axios';
import UserQuestions from '../Questions/UserQuestions'
import usurpLogo from "../Sidebar/usurp-logo.png";
import "../../App.css";
import ProfileData from './ProfileData';
import classes from "./profilepage.module.css";
import { fetchTopUsers } from '../../api/analysis';
import StatsOfUser1 from './StatsOfUser1';
import IntroductionAndGoals from './IntroductionAndGoals';
import EducationalDetails from './EducationalDetails';
import Certifications from './Certifications';
import Experiences from './Experiences';
import QuestionsOfUser from '../Questions/UserQuestions';


const ProfilePage = (props) => {
  const [displayPost, setDisplayPost] = useState(true)
  const [currentId, setCurrentId] = useState(null);

  const { post, posts, isLoading } = useSelector((state) => state.posts);

  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const { question, questions } = useSelector((state) => state.questions);
  const location = useLocation();


  useEffect(() => {
    const token = user?.token;
    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [location]);
  const dispatch = useDispatch();







  return (
    <>
      <div className={classes.container}>

        <div>
          <StatsOfUser1 />
          <div className={classes.section}>

            <ProfileData />
            <IntroductionAndGoals />
            <EducationalDetails />
            <Certifications />
            <Experiences />

          </div>


          <div className={classes.buttons}>
            <button className={classes.button} style={{ backgroundColor: `${displayPost ? "var(--opp)" : "inherit"}` }} onClick={() => setDisplayPost(true)}>{user.result.name}'s Posts</button>
            <button className={classes.button} style={{ backgroundColor: `${!displayPost ? "var(--opp)" : "inherit"}` }} onClick={() => setDisplayPost(false)}>{user.result.name}'s Questions</button>

          </div>

          {displayPost ?
            <PostsOfUser currentId={currentId} setCurrentId={setCurrentId} />
            :
            <QuestionsOfUser currentId={currentId} setCurrentId={setCurrentId} />
          }



        </div>


      </div>

    </>
  )
}


export default ProfilePage;