import React from 'react';

// import { ArcElement } from "chart.js";
// import Chart from "chart.js/auto";

import { Doughnut } from "react-chartjs-2";

const DoughnutChart = ({ chartData }) => {
  return (
    <div>


    </div>
  )
}

export default DoughnutChart
