import axios from "axios";
const API = axios.create({ baseURL: "https://usurp.live" })

// Contains the all the API requests for questions

API.interceptors.request.use((req) => {
    if (localStorage.getItem('profile')) {
        req.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }
    return req;
});

export const getPostByPage = async (data) => {
    return API.post("/posts/getPostRange", data)
}

export const getQuestionByPage = async (data) => {
    return API.post("/questions/getQuestionRange", data)
}

export const getContestByPage = async (data) => {
    return API.post("/contests/getContestRange", data)
}

export const getResponseByPage = async (data) => {
    return API.post("/getResponseByPage", data)
}

export const getQuizByPage = async (data) => {
    return API.post("/getQuizByPage", data)
}

export const getQuizOfUserByPage = async (data) => {
    return API.post("/getQuizOfUserByPage", data)
}
