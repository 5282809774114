import React from "react";
import classes from "./contests.module.css";
import { Grid, CircularProgress } from "@material-ui/core";
import Contest from './Contest/Contest';
import { useSelector } from "react-redux";
import Formbar from "../FormBar/FormButtonC";

const Contests = ({ setCurrentId }) => {
    const { contests, isLoading } = useSelector((state) => state.contests || {});


    return (
        isLoading ? <CircularProgress /> : (

            <div className={classes.container} >


                <Formbar />
                <div className={classes.grid}>
                    {contests?.map((contest) => (
                        <Contest contest={contest} setCurrentId={setCurrentId} />

                    ))}
                </div>

            </div>

        )
    );

}

export default Contests;