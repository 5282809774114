import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';


import { getSpecificUserProfile, getUserProfile } from '../../actions/profile';
import { Container, AppBar, Typography, Grow, Grid, Card, ListItemAvatar, Button, ListItem, List, Paper, Divider } from '@material-ui/core';
import useStyles from "./styles";
import FormbarP from '../FormBar/FormButtonP';
import ProfilePoints from './ProfilePoints';


const ProfileData = ({ currentId, setCurrentId }) => {
  const classes = useStyles();
  const { profiles, profile, isLoading } = useSelector((state) => state.profiles);

  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const location = useLocation();
  const dispatch = useDispatch();


  useEffect(() => {
    const token = user?.token;
    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [location]);
  useEffect(() => {
    dispatch(getUserProfile());
  }, [currentId, dispatch]);


  return (
    <div className={classes.card}>

      <div className={classes.details} >
        <img className={classes.image}
          class_name="header_logo"
          src={user.result.imageUrl}
          alt="profile-picture"
        />
        <h5 className={classes.email}  >
          {user.result.email || user.result.emailId}
        </h5>
        <h5 className={classes.number}  >
          {user.result.phoneNumber || profiles?.number}
        </h5>
      </div>


      <div className={classes.title}>
        <h4 className={classes.h5} >
          {user.result.name}
        </h4>

      </div>


      <ProfilePoints creator={profiles?.data?.creator} />



    </div>
  )
}

export default ProfileData;