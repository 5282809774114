import React from 'react';
import {Typography, Container} from '@material-ui/core';
import BlogHeader from './BlogHeader';
function PortfolioImportance() {
  return (
    <>
    <BlogHeader/>
    <Container style={{marginTop: '5vh', marginBottom: '5vh'}} maxWidth='lg'>
    <div>
      <Typography style={{color: 'white', marginTop: '3vh'}} variant='h3'>Easiest way to get a job in 2023 - Portfolios!</Typography>
      <Typography style={{color: 'white', marginTop: '3vh'}} variant= 'body1'>
When I completed my Digital Marketing course and was eager to begin my career I updated my resume as I began applying to different companies, I noticed that I wasn’t receiving a call for an interview. I was not sure why this was the case, so I sought to seek advice from my mentor. She suggested that instead of relying on a resume I should create a portfolio to showcase my best work, skills, and potential. I didn’t think much of it, and neither did I know how to make one. One day when I was applying for a Digital Marketing role, I noticed another candidate sitting beside me with her portfolio. I asked her if I could take a look and she obliged. It was beautiful! I could understand who she was, and relate to her. Even I knew that she would end up with the role. Lo and behold, she bagged the job. 
 </Typography>
  <Typography style={{color: 'white', marginTop: '3vh'}} variant= 'body1'>
  I knew that I had to create one for myself. So I did some research and started working on mine. It worked so well! In the next interview, I was selected. The employer was the founder of a startup. He mentioned that I could work a little more on the portfolio, but that it showed heart, consistency, and dedication. I have noted down everything I learned from him, which helped me make an awesome portfolio that set me apart from the competition. 
  </Typography>
  <Typography style={{color: 'white', marginTop: '6vh'}} variant='h4'>What are the benefits of having a portfolio?</Typography>
  <Typography style={{color: 'white', marginTop: '3vh'}} variant='body1'>We are in a skill economy now. Highly skilled employees are valued by their employers. To demonstrate that you are valuable to a company before you even join, you need something that can prove it. This is why you need a portfolio. </Typography>
  <Typography style={{color: 'white', marginTop: '3vh'}} variant='body1'>Portfolios are a great way to demonstrate the competencies you will list on a resume or talk about in an interview — they allow you to show and not just tell. During a job search, a portfolio showcases your work to potential employers. It presents evidence of your relevant skills and abilities.</Typography>
  <Typography style={{color: 'white', marginTop: '3vh'}} variant='body1'>
  The personal information that you incorporate into your portfolio can greatly reflect your abilities as an individual and become a useful tool in marketing yourself to employers, corporations, colleges, and universities. A portfolio can set you apart from other applicants in a professional or academic setting. 
  </Typography>
  <Typography style={{color: 'white', marginTop: '3vh'}} variant='body1'>
  • It allows you to be more personal and creative in order to expand on and exhibit your skills, knowledge, projects, and experiences.
  </Typography>
  <Typography style={{color: 'white'}} variant='body1'>
  • A portfolio is a method of self-discovery and confidence building.
  </Typography>
  <Typography style={{color: 'white'}} variant='body1'>
  • It is a multi-faceted way to organize your accomplishments, goals, aspirations, and personal thought. It showcases your personality to potential employers and organizations. 
  </Typography>
  <Typography style={{color: 'white'}} vairant='body1'> 
  • It is a useful tool to include in an interview. It provides tangible proof of your skills and abilities and demonstrates to the employer that you are qualified for that specific job.
  </Typography>
  <Typography style={{color: 'white', marginTop: '3vh'}} variant='body1'>
  All professions typically use portfolios to showcase their work. Artists, interior designers, cake decorators, graphic designers, writers, teachers, architects, and others in visual professions have been documenting their work in portfolios for years. But now it has become apparent to people in other professions as well that you need a portfolio to attain trust in the market.
  </Typography>
  <Typography style={{color: 'white', marginTop: '6vh'}} variant='h4'>
  Now that we know why a portfolio is important, how do we make one?
  </Typography>
  <Typography style={{color: 'white', marginTop: '6vh'}} variant='h4'>
  What do we include in it so that it attracts the viewer? 
  </Typography>
  <Typography style={{color: 'white', marginTop: '3vh'}} variant='h4'>
  What to Include in a Portfolio:
  </Typography>
  <Typography style={{color: 'white'}} variant='body1'>
  1.	<span>Introduction - </span>Your introduction will give potential employers a sneak peek at who you are as a person, what you can do, and what it is like to work with you. They get a glimpse of you beforehand. This will engage the reader and build some trust. 
  </Typography>
  <Typography style={{color: 'white'}} variant='body1'>
  2.	<span>Credentials -</span> Your portfolio should include credentials like your resume, any awards you received, your educational transcripts or diplomas, and any recommendation letters you earned. This will show that you are more than qualified for the position. It will build trust as it clearly shows that multiple other institutions and groups can verify your abilities.   
  </Typography>
  <Typography style={{color: 'white'}} variant='body1'>
  3.	<span>Summary Page -</span>  Including a summary page can show an employer what you have accomplished in the past and how you can potentially add value to their organization. You should add your goals as well.
  </Typography>
  <Typography style={{color: 'white'}} variant='body1'>
  4.	<span>Work Samples –</span> Showcasing your work samples is the ultimate proof of your skills and abilities. If you want to work with a specific organization or client, choose work samples that they would ideally prefer to see. 
 </Typography>
 <Typography style={{color: 'white'}} variant='body1'>
    5.	<span>Marketable skills and abilities –</span> When you showcase these, you inherently prove that these skills are potential gold mines in the existing market and that adding them into their organization is going to benefit them.  
 </Typography>
    <Typography style={{color: 'white'}} variant='body1'>
    6.	<span>Content section -</span> If your portfolio has many projects, it is a good practice that you start it with a table or list of contents. This will make it easy for the reader to navigate.
    </Typography>
    <Typography style={{color: 'white'}} variant='body1'>
    <span>Remember, you are trying to make their life easy, not show off. Keep it simple.</span> 
    </Typography>
    <Typography style={{color:'white'}} variant='body1'>
    7.	<span>Include your contact –</span> You can provide basic contact information like your phone number, email address and location to easily reach to you.
    </Typography>
    <Typography style={{color:'white'}} variant='body1'>
    8.	<span>Include recommendations and testimonials –</span> This is a game-changer that many ignore. It is very powerful so never forget it. Add the recommendations you received from your previous employers such as supervisors or managers and even coworkers. This shows that you were valued in your previous role and instills confidence in your potential employer. 
    </Typography>
    <Typography style={{color:'white', marginTop: '3vh'}} variant='body1'>If you are a fresher add testimonials from your professors, educators and mentors. </Typography>
    <Typography style={{color:'white', marginTop: '3vh'}} variant='body1'>Also, a pro tip, save the testimonials received from customers and clients. Adding them puts you in a different light altogether.  
    </Typography>
    <Typography style={{color:'white', marginTop: '3vh'}} variant='body1'>Adding recommendations and testimonials prove that you were consistent and performing well through all stages of your life. It shows dedication and good work ethics. 
    </Typography>
    <Typography style={{color:'white', marginTop: '3vh'}} variant='body1'>Now that you are armed with this information, go make an awesome portfolio that shocks and puts your potential employers in awe. Good portfolios make you famous among senior staff in the company even before you join. 
    </Typography>
    <Typography style={{color:'white', marginTop: '6vh'}} variant='body1'>There are multiple platforms to use when creating your portfolio. But nothing compares to Usurp.
    </Typography>
    <Typography style={{color:'white', marginTop: '3vh'}} variant='body1'>Here, not only will you be able to add all the information mentioned above, but you will also be able to take skill tests, aptitude tests, and personality tests. These results are automatically showcased in your portfolio making it even more powerful. It also has skill ratings in various fields showing your competency. You can log in and update your profile to easily include the information mentioned above. 
    </Typography>
    <Typography style={{color:'white', marginTop: '3vh'}} variant='body1'>You can get started by clicking<a href='/auth'><span>here</span></a>. Begin your journey of making an awesome portfolio with Usurp. 
    </Typography>
    </div>
    </Container>
    </>
  )
}

export default PortfolioImportance
