import React, { useState, useEffect } from "react";
import { getUser } from "../../APIServices/UserAPI";

function ContactInfo({ id }) {
  //console.log(id);
  //const [user , setUser] = useState([]);
  const [info, setInfo] = useState([]);

  useEffect(() => {
    getUser(id).then(
      (data) => {
        console.log(data?.data);
        //setUser(data.data)
        setInfo(data?.data);
        console.log(info);
        //obj = data.data.quizName;
        //console.log(obj);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(resMessage);
      }
    );
  }, []);
  if (id == null) {
    return (
      <div>
        <h6 className="h6">-</h6>
      </div>
    );
  }
  return (
    <div>
      <pre style={{fontSize: '1.5vh'}}>{info?.emailId}</pre>
    </div>
  );
}

export default ContactInfo;
