import React from 'react';
import { Paper, Avatar, h3, Container } from '@material-ui/core';
import Showcase from '../../Assets/Showcase yourself1.png';
import Become from '../../Assets/Become a usurper1.png';
import Earn from '../../Assets/earn kp1.png';
import Compete from '../../Assets/Compete in tournaments 1.png';
import Jobs from '../../Assets/Win jobs and cash rewards1.png';
import Upskill from '../../Assets/Upskill1.png';
import Usurper from '../../Assets/usruper to ruler1.png';
import kiran from '../../Assets/Kiran Chandra.png';
import raviAvatar from '../../Assets/Ravi Bhanshali.png';
import major from '../../Assets/MAjor Udaya Shanker.png';
import shilpiAvatar from '../../Assets/Shilpi Agarwal.png';
import soujanyaAvatar from '../../Assets/Soujanya.png';
import riyaAvatar from '../../Assets/Riya Agarwal.png';
import usurpLogo from "../Auth/usurp-logo-white.png";
import classes from "./about.module.css"
import { FaLinkedinIn } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";

const AboutUsurp = () => {

  return (
    <div className={classes.container} >
      <div className={classes.card}>
        <h2>About Us</h2>

       <h3 className={classes.statement}> Experience the full potential of your candidates and employees with our all-in-one HRTech solution. Usurp is expertly designed to save you valuable time and money by streamlining every aspect of the recruitment process. With our advanced testing platform(Challenge Engine), you'll no longer have to waste time and money sifting through countless resumes or interviewing candidates who may not be the right fit for your organisation. Not only does our platform evaluate technical skills, but it also assesses cultural fit, ensuring a seamless and efficient hiring experience. Say goodbye to the traditional, time-consuming recruitment process and hello to a smarter, more efficient way of finding top talent. </h3>
       
      </div>
      <div className={classes.card}>
        <h2>Brand Story</h2>

       <h3 className={classes.statement}>Welcome to a community where your skills and expertise shine bright, and your potential knows no bounds. Our platform is built on the belief that everyone deserves the opportunity to showcase their abilities and make meaningful connections in their field.</h3>

       <h3 className={classes.statement}>As a member of our community, you can take part in exciting hackathons, earn badges and set yourself apart from the crowd. But it doesn't end there - we also offer a unique opportunity to test your knowledge and expertise, both by giving and taking tests. With every question answered and badge earned, you'll see your profile grow and your reputation soar.</h3>

       <h3 className={classes.statement}>We understand that success doesn't come from working alone. That's why we've created a community of passionate individuals who share their skills, knowledge, and experiences. You can share your expertise by answering questions or posting your own queries, and connect with like-minded professionals to collaborate on projects and expand your horizons.</h3>

       <h3 className={classes.statement}>So whether you're a seasoned expert or just starting out, we're here to support you on your journey to success. Join us, and let your potential flourish in a community where collaboration and knowledge sharing are at the heart of everything we do.</h3>
       
      </div>
      <div className={classes.card}>
        <h2>What's special about Usurp?</h2>

       <h3 className={classes.statement}> Let's get beyond the resume. Only 20% of what's written on a resume actually matters in the hiring process. With our innovative HR technology, we use real data to uncover the true potential of each candidate. Find the right talent that truly fits your company. Join us in unlocking the full potential of HR. </h3>
       
      </div>
      <div className={classes.card}>
        <h2 className={classes.Header}>
          The Team
        </h2>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: '30px', gap: "40px" }}>
          <div className={classes.teambox}>
            <div>
              <div className={classes.aboutimg}>
                <img style={{ width: "100%", height: "100%", }} src={kiran} alt="avatar" />
              </div>
              <p className={classes.Question1}>Komarraju Kiran Chandra</p>
              <p className={classes.Statement1}>Founder and CEO</p>
              <a href='https://www.linkedin.com/in/kiran-chandra-komarraju-893674160/' className={classes.icons}>
                <FaLinkedinIn style={{ color: '#25b8ef', cursor: 'pointer' }} size={30} />
              </a>
            </div>
            <div>
              <div className={classes.aboutimg}>
                <img style={{ width: "100%", height: "100%", }} src={major} alt="avatar" />
              </div>
              <p className={classes.Question1}>Major (Retd) K. Udaya Shanker</p>
              <p className={classes.Statement1}>Chief Operating Officer</p>
              <a href='https://www.linkedin.com/in/major-retd-k-udaya-shanker-04661147/' className={classes.icons}>
                <FaLinkedinIn style={{ color: '#25b8ef', cursor: 'pointer' }} size={30} />
              </a>
            </div>
          </div>
          <div className={classes.teambox}>
            <div>
              <div className={classes.aboutimg}>
                <img style={{ width: "100%", height: "100%", }} src={raviAvatar} alt="avatar" />
              </div>
              <p className={classes.Question1}>Ravi Bhanshali</p>
              <p className={classes.Statement1}>Head of Sales and Marketing</p>
              <a href='https://www.linkedin.com/in/ravi-bhanshali/' className={classes.icons}>
                <FaLinkedinIn style={{ color: '#25b8ef', cursor: 'pointer' }} size={30} />
              </a>
            </div>
            <div>
              <div className={classes.aboutimg}>
                <img style={{ width: "100%", height: "100%", }} src={shilpiAvatar} alt="avatar" />
              </div>
              <p className={classes.Question1}>Shilpi Agarwal</p>
              <p className={classes.Statement1}>Brand Strategist</p>
            </div>
          </div>
          <div className={classes.teambox}>
            <div>
              <div className={classes.aboutimg}>
                <img style={{ width: "100%", height: "100%", }} src={soujanyaAvatar} alt="avatar" />
              </div>
              <p className={classes.Question1}>K Soujanya</p>
              <p className={classes.Statement1}>Head of Psychology and Strategy</p>

              <a href='https://www.linkedin.com/in/komarraju-soujanya-49883b59/' className={classes.icons}>
                <FaLinkedinIn style={{ color: '#25b8ef', cursor: 'pointer' }} size={30} />
              </a>
            </div>
            <div>
              <div className={classes.aboutimg}>
                <img style={{ width: "100%", height: "100%", }} src={riyaAvatar} alt="avatar" />
              </div>
              <p className={classes.Question1}>Riya Agarwal</p>
              <p className={classes.Statement1}>Brand Strategist</p>

              <a href='https://www.linkedin.com/in/riya-agarwal-20b24b239/' className={classes.icons}>
                <FaLinkedinIn style={{ color: '#25b8ef', cursor: 'pointer' }} size={30} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <a href='https://wa.me/919632247617'>
      <button className={classes.whatsapp}>
        <FaWhatsapp  size={40}/>
      </button>
      </a>
    </div>
  )
}

export default AboutUsurp