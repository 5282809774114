import React from 'react'
import "./RecruiterDashboard.css"

function Score({userId,quizId,quizDetails}) {

    const Score = quizDetails.find(res => res.quizId === quizId && res.userId === userId);
  return (
    
    <p>{Score.correctAnswers}</p>

)
}

export default Score;