import React, { useState, useEffect } from "react";
import "./RecruiterDashboard.css";
import { getQuizById } from "../../APIServices/QuestionAPI";
//import quizName from "./"
function ViewAllAnswers({ detailResponses, correctResponse }) {
  const [responses, setResponses] = useState([]);
  const [correct, setCorrect] = useState([]);
  const [Title, setTitle] = useState("");
  const [name, setName] = useState("");
  useEffect(() => {
    setResponses(detailResponses);
    console.log(correctResponse);
    getQuizById(correctResponse).then(
      (data) => {
        //console.log(data.data)
        console.log(data);
        setName(data.data.quizName);
        setCorrect(data.data.questions);
        console.log(data.data.questions);

        //setQuizs(data.data)
        //obj = data.data.quizName;
        //console.log(obj);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(resMessage);
      }
    );
  }, []);
  const Name = (props) => {
    const { data1 } = props;
    console.log(data1);

    var c = 0;
    {
      data1.map((title) => {
        if (c == 0) {
          setTitle(title.quizName);
          c = c + 1;
        }
      });
    }
    return <h2 className="h2">{Title}</h2>;
  };

  const Row = (props) => {
    const { option } = props;
    return (
      <tr>
        <td>{option}</td>
        {/*  <td>{correct}</td> */}
        {/* td>{contactInfo}</td>
            <td>{grade}</td>
            <td>{correctAnswers}</td>
            <td>{videos}</td>
            <td>{screenRecording}</td>
            <td>{audioRecording}</td>
            <td>{viewAll}</td> */}
      </tr>
    );
  };
  const Row1 = (props) => {
    const { Correct } = props;
    return (
      <tr>
        <td>{Correct}</td>
        {/*  <td>{correct}</td> */}
        {/* td>{contactInfo}</td>
            <td>{grade}</td>
            <td>{correctAnswers}</td>
            <td>{videos}</td>
            <td>{screenRecording}</td>
            <td>{audioRecording}</td>
            <td>{viewAll}</td> */}
      </tr>
    );
  };
  const Row2 = (props) => {
    const { question } = props;
    return (
      <tr>
        <td>{question}</td>
        {/*  <td>{correct}</td> */}
        {/* td>{contactInfo}</td>
            <td>{grade}</td>
            <td>{correctAnswers}</td>
            <td>{videos}</td>
            <td>{screenRecording}</td>
            <td>{audioRecording}</td>
            <td>{viewAll}</td> */}
      </tr>
    );
  };

  const Table = (props) => {
    const { data } = props;
    console.log(data);
    return (
      <>
        <table>
          <tbody>
            {data.map((row) => (
              <>
                <Row option={row.option} />
                {/* <Row userName = {row.userName}
                    contactInfo = <ContactInfo id = {row.userId}/>
                    grade = {row.grade}
                    correctAnswers = {row.correctAnswers}
                    videos = <LinkFile link={row.videos}/>
                    screenRecording=<LinkFile link={row.screenRecording}/>
                    audioRecording= <LinkFile link ={row.audioRecording}/>
              
                    
                    viewAll = <ViewAll response = {row.response}/> */}
              </>
            ))}
          </tbody>
        </table>
      </>
    );
  };
  const Table1 = (props) => {
    const { data1 } = props;
    console.log(data1);
    return (
      <>
        <table>
          <tbody>
            {data1.map((row) => (
              <>
                <Row1 Correct={row.answer} />
                {/* <Row userName = {row.userName}
                  contactInfo = <ContactInfo id = {row.userId}/>
                  grade = {row.grade}
                  correctAnswers = {row.correctAnswers}
                  videos = <LinkFile link={row.videos}/>
                  screenRecording=<LinkFile link={row.screenRecording}/>
                  audioRecording= <LinkFile link ={row.audioRecording}/>
            
                  
                  viewAll = <ViewAll response = {row.response}/> */}
              </>
            ))}
          </tbody>
        </table>
      </>
    );
  };
  const Table2 = (props) => {
    const { data2 } = props;
    console.log(data2);
    return (
      <>
        <table>
          <tbody>
            {data2.map((row) => (
              <>
                <Row2 question={row.question} />
                {/* <Row userName = {row.userName}
                contactInfo = <ContactInfo id = {row.userId}/>
                grade = {row.grade}
                correctAnswers = {row.correctAnswers}
                videos = <LinkFile link={row.videos}/>
                screenRecording=<LinkFile link={row.screenRecording}/>
                audioRecording= <LinkFile link ={row.audioRecording}/>
          
                
                viewAll = <ViewAll response = {row.response}/> */}
              </>
            ))}
          </tbody>
        </table>
      </>
    );
  };
  return (
    <div class="view">
      <h3>{name}</h3>
      <table>
        <tr className="tr">
          <th>Question</th>

          <th>Correct option</th>

          <th>Option Chosen</th>
        </tr>
      </table>
      <table>
        <tr className="tr">
          <th>
            {" "}
            <Table2 data2={correct} />
          </th>

          <th>
            <Table1 data1={correct} />
          </th>
          <th>
            <Table data={responses} />
          </th>
        </tr>
      </table>

      {/* <table>
    <table>
        <tr className="tr">
            <th>Option Chosen</th>
            <Table data = {responses}/>
            {/* <th>Correct option</th>
            <Table1 data1 = {correct}/> */}
      {/* </tr>
    </table>
    <table>
        <tr className="tr"> */}
      {/* <th>Option Chosen</th>
            <Table data = {responses}/> }
            <th>Correct option</th>
            <Table1 data1 = {correct}/>
        </tr>
    </table>
    </table> */}
    </div>
  );
}

export default ViewAllAnswers;
