import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRandom } from "@fortawesome/free-solid-svg-icons";
import { IoMdArrowDroprightCircle } from "react-icons/io";
import { BiLoaderAlt } from "react-icons/bi";
import "./RecruiterDashboard.css";
function LinkFiles({ link }) {
  const [navigate, setNavigate] = useState(link);
  const [copy, setCopy] = useState(false);
  if (link.length === 0) {
    return (
      <div>
        <h6 className="h6">NA</h6>
      </div>
    );
  } else {
    return (
      <div>
        <button
          className="dashboard-button"
          onClick={() => {
            navigator.clipboard.writeText(navigate);
            setCopy(!copy);
          }}
        >
          {!copy ? <IoMdArrowDroprightCircle size={25} style={{color: '#cbfff6'}} /> : "Copied!"}
        </button>
      </div>
    );
  }
}

/* function linkFiles({link})
{
    const [modal, setModal] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);

  const openModal = () => {
    setModal(!modal);
  };

  const spinner = () => {
    setVideoLoading(!videoLoading);
  };
  return (
    <div className="App">
      <button className="dashboard-button" onClick={openModal} className="">
      <FontAwesomeIcon icon={faRandom}/>
        {modal ? (
              <div modal={modal} className="Modal1" overlayClassName="Overlay">
                <IoCloseOutline
                  className="modal__close"
                  arial-label="Close modal"
                  onClick={setModal}
                />
                <div className>
                  {videoLoading ? (
                    <div>
                      <BiLoaderAlt
                        fadeIn="none"
                      />
                    </div>
                  ) : null}
                  <iframe

                    onLoad={spinner}
                    loading="lazy"
                    width="800"
                    height="500"
                    src={link}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
        ) : null}
      </button>
    </div>
  );
} */
export default LinkFiles;
