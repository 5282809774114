import React, { useState } from 'react';
import { Typography, Button, Paper, Container, Snackbar } from '@material-ui/core';
import { createContact } from '../../actions/contact';
import classes from "./contact.module.css"

import { Alert } from '@mui/material';
import axios from 'axios';
function ContactUs() {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [open, setOpen] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataContact = new FormData();
    formDataContact.append("Name", name);
    formDataContact.append("Message", message);

    formDataContact.append("Subject", subject);
    formDataContact.append("Email", email);

    formDataContact.append("PhoneNumber", phoneNumber);

    axios({ method: 'post', url: 'https://usurp.live/contact', data: formDataContact }).then(res => {
      setOpen(true);
      console.log(res);

    })
      .catch(function (err) {
        console.error(err);
      })
  };

  return (
    <div className={classes.container}>
      <div className={classes.card}>
        <h2 className={classes.h2}>Contact Us or Share Feedback</h2>
        <input type="text" placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} />
        <input type="email" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
        <input type="tel" placeholder='Phone Number' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
        <input type="text" placeholder='Subject' value={subject} onChange={(e) => setSubject(e.target.value)} />
        <textarea value={message} placeholder="Your Message" onChange={(e) => setMessage(e.target.value)} />
        <button onClick={handleSubmit} className="bt-gradient">Contact Us!</button>
        <Snackbar
          open={open}
          onClose={() => { setOpen(false) }}
          message="Your request/feedback is sent"
          key={"center bottom"}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert onClose={() => { setOpen(false) }} severity="success" sx={{ width: '100%' }}> Your request/feedback is sent</Alert>
        </Snackbar>

      </div>
    </div>
  )
}

export default ContactUs
