import React,{useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import Dashboard1 from "./Dashboard1.js";
import "./RecruiterDashboard.css"
function Dashboard() {
    

  const [user, setUser] = React.useState(JSON.parse(localStorage.getItem("profile")).obj ? 
  JSON.parse(localStorage.getItem("profile")).obj : JSON.parse(localStorage.getItem("profile")).result)
  const navigate = useNavigate();
  const [quizNo , setQuizNo] = useState([]);
    useEffect(() => {
        console.log("res");
        setUser(JSON.parse(localStorage.getItem("profile")).obj ? 
        JSON.parse(localStorage.getItem("profile")).obj : JSON.parse(localStorage.getItem("profile")).result)
        console.log(user.data.quizs);
        //console.log("ss")
        setQuizNo(user.data.quizs);
       
    }, [user._id])
    

  return (
    <div>
      <h2 className="h2">Cummulative Dashboard</h2>
      <Dashboard1 quizz={quizNo} />
    </div>
  );
}

export default Dashboard