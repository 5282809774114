import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { BiPowerOff } from "react-icons/bi";
import { Link } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import classes from "./Sidebar.module.css";




function Sidebar() {
    const [sidebar, setSidebar] = useState(false);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const logout = () => {
        dispatch({ type: 'LOGOUT' });
        navigate('/');
        setUser(null);
    }
    const showSidebar = () => setSidebar(!sidebar);
    return (
        <>
            <div className={classes.navbar}>
                <Link to="#" className={classes.menuBars}>
                    <FaBars onClick={showSidebar} />
                </Link>



            </div>
            {sidebar && <div className={classes.backdrop} />}
            <nav className={sidebar ? classes.navMenuActive : classes.navMenu}>
                <ul className={classes.navMenuItems} onClick={showSidebar}>
                    <li className={classes.navbarToggle}>
                        <Link to="#" className={classes.closeMenu}>
                            <AiOutlineClose />
                        </Link>
                    </li>
                    {SidebarData.map((item, index) => (
                        <li key={index} className={classes.text}>
                            <Link to={item.path} id={user?.result?.googleId || user?.result?._id}>
                                {item.icon}
                                <span className={classes.span}>{item.title}</span>
                            </Link>
                        </li>

                    ))}
                    <button onClick={logout}><BiPowerOff size={20} />LOG OUT</button>
                </ul>

            </nav>
        </>
    )
}

export default Sidebar;