import React, { useState, useEffect } from 'react';
import { BiLogIn } from "react-icons/bi";
import usurpLogo from "./Assets/Asset 1.png";
import { Avatar } from '@material-ui/core';
import decode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PointsForHeader from './components/Profile/PointsForHeader';
import Sidebar from './components/Sidebar/Sidebar';
import classes from './Header.module.css';


const Header = () => {


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const logout = () => {
        dispatch({ type: 'LOGOUT' });
        navigate('/');
        setUser(null);
    }

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));

    const location = useLocation();

    useEffect(() => {
        const token = user?.token;
        if (token) {
            const decodedToken = decode(token);
            if (decodedToken.exp * 1000 < new Date().getTime()) logout();
        }
        setUser(JSON.parse(localStorage.getItem('profile')));
    }, [location]);

    return (

        <div className={classes.header}>
            <Sidebar />
            <img
                className={classes.headerLogo}
                src={usurpLogo}
                alt="usurp-logo"
            />
            {user ? (
                <div className={classes.profile}>
                    <PointsForHeader />
                    <Link to='/profile'>
                        <Avatar className={classes.purple} alt={user?.result?.name || user.user.name} src={user?.result?.imageUrl || user.user.imageUrl}></Avatar>
                    </Link>


                </div>
            ) : (
                <Link to='/auth'>
                    <button className={classes.button} ><BiLogIn />Log In</button>
                </Link>
            )}

        </div>


    )

}

export default Header;