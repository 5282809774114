import React, { useState, useEffect } from 'react'
import {getQuizById} from "../../APIServices/QuestionAPI";
function QuizName({quizNumber}) {
  const [name , setName] = useState([]);
    //console.log(na);
    useEffect(()=>{
      getQuizById(quizNumber)
      .then((data) => {
        console.log(data.data)
        setName(data.data)
        //console.log(name);
  
      }, error => {
          const resMessage =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();
          console.log(resMessage);
      });
      
  },[])
  console.log(name);
  return (
    <div>
      <h2 className="h2">{name?.quizName}</h2>
    </div>
  );
}

export default QuizName;