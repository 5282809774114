import { makeStyles } from '@material-ui/core/styles';
import { autocompleteClasses } from '@mui/material';


export default makeStyles({
  container: {
    padding: "10vh 5vw",
    background: "var(--bg)",
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backgroundBlendMode: 'darken',
  },
  border: {
    border: 'solid',
  },
  fullHeightCard: {
    height: '100%',
  },
  card: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '15px',
    width: '100%',
    boxShadow: 'var(--shadow)',
    flexWrap: "wrap",
    background: "var(--card-bg)",
    gap: "50px",
    "@media (max-width: 800px)": {
      flexDirection: "row",
      gap: "10px !important",

    },
  },
  statsHeading: {
    fontSize: '3vh',
    color: '#25b8ef',
    marginLeft: '2vh'
  },
  statsCard: {
    background: 'var(--card-bg)',
    padding: '3vh',
    borderRadius: '15px',
    boxShadow: '5px 5px 12px rgba(5, 5, 10, 0.8), -5px -5px 10px rgba(255, 255, 255, 0.20)',
    width: "100%",
    height: "100%",
  },
  statsB: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    
  },
  overlay: {
    position: 'absolute',
    top: '20px',
    left: '20px',
    color: 'black',
  },
  overlay2: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    color: 'white',
  },
  grid: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: "column",
    // justifyContent: 'space-between',
    color: 'var(--opp)',
    "@media (max-width: 400px)": {
      justifyContent: 'space-between',
    },
  },
  title: {
    display: 'flex',
    // justifyContent: 'space-between',
    gap: "10vw",
    color: 'var(--opp)',
    fontSize: "20px"
  },
  cardActions: {
    padding: '0 16px 8px 16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  image: {
    height: '100px',
    width: '100px',
    borderRadius: '50%',
    boxShadow: '3px 3px 10px 3px rgba(5, 5, 10, 0.75), -3px -3px 10px 3px rgba(255, 255, 255, 0.25)',
    "@media (max-width: 650px)" : {
       boxShadow: '1.25px 1.25px 4px 1.25px rgba(5, 5, 10, 0.75), -1.25px -1.25px 4px 1.25px rgba(255, 255, 255, 0.35)',
       height: '60px',
       width: '60px',
     },
    "@media (max-width: 450px)" : {
       boxShadow: '1.25px 1.25px 4px 1.25px rgba(5, 5, 10, 0.75), -1.25px -1.25px 4px 1.25px rgba(255, 255, 255, 0.35)',
       height: '50px',
       width: '50px',
     },
  },
  details2: {
    display: 'flex',
    flexDirection: "column",
    fontSize: '18px',
    gap: "10px",


    "@media (max-width: 808px)": {

      fontSize: '14px',
    },
    "@media (max-width: 500px)": {

      justifyContent: 'space-between',
      fontSize: '8px',
    },
  },
  h5: {
    color: '#25b8ef',
    fontSize: '5vh',
    fontWeight: '600',

    "@media (max-width: 1000px)": {

      fontSize: '5vh',
      fontWeight: '600',
    },
    "@media (max-width: 650px)": {

      fontSize: '2.5vh',
    },
    "@media (max-width: 450px)": {

      fontSize: '2vh',
    },

  },
  h6: {
    color: '#25b8ef',
    textAlign: "center",
    fontSize: '22px',


  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    marginTop: '70px',
    marginLeft: '70px',
    marginRight: '50px',
    marginBottom: '-10px',
    "@media (max-width: 550px)": {
      marginTop: '0px',
      marginLeft: '5vw',
      marginRight: '0px',
      marginBottom: '0px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',

    },
    "@media (max-width: 450px)": {
      marginLeft: '5vw',
      marginRight: '0px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',

    },
    "@media (max-width: 1100px)": {
      display: 'flex',
      fontSize: '14px',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      marginRight: '75px',
      marginTop: '35px',
    },

  },
  button: {
    borderRadius: '30px',
    background: '#1a1a1a',
    border: '3px solid #25b8ef',
    color: '#25b8ef',

    fontWeight: '600',
    fontSize: '1.8vh',
    fontFamily: 'Montserrat, sans-serif',
    padding: '1.2vh',
    outline: 'none',
    cursor: 'pointer',
    transition: '0.3s ease',
    boxShadow: '2.5px 2.5px 6.5px rgba(5, 5, 10, 0.75), -2.5px -2.5px 6.5px rgba(255, 255, 255, 0.25)',

    "@media (max-width: 650px)": {
      fontSize: '12px',
      padding: '1vh',
      marginLeft: '10vw',

      border: '1.5px solid #25b8ef',
      boxShadow: '1.5px 1.5px 4.5px rgba(5, 5, 10, 0.75), -1.5px -1.5px 4.5px rgba(255, 255, 255, 0.25)',
    },
    "@media (max-width: 450px)": {
      fontSize: '1vh',
      padding: '0.8vh',
      marginLeft: '10vw',
      width: '30vw',
      border: '1px solid #25b8ef',
      boxShadow: '1.5px 1.5px 4.5px rgba(5, 5, 10, 0.75), -1.5px -1.5px 4.5px rgba(255, 255, 255, 0.25)',
    },
  },
  buttonRight: {
    borderRadius: '15px',
    background: '#1a1a1a',
    border: '3px solid #25b8ef',
    color: '#25b8ef',
    
    fontWeight: '600',
    fontSize: '1.8vh',
    fontFamily: 'Montserrat, sans-serif',
    padding: '1.2vh',
    outline: 'none',
    cursor: 'pointer',
    transition: '0.3s ease',
    boxShadow: '2.5px 2.5px 6.5px rgba(5, 5, 10, 0.75), -2.5px -2.5px 6.5px rgba(255, 255, 255, 0.25)',
    "@media (max-width: 450px)": {
      fontSize: '1vh',
      padding: '1vh',
      marginLeft: '20vw',
      border: '1px solid #25b8ef',
      boxShadow: '1.5px 1.5px 4.5px rgba(5, 5, 10, 0.75), -1.5px -1.5px 4.5px rgba(255, 255, 255, 0.25)',
    },
    "@media (max-width: 650px)": {
      fontSize: '1vh',
      padding: '1vh',
      marginLeft: '20vw',
      border: '1.5px solid #25b8ef',
      boxShadow: '1.5px 1.5px 4.5px rgba(5, 5, 10, 0.75), -1.5px -1.5px 4.5px rgba(255, 255, 255, 0.25)',
    },
  },
  divider: {
    color: 'white',
  },
  titles: {

    display: 'flex',
    justifyContent: 'space-evenly',
    color: 'white',
    "@media (max-width: 920px)": {
      marginLeft: '0px',
      marginBottom: '0px',
      marginRight: '30px',
      fontSize: '17px'
    },
    "@media (max-width: 400px)": {
      marginLeft: '0px',
      marginBottom: '0px',
      marginRight: '0px',
      fontSize: '12px',
    },
  },
  top5users: {
    marginTop: '15px',
    marginBottom: '5px',
    marginLeft: '-10px',
    marginRight: '10px',
    color: 'var(--opp)',
    listStyleType: 'none',
    display: 'flex',
    flexDirection: 'column',
  },
  ButtonP: {
    marginTop: '15px',
    paddingRight: '10px',
    background: '#25b8ef',
    color: '#1a1a1a',
    borderRadius: '15px',
    transition: '0.3s ease',
    
    boxShadow: '2px 2px 5px rgba(5, 5, 10, 0.75), -2px -2px 5px rgba(255, 255, 255, 0.25)',
    '&:hover': {
      boxShadow: '2px 2px 5px rgba(250, 250, 255, 0.25), -2px -2px 5px rgba(5, 5, 10, 0.75)',
      background: '#25b8ef',
      marginTop: '15px',
      paddingRight: '10px',
      color: '#1a1a1a',
      borderRadius: '15px',

      outline: 'none',
    },
  },
  ButtonQ: {
    background: '#0c7ca6',
    marginTop: '15px',
    paddingRight: '10px',
    color: '#e2e2de',
    borderRadius: '15px',
    boxShadow: '2px 2px 5px rgba(5, 5, 10, 0.75), -2px -2px 5px rgba(255, 255, 255, 0.25)',
    '&:hover': {
      boxShadow: '2px 2px 5px rgba(250, 250, 255, 0.25), -2px -2px 5px rgba(5, 5, 10, 0.75)',
      background: '#0c7ca6',
      marginTop: '15px',
      paddingRight: '10px',
      color: '#e2e2de',
      borderRadius: '15px',

      outline: 'none',
    },
  },
  ButtonS: {
    background: '#07455d',
    marginTop: '15px',
    paddingRight: '10px',
    color: '#e2e2de',
    borderRadius: '15px',

    outline: 'none',
    cursor: 'pointer',
    transition: '0.3s ease',
    boxShadow: '2px 2px 5px rgba(5, 5, 10, 0.75), -2px -2px 5px rgba(255, 255, 255, 0.25)',
    '&:hover': {
      boxShadow: '2px 2px 5px rgba(250, 250, 255, 0.25), -2px -2px 5px rgba(5, 5, 10, 0.75)',
      background: '#07455d',
      marginTop: '15px',
      paddingRight: '10px',
      color: '#e2e2de',
      borderRadius: '15px',

      outline: 'none',
    },
  },

  Modal: {
    backgroundColor: 'var(--card-bg)',
    padding: '30px',
    borderRadius: '15px',
    width: "90vw",
    maxWidth: "500px"


  },
  Modal1: {
    backgroundColor: 'var(--card-bg)',
    padding: '30px',
    borderRadius: '15px',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: "50vw",
    "@media (max-width: 620px)": {
      width: '80vw'
    },

  },
  statFunc: {
    width: '100px',
    position: 'fixed',
    right: "2vw",
    bottom: "15vh",
    zIndex: '10',
  },
  IntroHead: {
    marginBottom: '15px',
    color: '#25b8ef',
    fontSize: '20px',
    "@media (max-width: 650px)": {
      fontSize: '2.5vh'

    },
    "@media (max-width: 450px)": {
      fontSize: '1.5vh'

    },
  },
  IntroPaper: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "space-between",
    borderRadius: '15px',
    width: '100%',
    boxShadow: 'var(--shadow)',
    flexWrap: "wrap",
    background: "var(--card-bg)"
  },
  Details: {
    display: 'flex',
    flexDirection: 'column',

    justifyContent: 'space-between',
  },
  smallDetails: {
    marginBottom: '10px',
    color: 'var(--opp)',
    fontSize: '16px',
    "@media (max-width: 450px)": {
      fontSize: '1vh',

    },
    "@media (max-width: 650px)": {
      fontSize: '1.25vh',

    },
  },
  smallDetails1: {
    marginBottom: '10px',
    color: 'var(--opp)',
    fontSize: '1.8vh',
    display: 'flex',
    flexDirection: 'column',
    "@media (max-width: 450px)": {
      fontSize: '1vh',

    },
    "@media (max-width: 650px)": {
      fontSize: '1.25vh',

    },
  },
  IntroHead1: {
    marginRight: '3vw',
    marginBottom: '1.2vh',
    color: '#25b8ef',
    fontSize: '3vh',
    "@media (max-width: 650px)": {
      fontSize: '2.5vh'

    },
    "@media (max-width: 450px)": {
      fontSize: '1.5vh'

    },
  },
  email: {
    color: '#25b8ef',
    marginBottom: '2vh',
    fontSize: '16px',
    marginTop: '1.5vh',

  },
  number: {
    color: 'var(--blue)',
  },
  frequencyDet: {
    color: 'var(--opp)',
    marginBottom: '1vh',
    fontSize: '20px',
  },
  headerPoints: {
    marginRight: '1.5vw',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  kpFirst: {
    color: '#25b8ef',
    fontSize: '3.8vh',

    "@media (max-width: 1100px)": {
      fontSize: '3vh'

    },
    "@media (max-width: 900px)": {
      fontSize: '2.5vh'

    },
    "@media (max-width: 750px)": {
      fontSize: '2.5vh',
      marginTop: '2vh',
    },
    "@media (max-width: 550px)": {
      fontSize: '2.5vh',
      marginTop: '2vh',
    },
  },
  kpSecond: {
    color: '#25b8ef',
    fontSize: '3.8vh',
    alignItems: 'center',
    "@media (max-width: 1100px)": {
      fontSize: '3vh'

    },
    "@media (max-width: 900px)": {
      fontSize: '2.5vh'

    },
    "@media (max-width: 750px)": {
      fontSize: '2.5vh',
      marginTop: '2vh',

    },
    "@media (max-width: 550px)": {
      fontSize: '2.5vh',
      marginTop: '2vh',

    },
  },
  KPdetails: {
    color: '#25b8ef',
    fontSize: '4vh',
    "@media (max-width: 650px)": {
      fontSize: '2.5vh',


    },
    "@media (max-width: 450px)": {
      fontSize: '1.5vh',


    },
  },
  KPdiv: {
    border: '2px solid #25b8ef',
    borderRadius: '4px',
    marginTop: "10px",
    minWidth: '10.5vw',
    maxWidth: "250px",
    display: "flex",
    justifycontent: "center",
    alignItems: "center",
    padding: "5px 10px",
    boxShadow: "var(--shadow)",
    "@media (max-width: 450px)": {
      width: '15vw',
      border: '1.5px solid #25b8ef',
    },
    "@media (max-width: 650px)": {
      width: 'max-content',
      border: '2px solid #25b8ef',

    },
  },

  editButton: {
    color: "var(--opp)",
    '&:hover': {
      cursor: "pointer",
      // backgroundColor: '#1a1a1e',
      width: '30px',
      height: '30px',
      color: 'white',
      backgroundColor: "#262626",
      
      borderRadius: '15px',
    },
    textAlign: "right",
    marginLeft: "auto",
    marginRight: '5px'

  },
  modalSize: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '30%',


  },

});