import { useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import Logo from '../../Assets/Asset 2.png';
import classes from "./header.module.css"

const Header = () => {
  const [openMenu, setopenMenu] = useState(false);
  const path = document.location.pathname
  return (
    <nav className="flex sm:h-[20vh] h-[15vh] justify-between px-8 py-2 items-center w-full">
      <Link to="/" className="h-full">
        <img src={Logo} alt="logo" className="h-full slidedown" />
      </Link>
      <div className="gap-8 text-gray-300 justify-center items-center md:flex hidden font-semibold">
        <Link to={path === "/" ? "/auth" : "/recruiterlogin"} className="slidedown hover:text-secondary">
          <p>
            Log In / Sign Up
          </p>
        </Link>
        <Link to="/blog" className="slidedown hover:text-secondary">
          <p>
            Blog
          </p>
        </Link>
        <Link to="/about" className="slidedown hover:text-secondary">
          <p>
            About Us
          </p>
        </Link>
        <Link to={path === "/" ? "/recruiters" : "/"} className="slidedown hover:text-secondary">
          <p>
            {path === "/" ? "For Employers" : "For Candidates"}
          </p>
        </Link>
      </div>
      {openMenu ? (
        <AiOutlineClose
          style={{ color: "var(--opp)" }}
          size={30}
          className="md:hidden block cursor-pointer z-10"
          onClick={() => setopenMenu(false)}
        />
      ) : (
        <AiOutlineMenu
          style={{ color: "var(--opp)" }}
          size={30}
          className="md:hidden block cursor-pointer z-10"
          onClick={() => setopenMenu(true)}
        />
      )}

      <div
        className={`${openMenu ? "flex" : "hidden"
          } p-6  absolute top-[16vh] right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar ${classes.container} `}
      >
        <ul className="list-none flex justify-end items-center flex-col flex-1">
          <li className={`font-poppins font-normal cursor-pointer text-[16px] ${classes.list} mb-4  w-full text-center py-2`}>
            <Link to={path === "/" ? "/auth" : "/recruiterlogin"}>Log In / Sign Up</Link>
          </li>
          <li className={`font-poppins font-normal cursor-pointer text-[16px] ${classes.list} mb-4  w-full text-center py-2`}>
            <Link to="/blog">Blog</Link>
          </li>
          <li className={`font-poppins font-normal cursor-pointer text-[16px] ${classes.list} mb-4  w-full text-center py-2`}>
            <Link to="/about">About Us</Link>
          </li>
          <li className={`font-poppins font-normal cursor-pointer text-[16px] ${classes.list} text-center py-2`}>
            <Link to={path === "/" ? "/recruiters" : "/"}>{path === "/" ?  "For Employers" : "For Candidates"}</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;