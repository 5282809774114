import React, { useState } from "react";
import useStyles from "./styles";
import { Grid, CircularProgress, Paper, Typography, Container } from "@material-ui/core";
import Post from './Post/Post';
import { useSelector } from "react-redux";

import { Marginer } from "../Auth/Marginer";
import Formbar1 from "../FormBar/FloatingButtonForm1";
import Formbar from "../FormBar/FloatingButtonForm";

const Posts1 = ({ setCurrentId, show }) => {
    const { posts, isLoading } = useSelector((state) => state.posts);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const [creator, setCreator] = useState(null);
    const userId = user?.result?.googleId || user?.result?._id;
    const classes = useStyles();


    if (!posts.length && !isLoading && show) return (
        <div>
            <Formbar />
            <Paper className={classes.paper}>
                <Typography variant='h5' align='center'>
                    Please use the Button above to create a Post!!
                </Typography>
            </Paper>
        </div>
    );

    return (
        isLoading ? <CircularProgress /> : (

            <Container maxWidth='lg'>
            <Grid className={classes.container1} container alignItems="stretch" spacing={8}>

                
                {show ? (<Formbar1 className={classes.formbar} />) : ""}
                {posts.map((post) => (
                    <Grid key={post._id} item xs={12} sm={12} >
                        <Post post={post} setCurrentId={setCurrentId} />

                    </Grid>
                ))}

            </Grid>
            </Container>

        )
    );

}

export default Posts1;