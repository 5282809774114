import React, { useState, useEffect } from "react";
import { Container, AppBar, Typography, Grow, Grid, Paper, TextField, Button } from '@material-ui/core';
import { useNavigate, useLocation } from "react-router-dom";
import ChipInput from 'material-ui-chip-input';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Formbar from '../FormBar/FormButton';
import { getUserQuestions, getSpecificUserQuestions } from '../../actions/questions';
import { Marginer } from "../Auth/Marginer";
import Questions from '../Questions/Questions';
import Question from '../Questions/Question/Question';
import "../../App.css";
import useStyles from './styles';
import axios from 'axios';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const QuestionsOfUser = ({ currentId, setCurrentId, show = true }) => {

  const { question, questions, isLoading } = useSelector((state) => state.questions);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const [userQuestion, setUserQuestion] = useState([]);
  const query = useQuery();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchQuery = query.get('searchQuery');
  const userId = user?.result?.userId || user?.result?.googleId;

  useEffect(() => {

    axios({ method: 'patch', url: 'https://usurp.live/questions/userspecific', data: userId }).then(res => {
      console.log(res);
      setUserQuestion(res);
    })
      .catch(function (err) {
        console.error(err);
      })
  }, []);
  console.log(questions);
  if (questions.length === 0 && !isLoading && show) {
    return (
      <div style={{ display: "flex", flexDirection: "column", marginTop: "10vh", alignItems: "center", gap: "10px" }}>
        <Formbar />
        <Paper className={classes.paper}>
          <h5>
            Please use the Button above to create your first Question!!
          </h5>
        </Paper>
      </div>
    );


  }


  return (
    <>


      <Grow in style={{ marginTop: '40px'}}>
        <Container maxWidth='xl' >

          <Grid contianer position="absolute" justify="space-between" alignItems="stretch" Spacing={3} className={classes.gridContainer1}>
            {questions?.map((question) => (
              <Grid key={question._id} item xs={12} sm={12} mad={12} >
                <Question question={question} setCurrentId={setCurrentId} />

              </Grid>
            ))}
          </Grid>
        </Container>

      </Grow>


    </>
  );
};

export default QuestionsOfUser;