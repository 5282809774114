import React, { useState, useEffect, useRef } from "react";
import { getResponseByQuizId } from "../../APIServices/ResponseAPI";
import { getUser } from "../../APIServices/UserAPI";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./RecruiterDashboard.css";
//import QuizDetail from "./quizDetails.js"
import ResponsesTable from "./responsesTable.js";
import LinkFile from "./linkFiles.js";
import ContactInfo from "./contactInfo.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRandom } from "@fortawesome/free-solid-svg-icons";
import ViewAll from "./viewAll.js";
import MaterialTable from "material-table";
import XLSX from "xlsx";
import PrintIcon from "@material-ui/icons/Print";
import { CSVLink } from "react-csv";
import { BsArrowDownUp } from "react-icons/bs";
import ExportCSV from "./exportCSV.js";
import * as FaIcons from "react-icons/fa";
//import Sorting from "./sorting.js"
import Name from "./quizName.js";
import { GiConsoleController } from "react-icons/gi";
/* function quizCount({quizNumber}) {

    const [quizs, setQuizs] = useState([])
    const [print , setPrint] = useState(true);
    let obj;
    useEffect(()=>{
        getResponseByQuizId(quizNumber)
        .then((data) => {
          console.log(data.data)
          setQuizs(data.data)
          //obj = data.data.quizName;
          //console.log(obj);

        }, error => {
            const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            console.log(resMessage);
        });
        
    })
    return (
      <div className="dashboard-card">
        <div className ="heading">
          <h2 className="h2">{obj}</h2>
        </div> 
      <>
       
        { <div>

             <table>
              <tr>
                  <th>Name</th>
                  <th>Contact Info</th>
                  <th>Score</th>
                  <th>Grade</th>
                  <th>Video</th>
                  <th>Audio</th>
                  <th>Screen Recording</th>
              </tr> 
            </table>
        </div>}
      </> 
      
      {
        
        quizs.map(quizResponses =>{
        return (
          <>
          
          {/* <ResponsesTable key ={quizResponses._id} quizResponse = {quizResponses}/> */
/* <QuizDetail  key ={quizResponses._id} quizResponse = {quizResponses}/>
             </> */
/* )
  
      }
        )
      }
    </div>
        
  )
}

export default quizCount  */
function QuizCount({ quizNumber }) {
  const conponentPDF = useRef();
  const [quizs, setQuizs] = useState([]);
  const [Info, setInfo] = useState([]);
  const [order, setorder] = useState([]);
  const [num, setNum] = useState("");
  const list = [[]];
  const [Title, setTitle] = useState("");
  var [int, setInt] = useState(0);
  const [sortOrder, setSortOrder] = useState("asc");
  const coloumns = [
    { title: "Name", field: "userName" },
    { title: "Grade", field: "grade" },
    { title: "Score", field: "correctAnswers" },
    { title: "Video Link", field: "videos" },
    {
      title: "Audio Link",
      field: "audioRecording",
    },
    { title: "Screen Link", field: "screenRecording" },
  ];
  const headers = [
    { label: "Name", key: "userName" },
    { label: "Grade", key: "grade" },
    { label: "Score", key: "correctAnswers" },
    { label: "Video Link", key: "videos" },
    { label: "Audio Link", key: "audioRecording" },
    { label: "Screen Link", key: "screenRecording" },
  ];

  const downloadPdf = () => {
    const doc = new jsPDF("landscape", "pt", "a4");
    doc.text("Student Details", 20, 10);
    doc.autoTable({
      theme: "striped",
      columns: coloumns.map((col) => ({ ...col, dataKey: col.field })),
      body: quizs,
      columnStyles: {
        userName: { cellWidth: 50 },
        grade: { cellWidth: 50 },
        correctAnswers: { cellWidth: 50 },
        videos: { cellWidth: 250 },
        audioRecording: { cellWidth: "auto" },
        screenRecording: { cellWidth: 250 },
      },
    });
    doc.save(quizs.quizName);
  };
  const generatePDF = () => {
    const report = new jsPDF("landscape", "pt", "a4");
    report.html(document.querySelector("#series")).then(() => {
      report.save("report.pdf");
    });
  };
  useEffect(() => {
    getResponseByQuizId(quizNumber).then(
      (data) => {
        console.log(data.data);
        setQuizs(data.data);
        console.log(quizs[0]);
        //console.log(data.data)
        //console.log(quizs)
        //obj = data.data.quizName;
        //console.log(obj);
        // quizs.forEach((quiz)=>{
        //   console.log(quizs)
        //   const userData = {
        //     userName: quiz.userName,
        //     quizName: quiz.quizName,
        //     correctAnswers : quiz.correctAnswers,
        //     grade:quiz.grade,
        //   };

        //   const headers = [
        //     { label: 'User Name', key: userName },
        //     { label: 'Name', key: quizName },
        //     { label: 'RegistrationDate', key: correctAnswers },
        //     { label: 'Email', key:grade},
        //   ];
        //   const csvReport = {
        //     filename: "userReport.csv",
        //     headers: headers,
        //     data: userData
        //   }

        // })
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(resMessage);
      }
    );
  }, []);

  const Row = (props) => {
    const {
      userName,
      contactInfo,
      grade,
      correctAnswers,
      videos,
      screenRecording,
      audioRecording,
      viewAll,
    } = props;

    return (
      <tr>
        <td>{userName}</td>
        <td className="mail">{contactInfo}</td>
        <td>{grade}</td>
        <td>{correctAnswers}</td>
        <td>{videos}</td>
        <td>{screenRecording}</td>
        <td>{audioRecording}</td>
        <td>{viewAll}</td>
      </tr>
    );
  };
  /* const Title = (props)=>{
  const {quizName} = props;
  console.log(quizName)
  return (
    <h2 className="h2">{quizName}</h2>
  )
} */
  
  const sorting = () => {
    setNum("sort");
    console.log("re");

    /* sorted = [...data].sort((a, b) => (a.correctAnswers < b.correctAnswers ? 1 : -1));
  setorder(sorted); */
  };
  const handleSortClick = () => {
    const sortedData = [...quizs].sort((a, b) => 
      sortOrder === "asc" ? a.correctAnswers - b.correctAnswers : b.correctAnswers - a.correctAnswers
    );
    console.log("sorting")
    setQuizs(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc")
  } 

  const Table = (props) => {
    const { data } = props;
    if (num.length === 0) {
      setorder(data);
      console.log(order);
    } else {
      const sorted = [...data].sort((a, b) =>
        a.correctAnswers < b.correctAnswers ? 1 : -1
      );
      setorder(sorted);
      console.log(order);
      setNum("");
    }

    /*
     */
    //setNum(false)
    let data1;

    //data1 = [...data];
    /* data1 = [...data].sort((a, b) => (a.correctAnswers < b.correctAnswers ? 1 : -1)); */

    /* 
const ordering = () => {
  setorder(!order);
  if(order==true)
  {
    data1 = [...data].sort((a, b) => (a.correctAnswers < b.correctAnswers ? 1 : -1));
    return (<table>
      <tbody>
          {data1.map(row =>
              <Row userName = {row.userName}
              contactInfo = <ContactInfo id = {row.userId}/>
              grade = {row.grade}
              correctAnswers = {row.correctAnswers}
              videos = <LinkFile link={row.videos}/>
              screenRecording=<LinkFile link={row.screenRecording}/>
              audioRecording= <LinkFile link ={row.audioRecording}/>
          />
  
  
          
              )}
      </tbody>
  </table>)}
   else{
    return (<table>
      <tbody>
          {data.map(row =>
              <Row userName = {row.userName}
              contactInfo = <ContactInfo id = {row.userId}/>
              grade = {row.grade}
              correctAnswers = {row.correctAnswers}
              videos = <LinkFile link={row.videos}/>
              screenRecording=<LinkFile link={row.screenRecording}/>
              audioRecording= <LinkFile link ={row.audioRecording}/>
          />
  
  
          
              )}
      </tbody>
  </table>)}
  } 
 */

    //console.log(data1);
    /* return (<table>
    <tbody>
        {data1.map(row =>
            <Row userName = {row.userName}
            contactInfo = <ContactInfo id = {row.userId}/>
            grade = {row.grade}
            correctAnswers = {row.correctAnswers}
            videos = <LinkFile link={row.videos}/>
            screenRecording=<LinkFile link={row.screenRecording}/>
            audioRecording= <LinkFile link ={row.audioRecording}/>
        />


        
            )}
    </tbody>
</table>)} */

    return (
      <>
        <table>
          <tbody>
            {order.map((row) => (
              <>
                <Row
                  userName={row.userName}
                  contactInfo=<ContactInfo id={row.userId} />
                  grade={row.grade}
                  correctAnswers={row.correctAnswers}
                  videos=<LinkFile link={row.videos} />
                  screenRecording=<LinkFile link={row.screenRecording} />
                  audioRecording=<LinkFile link={row.audioRecording} />
                  viewAll=<ViewAll
                    response={row.response}
                    correctAnswers={row.quizId}
                  />
                  //exportCSV =<ExportCSV user ={row.userName} quiz = {row.quizName} score ={row.correctAnswers} grade={row.grade}/>
                  //exportCSV =<ExportCSV doc ={row}/>
                />
              </>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  return (
    <>
    <div >
      <Name key={quizNumber} quizNumber={quizNumber} />
      <div className="btnn" style={{marginBottom: '-5.8vh'}}>
        <button
          className="btn btn-primary mb-2 dashboard-button"
          onClick={downloadPdf}
        >
          <div style={{ display: 'flex', flexDirection: 'row'}}>
          <FaIcons.FaDownload />&nbsp;PDF
          </div>
        </button>
        <CSVLink
          data={quizs}
          headers={headers}
          filename=<Title quizName={quizs.quizName} />
        >
          <button className="btn btn-primary mb-2 dashboard-button" style={{ marginLeft: "1.5vh" }}>
          <div style={{ display: 'flex', flexDirection: 'row'}}>
            <FaIcons.FaDownload />&nbsp;CSV
            </div>
          </button>
        </CSVLink>
      </div>
      </div>
      <div className="series">
        <div className="dashboard-card">
          <table>
            <tr className="tr">
              <th>Name</th>
              <th>Contact Info</th>

              <th>Grade</th>
              <th>
                Score
                <button className="dashboard-button" onClick={handleSortClick}>
                  {sortOrder === "asc" ? <BsArrowDownUp/> : <BsArrowDownUp />}
                </button>
              </th>

              <th>Video</th>
              <th>Screen Recording</th>
              <th>Audio Recording</th>
              <th>View Answers</th>
            </tr>
          </table>
          <Table data={quizs} />
        </div>
      </div>
    </>
  );
}

export default QuizCount;

// function quizCount({quizNumber})
// {
//   const conponentPDF= useRef();
//     const [userData, setUserdata]= useState([]);
//     const csvDownloadRef = useRef(0);

//     useEffect(()=>{
//           getResponseByQuizId(quizNumber)
//           .then((data) => {
//             console.log(data.data)
//             setUserdata(data.data)

//           }, error => {
//               const resMessage =
//                   (error.response &&
//                       error.response.data &&
//                       error.response.data.message) ||
//                   error.message ||
//                   error.toString();
//               console.log(resMessage);
//           });

//       },[])
//       const doc = new jsPDF({
//         orientation: "landscape",
//         unit: "in"
//       });
//       const headers = [
//         { label: "Name", key: "name" },
//         { label: "Age", key: "age" },
//         { label: "Phone", key: "phone" },
//         { label: "Email", key: "email" },
//       ];

//       const generatePDF= useReactToPrint({
//         content: (doc)=>conponentPDF.current,
//         documentTitle:"Userdata",
//         onAfterPrint:()=>alert("Data saved in PDF")
//     });
//     const fetchDataToDownload = useReactToPrint({
//       content : ()=>csvDownloadRef.current
//     })
// const Title = (props)=>{
//   const {quizName} = props;
//   return (
//     <h2 className="h2">{quizName}</h2>
//   )
// }
// const Name = (props)=>{
//   const {data1} = props ;
//   console.log(data1);
//   return (
//     data1.map(title=>

//         <Title quizName={title.quizName}/>

//     )
//   )
//     }
//     return(
//       <React.Fragment>
//           <div className="btnn">
//                   <button  className="btn btn-success" onClick={ generatePDF}>PDF</button>
//                   </div>
//     <div className="btnn">
//     <CSVLink
//           headers={headers}
//           data={userData}
//           filename="userdata.csv"
//           className="hidden"
//           ref={csvDownloadRef}
//           target="_blank"><button>Export csv</button></CSVLink>
//     </div>

//                   <Name data1 = {userData}/>
//                  <div className ="card" ref={conponentPDF}>

//                   <table>
//                       <thead className="bg-primary">
//                          <tr className="tr">
//                           <th>Name</th>
//                           <th>Email</th>
//                           <th>Grade</th>
//                           <th>Score</th>
//                           <th>Video</th>
//                       <th>Screen Recording</th>
//                        <th>Audio Recording</th>
//                      <th>View Answers</th>

//                           </tr>
//                       </thead>
//                       <tbody>
//                           {
//                               userData.map( (uData, index)=>(
//                                <tr key={index}>
//                               <td>{index+1}</td>
//                               <td><ContactInfo id = {uData.userId}/></td>
//                               <td>{uData.grade}</td>
//                                <td>{uData.correctAnswers}</td>
//                                <td><LinkFile link={uData.videos}/></td>
//                                <td><LinkFile link={uData.screenRecording}/></td>
//                                <td><LinkFile link ={uData.audioRecording}/></td>

//            <td><ViewAll response = {uData.response} correctAnswers={uData.quizId}/></td>

//                           </tr>
//                           )) }
//                       </tbody>
//                   </table>
//                   </div>

//       </React.Fragment>
//     );

// }
// export default quizCount
