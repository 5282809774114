import React, { useState, useEffect } from 'react'
import { Container, AppBar, Typography, Grow, Grid, Card, ListItemAvatar, Button, ListItem, List, Paper, Divider } from '@material-ui/core';
import { getUserProfile } from '../../actions/profile';
import useStyles from "./styles";
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Marginer } from "../Auth/Marginer";
import Edit from "@material-ui/icons/Edit"
import FormPAddExp from '../Form/FormPAddExp';
import FormPRemExp from '../Form/FormPRemExp';
import { Modal } from '@material-ui/core';
import Add from "@material-ui/icons/Add"

const Experiences = ({ currentId, setCurrentId }) => {
    const classes = useStyles();
    const { profiles, profile, isLoading } = useSelector((state) => state.profiles);

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const location = useLocation();
    const dispatch = useDispatch();
    const [addOpen, setAddOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false)

    const handleAddClose = () => {
        setAddOpen(false)
    }
    const handleEditClose = () => {
        setEditOpen(false)
    }



    useEffect(() => {
        const token = user?.token;
        setUser(JSON.parse(localStorage.getItem('profile')));
    }, [location]);
    useEffect(() => {
        dispatch(getUserProfile());
    }, [currentId, dispatch]);
    return (
        <Paper className={classes.IntroPaper} elevation={7}>
            <div style={{ textAlign: "right", flexDirection: 'row', paddingTop: '5px', paddingBottom: '5px', width: "100%", }}>

                <Add className={classes.editButton} fontSize="medium" onClick={() => {
                    setEditOpen(false)
                    setAddOpen(true)
                }} />
                <Edit className={classes.editButton} fontSize="medium" onClick={() => {
                    setEditOpen(true)
                    setAddOpen(false)
                }} />
            </div>

            <div className={classes.Details}>
                <typography variant="h6" className={classes.IntroHead} gutterBottom >
                    Experience
                </typography>


                {profiles?.data?.experiences?.map((exp, i) => (
                    <>
                        <typography className={classes.smallDetails}>
                            <h6>{exp.title}  |  {exp.company}</h6>
                        </typography>
                        <typography className={classes.smallDetails}>
                            {exp.current ? exp.start + " - Current" : exp.start + " - " + exp.end}&nbsp;
                        </typography>
                        <typography className={classes.smallDetails}>
                            {exp.emptype}
                        </typography>
                        <hr style={{ "color": "white" }}></hr>
                    </>
                ))}
            </div>
            <Modal style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} open={addOpen} onClose={handleAddClose} >
                
                    <FormPAddExp currentId={currentId} setCurrentId={setCurrentId} handleClose={handleAddClose} profile={profiles} />
                
            </Modal>
            <Modal style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} open={editOpen} onClose={handleEditClose}>
                
                    <FormPRemExp currentId={currentId} setCurrentId={setCurrentId} handleClose={handleEditClose} profile={profiles} />
                
            </Modal>
        </Paper >
    )
}

export default Experiences