import React from 'react'
import "./RecruiterDashboard.css"

function Score1({userId,quizId,quizDetails}) {

    const Score = quizDetails.find(res => res.quizId === quizId && res.userId === userId);
    console.log(Score);
  return (
    <p>{Score.grade}</p>
)
}

export default Score1