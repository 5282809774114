import axios from "axios";
const API =  "https://usurp.live/recruiter";

// Contains the all the API requests for questions

/* API.interceptors.request.use((req) => {
    if (localStorage.getItem('profile')) {
        req.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }
    return req;
});
 */
export const createRecruiter = async (data) => {
    
    return await axios
        .post(API + "/createRecruiter", data)
        .then(res => {
            console.log(res);
            return res;

        })
}

export const getRecruiter = async (id) => {
    return await axios
        .get(API + `/getRecruiter/${id}`)
        .then(res=>{
            console.log(res);
            return res;
        })
        

}
export const getSpecificUser = async (data) =>{
    
    return await axios
        .patch(API + "/recruiterSpecific", data)
        .then(res => {
            console.log(res);
            return res;
        })
        .catch(function (err) {
            console.error(err);
        })
}