import { AUTH } from '../constants/actionTypes';
import * as api from '../api';

export const signInRecruiter = (form, navigate) => async (dispatch) => {
    try {
        const { data } = await api.signIn(form);
        dispatch({ type: AUTH, data });
        navigate('/recruiterdashboard')
    } catch (error) {
        console.log(error);
    }
}
export const signUpRecruiter = (form, navigate) => async (dispatch) => {
    try {
        const { data } = await api.signUp(form);
        dispatch({ type: AUTH, data });
        navigate('/recruiterdashboard')
    } catch (error) {
        console.log(error);
    }
}