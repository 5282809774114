import React, { useState } from 'react';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';
import "./style.css"
const ImageSlider = ({ slides }) => {
    const [current, setCurrent] = useState(0);
    const length = slides.length;

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    };

    if (!Array.isArray(slides) || slides.length <= 0) {
        return null;
    }

    return (
        <section className='slider'>
            <FaArrowAltCircleLeft style={{ color: 'white'}} className='left-arrow' onClick={prevSlide} />
            <FaArrowAltCircleRight style={{ color: 'white'}} className='right-arrow' onClick={nextSlide} />
            {slides.map((slide, index) => {
                return (
                    <div
                        className={index === current ? 'slide active' : 'slide'}
                        key={index}
                    >

                        {index === current && slide.slice(-3) === "mp4" ? (
                            <video src={slide} controls={true} className='image' />

                        ) : index === current && (

                            <img src={slide} alt='travel image' className='image' />
                        )}

                    </div>
                );
            })}
        </section>
    );
};

export default ImageSlider;