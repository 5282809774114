import React, { useState, useEffect } from 'react'
//import { Button,Modal} from 'react-bootstrap';
import classes from "./RecruiterDashboard.module.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRandom } from '@fortawesome/free-solid-svg-icons'
import Modal from "react-modal";
import ShowAllResponses from "./viewAllAnswers.js"
import { IoIosArrowDropup } from "react-icons/io";
Modal.setAppElement('#root');
function ViewAll({ response, correctAnswers }) {

  //const [responses , setResponses] = useState([]);
  /* useEffect(()=>{
      setResponses(response);
  }) */
  const [modal, setModal] = useState(false);
  const setModalOpen = () => {
    setModal(true)
  }
  const setModalClose = () => {
    setModal(false)
  }

  return (
    /* <div
      className="modal show"
      style={{ display: 'block', position: 'initial' }}
    >
      <Modal.Dialog>
        <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Modal body text goes here.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary">Close</Button>
          <Button variant="primary">Save changes</Button>
        </Modal.Footer>
      </Modal.Dialog>
    </div> */
    <>
      <div >
        <button className={classes.button} onClick={setModalOpen}>
          <IoIosArrowDropup size={25} />
        </button>
        <div className={classes.size}>
          <Modal isOpen={modal} className="Modal" overlayClassName="Overlay">
            <button className={classes.button} onClick={setModalClose}>

              x
            </button>
            <ShowAllResponses detailResponses={response} correctResponse={correctAnswers} />
          </Modal>

        </div>


      </div>
    </>
  );

}

export default ViewAll;