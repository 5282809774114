import axios from "axios";
const API = axios.create({ baseURL: "https://usurp.live/template" })

// Contains the all the API requests for questions

API.interceptors.request.use((req) => {
    if (localStorage.getItem('profile')) {
        req.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }
    return req;
});



export const addTemplate = async (data) => {

    return API.post("/addTemplate", data);
}

export const getTemplateQuizes = async (data) => {
    return API.get("/")
}

export const getQuizsLazyLoading = async (data) => {
    return API.post("/lazy", data);
}

export const getQuizBySearch = async (search) => {
    return API.get(`/getQuizBySearch/${search}`);
}

export const getTemplateById = async (quizId) => {
    return API.get(`/getTemplateById/${quizId}`);
}