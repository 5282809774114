import React from 'react';
import { useParams, Link, Navigate, useNavigate } from 'react-router-dom';
import { getQuizById } from '../../APIServices/QuestionAPI';
import { addTemplate,getTemplateById } from '../../APIServices/Template';
import { Grid, Card, CardActionArea, CardMedia, CardContent, Typography, Button, Snackbar, Paper } from '@material-ui/core';
import NavBar from "../NavBar/NavBar";
import useStyle from "./Style";
import * as BiIcons from "react-icons/bi";
import * as BsIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";
function TemplateDetails() {
    
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [user, setUser] = React.useState(JSON.parse(localStorage.getItem("profile")).obj ? JSON.parse(localStorage.getItem("profile")).obj : JSON.parse(localStorage.getItem("profile")).result)
    const [quizId, setQuizId] = React.useState("");
    const navigate = useNavigate();
    const classes = useStyle();
    const [quizDetails, setQuizDetails] = React.useState({});
    const [openOfAlert, setOpenOfAlert] = React.useState(false);



    const params = useParams();

    React.useEffect(() => {
        var quizId = params.quizId
        if (quizId !== undefined) {
            setQuizId(quizId)
            getTemplateById(quizId)
                .then((data) => {
                    // console.log(data);     
                    setQuizDetails(data.data)
                },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        console.log(resMessage);
                    }
                );
        }
    }, [params.quizId]);
    
    console.log(quizDetails);
    

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("objectId", quizId);
        formData.append("userId", user.data._id);
        addTemplate(formData)
        .then((res) => {
            console.log(res);
            setOpen(true);
            navigate("/myChallenges");
        })
        .catch(function (err) {
            console.error(err);
          });
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

  return (
    <>
    
    <div className={classes.body}>
    <NavBar />
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Card style={{padding: '10vh', width: '70vw', height: 'auto'}}> 
            <div>

    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      
      <img  src={quizDetails?.quizImage}></img>
      </div>
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <Typography style={{marginTop: '3.5vh', fontWeight: '500'}} variant='h2'>{quizDetails?.quizName}</Typography>
      <Typography style={{marginTop: '3.5vh'}} variant='h6' >{quizDetails?.description}</Typography>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <BsIcons.BsQuestionCircle style={{marginTop: '3.30vh'}} size={27}/><Typography style={{marginTop: '3.5vh', marginLeft: '0.75vw'}} >No.of Questions: {quizDetails['questions']?.length}</Typography>
      </div>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <BiIcons.BiTimer style={{marginTop: '3.35vh'}} size={30}/><Typography style={{marginTop: '3.5vh', marginLeft: '0.75vw'}} >Duration: {quizDetails?.duration?.minutes} min {quizDetails?.duration?.seconds} sec</Typography>
      </div>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <BsIcons.BsDoorOpen style={{marginTop: '3.30vh'}} size={23}/><Typography style={{marginTop: '3.5vh', marginLeft: '0.75vw'}} >No.of Allowed attempts: {quizDetails?.attempts}</Typography>
      </div>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <MdIcons.MdAttribution style={{marginTop: '3.40vh'}} size={28}/><Typography style={{marginTop: '3.5vh', marginLeft: '0.75vw'}} >Attribute used to grade: {quizDetails?.numericalAttribute}</Typography>
      </div>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <MdIcons.MdOutlineGrading style={{marginTop: '3.35vh'}} size={23}/><Typography style={{marginTop: '3.5vh', marginLeft: '0.75vw'}} >No.of grading ranges: {quizDetails['numericalRange']?.length}</Typography>
      </div>
      </div>
    </div>
    <Typography variant='subtitle1' style={{marginTop: '5vh', marginBottom: '1vh', fontSize: '1.75vh', fontStyle: 'italic'}}>Use the button below to add the challenge to your account</Typography>
    <Button color="primary" variant="contained" onClick={handleSubmit}>Add Challenge</Button>
    <Snackbar 
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Challenge Added"
        />
        </Card>
        </div>
    </div>
    </>
  )
}

export default TemplateDetails
