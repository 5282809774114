import React from 'react';
import BlogHeader from './BlogHeader';
import { Container, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
const BlogMain = () => {
  return (
    <>
      <BlogHeader />
      <Container maxwidth='xl'>
        <Typography style={{ color: 'white', marginTop: '10vh', fontWeight: '500', marginBottom: '10vh' }} variant='h2'>Blog Posts</Typography>
        <div style={{ borderRadius: '15px', padding: '3vh', background: 'var(--card-bg)', boxShadow: '-3px -3px 6px rgba(256, 256, 256, 0.3), 3px 3px 6px rgba(0, 0, 0, 0.7)', marginTop: '8vh', marginBottom: '10vh' }}>
          <Link to='/blog/easiest-way-to-get-a-job-in-2023' style={{ color: 'white', fontSize: '3vh' }}>Easiest way to get a job in 2023 - Portfolios!</Link>
          <Typography style={{ color: 'var(--opp)', marginTop: '1vh' }} variant='subtitle2'>Posted on 1 March 2023</Typography>
        </div>
      </Container>
    </>
  )
}

export default BlogMain
