import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  media: {
    borderRadius: '15px',

    objectFit: 'cover',
    width: '100%',
    height: '95%',
  },

  media2: {
    borderRadius: '5px',
    objectFit: 'scale-down',
    width: '20vh',
    height: '20vh',
    marginLeft: '2vw',
    marginRight: '2vw'

  },
  title: {
    marginTop: '1.5vh',
    fontSize: '30px',
    color: '#25b8ef',
    "@media (max-width: 600px)": {
      fontSize: '24px'
    },
  },
  time: {
    color: '#25b8ef',
    fontSize: "14px",

  },
  tags: {
    fontSize: '16px',
    marginBottom: '2vh',
    color: '#c935ff',
  },
  paper: {
    display: 'flex',
    padding: '20px',
    borderRadius: '15px',
    width: '90%',
    background: 'var(--card-bg)',
    flexDirection: 'column',
    boxShadow: '5px 5px 15px 5px rgba(5, 5, 10, 0.7), -5px -5px 15px 5px rgba(255, 255, 255, 0.3)',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      flexDirection: 'column',

    },
  },
  section: {
    width: "90%",
    display: 'flex',
    flexDirection: 'column',

  },
  name: {
    fontSize: '3vh',
    marginLeft: '1vw',
    color: '#25b8ef',
    "@media (max-width: 780px)": {
      fontSize: '2vh',
      marginRight: '1vw'
    },
    "@media (max-width: 580px)": {
      fontSize: '1.75vh'

    },

  },
  imageSection: {
    margin: '3vw',
    height: '50vh',
    width: '75vw',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  recommendedPosts: {
    display: 'inline-block',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
    },
  },
  loadingPaper: {
    display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px', borderRadius: '30px', height: '39vh',
    marginTop: '25vh',
    background: 'var(--card-bg)',
    boxShadow: "var(--shadow)"
  },
  commentsOuterContainer: {
    display: 'flex', justifyContent: 'space-between',
  },
  commentsInnerContainer: {
    height: '30vh', overflowY: 'auto', marginRight: '4vw', width: '40vw',

  },
  commentField: {
    borderRadius: '4px',
    padding: "5px",
    width: "100%",
    maxWidth: "100%",
    background: "none",

    border: '1px solid var(--opp)',
    outline: '0px',
    boxShadow: '-1px -1px 2.5px 1px rgba(255, 255, 255, 0.3), 1px 1px 2.5px 1px rgba(5, 5, 10, 0.9)',

  },
  nameDet: {
    display: 'flex',
    direction: "row",
    justifyContent: "flex-start",
    margin: '0px',
    marginRight: '5px',
  },
  pre: {
    whiteSpace: 'pre-wrap',
    fontSize: '16px',
    marginBottom: '2vh',
    color: 'var(--opp)',

  },
  commentButton: {
    background: 'var(--opp)',
    width: '10vw',
    padding: "2vh 10vw",
    height: '5vh',
    borderRadius: '4px',
    color: 'var(--card-bg)',
    fontSize: '14px',
    fontFamily: 'Montserrat, sans-serif',
    boxShadow: '-2px -2px 5px 2px rgba(255, 255, 255, 0.3), 2px 2px 5px 2px rgba(5, 5, 10, 0.7)',
  },
  comments: {
    fontFamily: 'Montserrat, sans-serif',
    color: 'var(--opp)',
    fontSize: '16px',
  },
  nameComments: {
    fontWeight: '700'
  },
  button: {
    border: '0',
    fontFamily: 'Montserrat, sans-serif',
  },
  recommendedTitle: {
    fontSize: '2.3vh',
    margin: '2vh',
    color: '#25b8ef',
    "@media (max-width: 480px)": {
      fontSize: '1.5vh',
      margin: '1.25vh',
    },
  },
  recommendedName: {
    fontSize: '14px',
    margin: '1vh',
    color: 'var(--opp)',
    "@media (max-width: 480px)": {
      fontSize: '1.25vh',
      margin: '1.25vh',

    },
  },
  upvotes: {
    color: '#25b8ef',
    "@media (max-width: 480px)": {
      fontSize: '1vh',

    },
  },
  downvotes: {
    color: '#c935ff',
    "@media (max-width: 480px)": {
      fontSize: '1vh',
    },
  },
  commentOut: {
    width: '50vw',
    "@media (max-width: 780px)": {
      width: '35vw',
      marginRight: '13vw'



    },
    "@media (max-width: 480px)": {
      width: '30vw',
      marginRight: '10vw'



    },
  },
  recommendedContainer: {
    margin: '2vh 0 4vh 0',
    cursor: 'pointer',
    background: 'var(--card-bg)',
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '-3px -3px 8px 3px rgba(255,255,255,0.3), -3px -3px 8px 3px rgba(5,5,10,0.9)',
    "@media (max-width: 1400px)": {
      width: '35vw',

    },
  }
}));
