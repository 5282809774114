import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(0),
    },
  },

  paper: {
    padding: '3vh',
    position: 'absolute',
    backgroundColor: 'var(--card-bg)',
    borderRadius: '15px',
    width: "700px",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    height: "90vh",
    "@media (max-width: 800px)": {
      width: '90%',
      marginLeft: "5%"
    },
  },
  profilePaper: {
    padding: '3vh',
    position: 'absolute',
    backgroundColor: 'var(--card-bg)',
    borderRadius: '15px',
    width: "700px",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    
    "@media (max-width: 800px)": {
      width: '90%',
      marginLeft: "5%"
    },
  },
  Field: {
    width: "100%",
    background: "none",
    padding: "1rem",
    fontSize: "16px",
    border: "2px solid var(--opp)",
    borderRadius: "4px",
    color: "var(--opp)",
  },
  form: {
    display: 'flex',
    flexDirection: "column",
    gap: "15px",
    alignItems: 'center',
  },
  fileInput: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: "center",
    gap: "15px"

  },
  buttonSubmit: {
    marginTop: '2vh',
    background: '#25b8ef',
    fontSize: '2vh',
    borderRadius: '15px',
    marginLeft: '-15vw',
    "@media (max-width: 450px)": {
      marginTop: '3vh',
      marginLeft: '1vw',
      fontSize: '1vh',
      paddingRight: '4vw'
    },

  },
  formTitle: {
    fontSize: '25px',
    color: 'var(--opp)',
    marginBottom: '20px'
  },
  ButtonS: {
    background: '#25b8ef',
    borderRadius: '4px',
    color: 'black',
    fontSize: '14px',
    cursor: 'pointer',
    border: '0px',
    padding: '10px 30px',

  },
  ButtonC: {
    background: '#c935ff',
    borderRadius: '4px',
    color: 'black',
    fontSize: '14px',
    cursor: 'pointer',
    border: '0px',
    padding: '10px 30px',
  },
  paperP: {

    padding: '3vh',
    marginTop: '10vh',
    marginLeft: '15vh',
    position: 'absolute',
    backgroundColor: '#1a1a1a',
    borderRadius: '30px',

    width: '50vw',
    "@media (max-width: 1100px)": {
      marginLeft: '10vh',
      marginTop: '15vh',
      width: '70vw',
    },
    "@media (max-width: 900px)": {
      marginLeft: '7.5vh',
      marginTop: '15vh',
      width: '70vw',
    },
    "@media (max-width: 750px)": {
      marginLeft: '4vh',
      marginTop: '15vh',
      width: '80vw',
    },
    "@media (max-width: 600px)": {
      marginLeft: '3.5vh',
      marginTop: '5vh',
      width: '80vw',
    },
    "@media (max-width: 450px)": {
      marginLeft: '3vh',
      marginTop: '5vh',
      width: '80vw',
    },

  },
  buttonClear: {
    marginTop: '2vh',
    background: '#c935ff',
    fontSize: '2vh',
    borderRadius: '15px',
    marginLeft: '5vw',
    "@media (max-width: 450px)": {
      marginTop: '3vh',
      fontSize: '1vh',
      paddingRight: '4vw'
    },

  },
  buttonsBelow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: "20px",
  },
  videIcon: {
    color: '#25b8ef',
    cursor: 'pointer',
    marginLeft: '75px',
    "@media (max-width: 650px)": {

      marginLeft: '45px',


    },
    "@media (max-width: 450px)": {

      marginLeft: '35px',


    },
  },
  label: {
    "@media (max-width: 900px)": {
      fontSize: '2.0vh',


    },
    "@media (max-width: 650px)": {
      fontSize: '1.8vh',


    },
    "@media (max-width: 450px)": {
      fontSize: '1.5vh',


    },

  },
  choice: {
    fontSize: '16px',
    color: 'var(--opp)',
    marginTop: '15px',

  },
  Or: {
    color: 'var(--opp)',

  },
  smallDetails: {
    marginLeft: '3vw',
    marginBottom: '1vh',
    color: '#e2e2de',
    fontSize: '1.8vh',
  },
  editButton: {
    color: "var(--opp)",
    '&:hover': {
      cursor: "pointer",
      width: '30px',
      height: '30px',
      color: "white",
      backgroundColor: '#262626',
      boxShadow: '10px - 10px  rgba(0,0,0,0.6)- moz - box - shadow: 10px - 10px  rgba(0, 0, 0, 0.6)- webkit - box - shadow: 10px - 10px  rgba(0, 0, 0, 0.6)- o - box - shadow: 10px - 10px  rgba(0, 0, 0, 0.6)',
      borderRadius: '100px',
    },
    textAlign: "right",
    marginLeft: "auto",
    marginRight: '5px'

  },
}));