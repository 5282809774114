import { makeStyles } from '@material-ui/core/styles';


export default makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    body: {
        width: "100%",
        background: "var(--bg)"
    },
    media: {
        height: 140,
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    grid: {
        width: "100%",
        display: "grid",
        gridTemplateColumns: "50% 50%",
        columnGap: "50px",
        rowGap: "10vh",
        "@media (max-width: 800px)": {
            gridTemplateColumns: "1fr",
            columnGap: "0",
            rowGap: "5vh",
        },
    }

}));