import React, { useEffect, useState } from "react";
import { getResponseByQuizId } from "../../APIServices/ResponseAPI";
import "./RecruiterDashboard.css";
import QuizName from "./quizName.js";
import QuizName1 from "./quizName1";
import Email from "./contactInfo.js";
import RenderDetails from "./QuizDetail.js";
function Dashboard1({ quizz }) {
  const [quizDetails, setQuizDetails] = useState([]);
  //const [quizzes , setQuizzes] = useState(quizz);
  console.log(quizDetails);
  //console.log(quizzes);
  useEffect(() => {
    //console.log(quizzes);
    const fetchQuizDetails = async () => {
      const quizDetails = await Promise.all(
        quizz.map(async (quizId) => {
          console.log("i");
          const response = await fetch(
            `https://usurp.live/getResponseByQuizId/${quizId}`
          );
          const data = await response.json();
          console.log(data);
          return data;
        })
      );
      setQuizDetails([].concat(...quizDetails));
      //setQuizDetails(prevDetails => [...prevDetails, ...quizDetails]);
    };
    fetchQuizDetails();
  }, [quizz]);
  const va = "Test 1";
  
  //   const hasTakenQuiz = quizDetails.find(response => response === quiz)
  //  if(hasTakenQuiz)
  //  {
  //   return hasTakenQuiz.correctAnswers;
  //  }
  //  else{
  //   return <h3>NA</h3>
  //  }
  const renderQuizDetails = (quizDetails) => {
    // Render quiz scores or empty string if quiz not taken
    return quizz.map(
      (quiz) => (
        console.log(quiz),
        (
          <td key={quiz}>
            {quizDetails.quiz ? quizDetails.quiz.correctAnswers : "NA"}
          </td>
        )
        // <td key={quiz}>
        //       {findQuiz ? findQuiz.correctAnswers : "NA" }
        // </td>
      )
    );
  };
  const renderUserDetails = (user) => {
    // Check if user ID has already been rendered
    if (renderedUserIds.has(user.userId)) {
      return null;
    }

    renderedUserIds.add(user.userId);
    return (
      <>
        <td>{user.userName}</td>
        <td>
          <Email id={user.userId} />
        </td>
        <RenderDetails
          id={user.userId}
          quizzes={quizz}
          quizDetails={quizDetails}
        />
      </>
    );
  };
  
  const renderedUserIds = new Set();
  return (
    <div className="dashboard-card">
      <table>
        <thead>
          <tr className="tr">
            <th>Name</th>
            <th>Contact Info</th>
            {quizz.map((quiz) => (
              <th>
                <p><QuizName1 key={quiz} quizNumber={quiz} /></p>
              </th>
            ))}
          </tr>
        </thead>
        {/* <tbody>
        {quizDetails.map(user => (
          console.log(user),
          <tr key={user.userId}>
            <td>{user.userName}</td>
            {/* {quizz.map(quiz => {
              const scoreObj = quizz.find(
                (s) => s.userId === user.userId && s.quizId === quiz.quizId
              );
              return <td key={quiz.id}>{scoreObj ? scoreObj.correctAnswers : "NA"}</td>;
            })} */}
        {/* {quizz.map(quiz =>{
              return <td>{quiz} </td>
            })}
          </tr>
        ))}
        </tbody> */}
        <tbody>
          {quizDetails.map((user) => (
            <tr key={user.userId}>{renderUserDetails(user)}</tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Dashboard1;
