import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { BiPowerOff } from "react-icons/bi";
import { Link, Dispatch } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import { IconContext } from "react-icons";
import usurpLogo from "./usurp-logo.png";
import { Navigate } from 'react-router-dom';
import classes from "./SidebarR.module.css";


// const Button = styled.button`
//     border-radius: 15px;
//     margin-top: 2vh;
//     margin-left: 2vw;
//     background: #ac23e5;
//     border: 0px solid #6f006f;
//     box-shadow: -2px -2px 6px 2px rgba(255, 255, 255, 0.25), 2px 2px 6px 2px rgba(5, 5, 10, 0.75);
//     color: #eeddee;
//     font-family: 'Montserrat', sans-serif;
//     font-weight: 500;
//     font-size: 18px;
//     padding: 0.7rem 1.2rem;
//     outline: none;
//     cursor: pointer;
//     transition: 0.3s ease;
//     :hover {
//         border-radius: 15px;
//     margin-top: 2vh;
//     margin-left: 2vw;
//     background: #c935ff;
//     border: 0px solid #6f006f;
//     box-shadow: 2px 2px 6px 2px rgba(255, 255, 255, 0.25), -2px -2px 6px 2px rgba(5, 5, 10, 0.75);
//     color: #eeddee;
//     font-family: 'Montserrat', sans-serif;
//     font-weight: 500;
//     font-size: 18px;
//     padding: 0.7rem 1.2rem;
//     outline: none;
//     cursor: pointer;
//     }
// `;


function Sidebar() {
    //const [sidebar, setSidebar] = useState(false);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const logout = () => {
        dispatch({ type: 'LOGOUT' });
        navigate('/recruiters');
        setUser(null);
    }
    //const showSidebar = () => setSidebar(!sidebar);
    return (
        <>
            {/* <div className={classes.navbar}>
                <Link to="#" className={classes.menuBars}>
                    <FaBars onClick={showSidebar} />
                </Link>
            </div> */}
            <nav className={classes.navMenuActive}>
                <ul className={classes.navMenuItems}>
                    {/* <li className={classes.navbarToggle}>
                        <Link to="#" className={classes.closeMenu}>
                            <AiOutlineClose />
                        </Link>
                    </li> */}
                    {SidebarData.map((item, index) => (
                        <li key={index} className={classes.text}>
                            <Link to={item.path} id={user?.result?.googleId || user?.result?._id}>
                                {item.icon}
                                <span className={classes.span}>{item.title}</span>
                            </Link>
                        </li>

                    ))}
                    <button onClick={logout}><BiPowerOff size={20} />LOG OUT</button>
                </ul>

            </nav>
        </>
    )
}

export default Sidebar;